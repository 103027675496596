import { AxiosError, AxiosResponse } from "axios";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { MdDelete, MdOutlineAdd, MdOutlineRemove } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useAuth } from "src/components/auth/AuthContext";
import { useCart } from "src/components/cart/CartProvider";
import Button from "src/components/form-components/Button";
import Checkbox from "src/components/form-components/Checkbox";
import InputField from "src/components/form-components/InputField";
import SelectField from "src/components/form-components/SelectField";
import TextArea from "src/components/form-components/TextArea";
import Loading from "src/components/loading/Loading";
import { usePopup } from "src/components/popup/ModalContext";
import { ErrorItem } from "src/data/Api";
import { Order, OrderPostResponse } from "src/data/Order";
import { PaymentType } from "src/data/PaymentType";
import { ProfileData, ShippingData } from "src/data/Profile";
import { ShippingType } from "src/data/ShippingType";
import axiosInstance from "src/shared/axiosInstance";

function Checkout() {
  const {
    cart,
    cartTotalPrice,
    clearCart,
    cartLoaded,
    addToCart,
    decreaseQuantity,
    removeFromCart,
  } = useCart();
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const { openPopup, closePopup } = usePopup();

  const [shippingTypes, setShippingTypes] = useState<ShippingType[]>();
  const [paymentTypes, setPaymentTypes] = useState<PaymentType[]>();

  const [orderForm, setOrderForm] = useState<Partial<Order>>({
    same_shipment_and_billing: 0,
  });
  const [orderFormErrors, setOrderFormErrors] = useState<ErrorItem>();
  const [shippingAddresses, setShippingAddresses] = useState<ShippingData[]>(
    []
  );
  const [shippingAddressOptions, setShippingAddressOptions] = useState<
    { id: number; name: string }[]
  >([]);

  const [acceptTermsOfUse, setAcceptTermsOfUse] = useState(false);

  useEffect(() => {
    getShippingTypes();
    getPaymentTypes();
    if (isLoggedIn) {
      axiosInstance
        .get<ProfileData>("/profile")
        .then((response: AxiosResponse<ProfileData>) => {
          setOrderForm((prev) => ({
            ...prev,
            email: response.data.email,
            phone_number: response.data.phone_number,
            shipment_last_name: response.data.last_name,
            shipment_first_name: response.data.first_name,
          }));
        });
      axiosInstance
        .get<ShippingData[]>("/shipping-address")
        .then((response: AxiosResponse<ShippingData[]>) => {
          setShippingAddresses(
            response.data.sort((a) => (a.primary_shipping_address ? -1 : 1))
          );
          setShippingAddressOptions(
            response.data.map((item) => ({
              id: item.id,
              name: `${item.zip_code} ${item.city}, ${item.street_address}`,
            }))
          );
          if (response.data.length > 0) {
            setOrderForm((prev) => ({
              ...prev,
              shipment_address_zip_code: response.data[0].zip_code,
              shipment_address_city: response.data[0].city,
              shipment_address_street_address: response.data[0].street_address,
              shipment_address_floor_number: response.data[0].floor_number,
              shipment_address_door_number: response.data[0].door_number,
            }));
          }
        });
    }
  }, []);

  function changeShippingData(shippingDataId: number) {
    const shippingData = shippingAddresses.find(
      (data) => data.id === shippingDataId
    );
    if (shippingData) {
      setOrderForm((prev) => ({
        ...prev,
        shipment_address_zip_code: shippingData.zip_code,
        shipment_address_city: shippingData.city,
        shipment_address_street_address: shippingData.street_address,
        shipment_address_floor_number: shippingData.floor_number,
        shipment_address_door_number: shippingData.door_number,
      }));
    }
  }

  useEffect(() => {
    if (cartLoaded && cart.length === 0) {
      navigate("/");
    }
    setOrderForm((prev) => ({
      ...prev,
      products: cart.map((cartItem) => ({
        product_id: cartItem.product_id,
        quantity: cartItem.quantity,
      })),
    }));
  }, [cart]);

  function getShippingTypes() {
    axiosInstance
      .get<ShippingType[]>("/shipping/types")
      .then((response: AxiosResponse<ShippingType[]>) => {
        setShippingTypes(response.data);
      });
  }

  function getPaymentTypes() {
    axiosInstance
      .get<PaymentType[]>("/payment/types")
      .then((response: AxiosResponse<PaymentType[]>) => {
        setPaymentTypes(response.data);
      });
  }

  function changeSameShipmentAndBilling(state: boolean) {
    setOrderForm((prev) => ({
      ...prev,
      same_shipment_and_billing: state ? 1 : 0,
    }));

    if (state) {
      setOrderForm((prev) => ({
        ...prev,
        billing_first_name: orderForm?.shipment_first_name,
        billing_last_name: orderForm?.shipment_last_name,
        billing_address_zip_code: orderForm?.shipment_address_zip_code,
        billing_address_city: orderForm?.shipment_address_city,
        billing_address_street_address:
          orderForm?.shipment_address_street_address,
        billing_address_floor_number: orderForm?.shipment_address_floor_number,
        billing_address_door_number: orderForm?.shipment_address_door_number,
      }));
    }
  }

  function getShipmentPrice() {
    return (
      shippingTypes?.find(
        (shipmentType) => shipmentType.id === orderForm?.shipment_type_id
      )?.price ?? 0
    );
  }

  function getPaymentPrice() {
    return (
      paymentTypes?.find(
        (paymentType) => paymentType.id === orderForm?.payment_type_id
      )?.price ?? 0
    );
  }

  function getFinalPrice() {
    return cartTotalPrice + getShipmentPrice() + getPaymentPrice();
  }

  function submitOrder() {
    setOrderFormErrors(undefined);
    axiosInstance
      .post<OrderPostResponse>("/order", orderForm)
      .then((response: AxiosResponse<OrderPostResponse>) => {
        clearCart();
        navigate("/orderOverview", {
          state: {
            order_data: response.data.message.order_data[0],
          },
        });
      })
      .catch((error: AxiosError<any>) => {
        setOrderFormErrors(error.response?.data.message);
      });
  }

  function openOrderConfirm() {
    openPopup(
      t("order.order_confirm_title"),
      <div className="flex flex-col gap-1">
        <span>{t("order.order_confirm_confirm")}</span>
        <Button
          onClick={() => {
            submitOrder();
            closePopup();
          }}
        >
          {t("general.yes")}
        </Button>
        <Button color="secondary" onClick={closePopup}>
          {t("general.cancel")}
        </Button>
      </div>
    );
  }

  return (
    <div className="p-2 flex flex-col items-center w-full">
      <div className="flex flex-row gap-10 justify-center max-w-[80%] w-full max-lg:w-full max-lg:max-w-full max-lg:flex-col">
        <div className="w-fit h-fit flex flex-col gap-2 max-lg:w-full">
          <span className="font-bold text-lg mt-1">
            {t("order.personal_data")}
          </span>
          <div className="flex flex-col gap-5 bg-bg-primary2 shadow rounded p-2 w-full">
            <div className="flex flex-row gap-1 flex-1 max-sm:flex-col">
              <InputField
                className="w-full"
                placeholder={t("order.email")}
                value={orderForm?.email}
                change={(value) =>
                  setOrderForm((prev) => ({ ...prev, email: value }))
                }
                errorKey={orderFormErrors?.email}
              ></InputField>
              <InputField
                className="w-full"
                placeholder={t("order.phone_number")}
                value={orderForm?.phone_number}
                change={(value) =>
                  setOrderForm((prev) => ({ ...prev, phone_number: value }))
                }
                errorKey={orderFormErrors?.phone_number}
              ></InputField>
            </div>
          </div>
          <span className="font-bold text-lg mt-5">
            {t("order.shipping_data")}
          </span>
          <div className="flex flex-col gap-2 bg-bg-primary2 shadow rounded p-2 w-full">
            {isLoggedIn && shippingAddressOptions.length > 0 && (
              <div className="mb-5">
                <SelectField
                  placeholder={t("order.select_saved_shipping_data")}
                  options={shippingAddressOptions}
                  value={shippingAddressOptions[0]}
                  change={(item) => {
                    changeShippingData(item.id);
                  }}
                  displayKey="name"
                ></SelectField>
              </div>
            )}

            <div className="flex flex-row gap-1 max-sm:flex-col">
              <InputField
                className="w-full"
                placeholder={t("order.last_name")}
                value={orderForm?.shipment_last_name}
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    shipment_last_name: value,
                  }))
                }
                errorKey={orderFormErrors?.shipment_last_name}
              ></InputField>
              <InputField
                className="w-full"
                placeholder={t("order.first_name")}
                value={orderForm?.shipment_first_name}
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    shipment_first_name: value,
                  }))
                }
                errorKey={orderFormErrors?.shipment_first_name}
              ></InputField>
            </div>
            <div className="flex flex-row gap-1 max-sm:flex-col">
              <InputField
                className="w-full"
                placeholder={t("address.zip_code")}
                type="number"
                value={orderForm?.shipment_address_zip_code}
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    shipment_address_zip_code: value,
                  }))
                }
                errorKey={orderFormErrors?.shipment_address_zip_code}
              ></InputField>
              <InputField
                className="w-full"
                placeholder={t("address.city")}
                value={orderForm?.shipment_address_city}
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    shipment_address_city: value,
                  }))
                }
                errorKey={orderFormErrors?.shipment_address_city}
              ></InputField>
            </div>
            <InputField
              placeholder={t("address.street_address")}
              value={orderForm?.shipment_address_street_address}
              change={(value) =>
                setOrderForm((prev) => ({
                  ...prev,
                  shipment_address_street_address: value,
                }))
              }
              errorKey={orderFormErrors?.shipment_address_street_address}
            ></InputField>
            <div className="flex flex-row gap-1 max-sm:flex-col">
              <InputField
                className="w-full"
                placeholder={t("address.floor_number")}
                type="number"
                value={orderForm?.shipment_address_floor_number}
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    shipment_address_floor_number: value,
                  }))
                }
                errorKey={orderFormErrors?.shipment_address_floor_number}
              ></InputField>
              <InputField
                className="w-full"
                placeholder={t("address.door_number")}
                type="text"
                value={orderForm?.shipment_address_door_number}
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    shipment_address_door_number: value,
                  }))
                }
                errorKey={orderFormErrors?.shipment_address_door_number}
              ></InputField>
            </div>
          </div>
          <span className="font-bold text-lg mt-5">
            {t("order.billing_data")}
          </span>
          <div className="flex flex-col gap-2 bg-bg-primary2 shadow rounded p-2 w-full">
            <Checkbox
              checked={orderForm?.same_shipment_and_billing === 1}
              checkCallback={(state: boolean) =>
                changeSameShipmentAndBilling(state)
              }
            >
              {t("order.same_as_shipping_data")}
            </Checkbox>
            <div className="flex flex-row gap-1 max-sm:flex-col">
              <InputField
                className="w-full"
                placeholder={t("order.last_name")}
                value={
                  orderForm?.same_shipment_and_billing === 1
                    ? orderForm?.shipment_last_name
                    : orderForm?.billing_last_name
                }
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    billing_last_name: value,
                  }))
                }
                errorKey={orderFormErrors?.billing_last_name}
              ></InputField>
              <InputField
                className="w-full"
                placeholder={t("order.first_name")}
                value={
                  orderForm?.same_shipment_and_billing === 1
                    ? orderForm?.shipment_first_name
                    : orderForm?.billing_first_name
                }
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    billing_first_name: value,
                  }))
                }
                errorKey={orderFormErrors?.billing_first_name}
              ></InputField>
            </div>
            <div className="flex flex-row gap-1 max-sm:flex-col">
              <InputField
                className="w-full"
                placeholder={t("address.zip_code")}
                type="number"
                value={
                  orderForm?.same_shipment_and_billing === 1
                    ? orderForm?.shipment_address_zip_code
                    : orderForm?.billing_address_zip_code
                }
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    billing_address_zip_code: value,
                  }))
                }
                errorKey={orderFormErrors?.billing_address_zip_code}
              ></InputField>
              <InputField
                className="w-full"
                placeholder={t("address.city")}
                value={
                  orderForm?.same_shipment_and_billing === 1
                    ? orderForm?.shipment_address_city
                    : orderForm?.billing_address_city
                }
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    billing_address_city: value,
                  }))
                }
                errorKey={orderFormErrors?.billing_address_city}
              ></InputField>
            </div>
            <InputField
              className="w-full"
              placeholder={t("address.street_address")}
              value={
                orderForm?.same_shipment_and_billing === 1
                  ? orderForm?.shipment_address_street_address
                  : orderForm?.billing_address_street_address
              }
              change={(value) =>
                setOrderForm((prev) => ({
                  ...prev,
                  billing_address_street_address: value,
                }))
              }
              errorKey={orderFormErrors?.billing_address_street_address}
            ></InputField>
            <div className="flex flex-row gap-1 max-sm:flex-col">
              <InputField
                className="w-full"
                placeholder={t("address.floor_number")}
                type="number"
                value={
                  orderForm?.same_shipment_and_billing === 1
                    ? orderForm?.shipment_address_floor_number
                    : orderForm?.billing_address_floor_number
                }
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    billing_address_floor_number: value,
                  }))
                }
                errorKey={orderFormErrors?.billing_address_floor_number}
              ></InputField>
              <InputField
                className="w-full"
                placeholder={t("address.door_number")}
                type="text"
                value={
                  orderForm?.same_shipment_and_billing === 1
                    ? orderForm?.shipment_address_door_number
                    : orderForm?.billing_address_door_number
                }
                change={(value) =>
                  setOrderForm((prev) => ({
                    ...prev,
                    billing_address_door_number: value,
                  }))
                }
                errorKey={orderFormErrors?.billing_address_door_number}
              ></InputField>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-10 flex-1 justify-center h-fit w-full">
          <div className="flex flex-col gap-2">
            <span className="font-bold text-lg mt-2">
              {t("order.shipment_type")}
            </span>
            {orderFormErrors?.shipment_type_id && (
              <div className="text-color-error text-sm mb-2">
                {t(`general.messages.${orderFormErrors?.shipment_type_id}`)}
              </div>
            )}
            {!shippingTypes && (
              <div className="w-full flex justify-center p-10">
                <Loading></Loading>
              </div>
            )}
            {shippingTypes &&
              shippingTypes.map((shippingType, index) => (
                <div key={index} className="bg-bg-primary2 p-2 rounded shadow">
                  <Checkbox
                    description={shippingType.description}
                    checked={orderForm?.shipment_type_id === shippingType.id}
                    checkCallback={() =>
                      setOrderForm((prev) => ({
                        ...prev,
                        shipment_type_id: shippingType.id,
                      }))
                    }
                    price={shippingType.price}
                  >
                    {shippingType.name}
                  </Checkbox>
                </div>
              ))}
          </div>

          <div className="flex flex-col w-full gap-2 p-2">
            <span className="font-bold text-lg">{t("order.payment_type")}</span>
            {orderFormErrors?.payment_type_id && (
              <div className="text-color-error text-sm mb-2">
                {t(`general.messages.${orderFormErrors?.payment_type_id}`)}
              </div>
            )}
            {!paymentTypes && (
              <div className="w-full flex justify-center p-10">
                <Loading></Loading>
              </div>
            )}
            {paymentTypes &&
              paymentTypes.map((paymentType, index) => (
                <div key={index} className="shadow bg-bg-primary2 rounded p-2">
                  <Checkbox
                    description={paymentType.description}
                    checked={orderForm?.payment_type_id === paymentType.id}
                    checkCallback={() =>
                      setOrderForm((prev) => ({
                        ...prev,
                        payment_type_id: paymentType.id,
                      }))
                    }
                    price={paymentType.price}
                  >
                    {paymentType.name}
                  </Checkbox>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="flex flex-col max-w-[80%] w-[80%] gap-2 bg-bg-primary2 shadow rounded p-2 mt-10 max-lg:w-full max-lg:max-w-full max-lg:flex-col">
        <span>{t("order.comment")}</span>
        <TextArea
          placeholder={t("order.comment")}
          value={orderForm.comment}
          change={(value) =>
            setOrderForm((prev) => ({ ...prev, comment: value }))
          }
          errorKey={orderFormErrors?.comment}
        ></TextArea>
      </div>

      <div className="flex flex-col w-full gap-2  max-w-[80%] mt-10 p-2 mb-2 max-lg:w-full max-lg:max-w-full max-lg:flex-col">
        <span className="font-bold text-lg mt-1">
          {t("order.order_summary")}
        </span>
        {t("order.products")}
        {cart.map((cartItem, index) => (
          <div
            key={index}
            className="bg-bg-primary2 rounded shadow p-2 flex flex-row gap-2 w-full items-center"
          >
            <div className="rounded overflow-hidden shadow w-fit">
              <img src="https://placehold.co/80x80" alt="" />
            </div>
            <div className="flex-1">
              <h1 className="font-bold text-lg">{cartItem.name}</h1>
              <span className="font-thin">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Distinctio, quasi.
              </span>
            </div>
            <div className="flex flex-col items-end gap-2">
              <strong className="text-color-primary">
                {cartItem.unit_price * cartItem.quantity} Ft
              </strong>
              <div className="flex flex-row gap-2 items-center">
                <MdOutlineRemove
                  className="text-2xl bg-bg-primary rounded shadow cursor-pointer"
                  onClick={() => decreaseQuantity(cartItem)}
                />
                <span className="px-2">{cartItem.quantity}</span>
                <MdOutlineAdd
                  className="text-2xl bg-bg-primary rounded shadow cursor-pointer"
                  onClick={() => addToCart(cartItem)}
                />
              </div>
            </div>
            <div
              className="h-full p-5 flex items-center text-2xl text-text-secondary hover:text-color-primary cursor-pointer"
              onClick={() => removeFromCart(cartItem.product_id)}
            >
              <MdDelete />
            </div>
          </div>
        ))}
        <div className="flex flex-row gap-5 justify-center">
          <div className="flex flex-col bg-bg-primary2 rounded shadow p-2 gap-2 max-w-[60%] w-full max-md:max-w-full max-md:w-full">
            <div className="flex flex-row gap-20 justify-between">
              <span>{t("order.product_sum")}: </span>
              <span>{cartTotalPrice} Ft</span>
            </div>
            <div className="flex flex-row gap-20 justify-between">
              <span>{t("order.shipment_price")}:</span>
              <span>{getShipmentPrice()} Ft</span>
            </div>
            <div className="flex flex-row gap-20 justify-between">
              <span>{t("order.payment_price")}:</span>
              <span>{getPaymentPrice()} Ft</span>
            </div>
            <div className="flex flex-row gap-20 justify-between">
              <span>{t("order.package_weight")}:</span>
              <span>{0} g</span>
            </div>
            <div className="h-px w-full bg-table-border"></div>
            <strong className="text-lg my-2 flex flex-row gap-20 justify-between">
              <span>{t("order.total_price")}:</span>
              <span>{getFinalPrice()} Ft</span>
            </strong>
          </div>
        </div>
        <div className="bg-bg-primary2 rounded shadow p-2 gap-2 flex flex-col flex-1 justify-end h-fit">
          <Checkbox
            checked={acceptTermsOfUse}
            checkCallback={() => {
              setAcceptTermsOfUse(!acceptTermsOfUse);
            }}
          >
            <span>
              {t("order.accept_terms_text")}{" "}
              <span className="underline">{t("order.terms_of_use_link")}</span>
            </span>
          </Checkbox>
          <Button
            disabled={!acceptTermsOfUse}
            className="w-full text-xl justify-self-end"
            onClick={() => openOrderConfirm()}
          >
            {t("order.place_order")}
          </Button>
        </div>
      </div>
    </div>
  );
}
export default Checkout;
