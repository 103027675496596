import { t } from "i18next";
import { useState } from "react";
import { MdModeEdit } from "react-icons/md";
import { useQuery } from "react-query";
import PermissionLevelEditPopup from "src/admin/pages/users/PermissionLevelEditPopup";
import UserDetails from "src/admin/pages/users/UserDetails";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import InputField from "src/components/form-components/InputField";
import SelectField from "src/components/form-components/SelectField";
import { FullscreenLoading } from "src/components/loading/Loading";
import { usePopup } from "src/components/popup/ModalContext";
import DateCell from "src/components/table/date-cell/DateCell";
import OptionChangeCell from "src/components/table/option-cell/OptionChangeCell";
import Table, {
  FilterData,
  OrderData,
  PageData,
} from "src/components/table/Table";
import { DEFAULT_PAGE_SIZE } from "src/components/table/TablePaginator";
import { PaginatedUserResponse, User, UserState } from "src/data/User";
import axiosInstance from "src/shared/axiosInstance";

export const getAllUser = async (
  pageData: PageData,
  filter?: FilterData,
  order?: OrderData
) => {
  const response = await axiosInstance.get(`/admin/users`, {
    params: {
      limit: pageData.limit,
      page: pageData.page,
      ...Object.fromEntries(
        Object.entries(filter || []).filter(([_, value]) => value)
      ),
      ...order,
    },
  });
  return response.data;
};
export const getUserFilers = async () => {
  const response = await axiosInstance.get(`/admin/users/filters`);
  return response.data;
};

function Users() {
  const { showAlert } = useAlert();
  const { openPopup, closePopup } = usePopup();
  const [filter, setFilter] = useState<FilterData>({});
  const [filterValue, setFilterValue] = useState<FilterData>({});
  const [orderData, setOrderData] = useState<OrderData | undefined>();
  const [pageData, setPageData] = useState<PageData>({
    limit: DEFAULT_PAGE_SIZE,
    page: 1,
  });

  const {
    data: users,
    error,
    isLoading: usersLoading,
    refetch,
  } = useQuery<PaginatedUserResponse>(
    ["users", pageData, filter, orderData],
    () => getAllUser(pageData, filter, orderData),
    {
      keepPreviousData: true,
    }
  );

  const {
    data: userFilters,
    error: filterError,
    isLoading: filtersLoading,
  } = useQuery<{ state_filters: string[]; states: string[] }>(
    ["userFilters"],
    () => getUserFilers(),
    {
      keepPreviousData: true,
    }
  );

  const customColumns = {
    state: (row: User) => {
      return (
        <OptionChangeCell
          dataName={t("admin_users.user_state")}
          valueKey="admin_users.user_statuses"
          value={row.state}
          update={(state: UserState) => {
            return axiosInstance.put("/admin/user/state", {
              user_id: row.id,
              state,
            });
          }}
          disabled={!row.editable_by_admin}
          afterChange={(state: UserState) => {
            showAlert(
              AlertType.SUCCESS,
              t("general.messages.successful_update"),
              t("admin_users.messages.status_update_successful")
            );
            if (users && users.data) {
              users.data = users.data.map((item) =>
                item.id === row.id ? { ...item, state } : item
              );
            }
          }}
          options={userFilters?.states || []}
        />
      );
    },
    permission_level: (row: User) => (
      <div
        className="flex flex-row gap-2 items-center p-1 px-2 rounded-full cursor-pointer w-fit border border-transparent hover:border-table-border hover:shadow hover:bg-bg-primary"
        onClick={(e) => {
          if (!row.editable_by_admin) return;
          e.stopPropagation();
          openPopup(
            t("admin_users.edit_permission_level"),
            <PermissionLevelEditPopup
              value={row.permission_level}
              change={(level: number) =>
                axiosInstance.put("/admin/user/permission", {
                  user_id: row.id,
                  permission_level: level,
                })
              }
              afterSave={() => {
                closePopup();
                showAlert(
                  AlertType.SUCCESS,
                  t("general.message.successful_update"),
                  t("admin_users.messages.permission_update_successful")
                );
              }}
            />
          );
        }}
      >
        <span>{row.permission_level}</span>
        {row.editable_by_admin && <MdModeEdit />}
      </div>
    ),
    name: (row: User) => <>{`${row.last_name} ${row.first_name}`}</>,
    created_at: (row: User) => <DateCell date={row.created_at} />,
    last_login: (row: User) => <DateCell date={row.last_login} />,
  };

  if (usersLoading) {
    return <FullscreenLoading />;
  }

  return (
    <div className="w-full px-10 py-2">
      <div className="w-full mb-2 flex flex-row gap-2 flex-wrap">
        <SelectField
          placeholder={t("admin_users.user_state")}
          displayTranslateKey="admin_users.user_statuses"
          value={filterValue.state}
          options={[null, ...(userFilters?.state_filters || [])]}
          change={(value) => setFilter((prev) => ({ ...prev, state: value }))}
        ></SelectField>
        <InputField
          className="!w-fit"
          placeholder={t("admin_users.last_name")}
          value={filterValue.last_name}
          change={(value) =>
            setFilterValue((prev) => ({ ...prev, last_name: value }))
          }
          onSubmit={(value) => {
            setFilter((prev) => ({ ...prev, last_name: value }));
          }}
        />
        <InputField
          className="!w-fit"
          placeholder={t("admin_users.first_name")}
          value={filterValue.first_name}
          change={(value) =>
            setFilterValue((prev) => ({ ...prev, first_name: value }))
          }
          onSubmit={(value) => {
            setFilter((prev) => ({ ...prev, first_name: value }));
          }}
        />
      </div>
      {users && userFilters && (
        <Table
          dataSource={users.data}
          dataSourceSize={users.number_of_users}
          columns={[
            "id",
            "name",
            "email",
            "state",
            "permission_level",
            "created_at",
            "last_login",
          ]}
          customColumns={customColumns}
          translateKey="admin_users"
          pageData={pageData}
          DetailComponent={UserDetails}
          changePage={(page: number, limit: number) =>
            setPageData({ page, limit })
          }
          orderKeys={["created_at", "last_login"]}
          order={orderData}
          orderChange={(order: OrderData | undefined) => {
            setOrderData(order);
          }}
        />
      )}
    </div>
  );
}

export default Users;
