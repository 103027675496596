import { format } from "date-fns";
import { t } from "i18next";
import { User } from "src/data/User";

interface UserDetailsProps {
  detailData?: User;
}

function UserDetails({ detailData }: UserDetailsProps) {
  if (!detailData) return <></>;

  return (
    <div className="flex flex-col gap-1 ">
      <div className="w-fit">
        <span>{t("admin_users.reg_date")}: </span>
        <strong>
          {format(new Date(detailData.created_at), "yyyy. MM. dd HH:mm:ss")}
        </strong>
      </div>
      <div className="w-fit">
        <span>{t("admin_users.last_login")}: </span>
        <strong>
          {detailData.last_login
            ? format(new Date(detailData.last_login), "yyyy. MM. dd HH:mm:ss")
            : t("admin_users.no_last_login")}
        </strong>
      </div>
    </div>
  );
}

export default UserDetails;
