import { t } from "i18next";

export interface TextAreaProps {
  value?: string;
  placeholder?: string;
  change?: (e: any) => void;
  error?: string;
  errorKey?: string[];
  required?: boolean;
  rows?: number;
  disabled?: boolean;
}

function TextArea({
  value,
  placeholder,
  change,
  error,
  errorKey,
  required = false,
  rows = 3,
  disabled = false,
}: TextAreaProps) {
  return (
    <div>
      <span className="text-sm text-text-secondary">
        {placeholder} {required && <sup className="text-color-primary">*</sup>}
      </span>
      <div
        className={`bg-bg-primary rounded-sm shadow  px-2 py-1 border-[1px] ${
          error || errorKey
            ? "border-color-error animate-[input-error_0.2s_linear]"
            : "border-text-secondary"
        } ${disabled ? "bg-text-disabled" : ""}`}
      >
        <textarea
          className="bg-transparent border-0 outline-0 autofill:shadow-[inset_0_0_0px_1000px_rgb(156,163,175)] w-full"
          value={value === null || value === undefined ? "" : value}
          required={required}
          rows={rows}
          disabled={disabled}
          onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            change && change(e.target.value)
          }
        />
      </div>
      {error && <span className="text-color-error text-sm">{error}</span>}
      <div className="flex flex-col">
        {errorKey &&
          errorKey.map((errorItem, index) => (
            <span key={index} className="text-color-error text-sm">
              {t(`general.messages.${errorItem}`)}
            </span>
          ))}
      </div>
    </div>
  );
}

export default TextArea;
