import { format } from "date-fns";
import { t } from "i18next";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import { useAuth } from "src/components/auth/AuthContext";
import Button from "src/components/form-components/Button";
import { usePopup } from "src/components/popup/ModalContext";
import { OrderDetailsData, OrderStatus } from "src/data/Order";
import axiosInstance from "src/shared/axiosInstance";

interface OrderItemProps {
  order: OrderDetailsData;
  cancel: () => void;
}

function OrderItem({ order, cancel }: OrderItemProps) {
  const { openPopup, closePopup } = usePopup();
  const { loggedInUserId } = useAuth();
  const { showAlert } = useAlert();

  function openCancelOrder() {
    openPopup(
      t("order.cancel_order"),
      <div className="w-full flex flex-col gap-2">
        <span>{t("order.cancel_order_confirm")}</span>
        <span>
          {t("order.order_id")}: <strong>{order.id}</strong>
        </span>

        <Button onClick={() => cancelOrder()}>{t("order.cancel_order")}</Button>
        <Button color="secondary">{t("general.cancel")}</Button>
      </div>
    );
  }

  function cancelOrder() {
    axiosInstance
      .delete("/order", {
        params: { order_id: order.id, user_id: loggedInUserId },
      })
      .then(() => {
        showAlert(
          AlertType.SUCCESS,
          t("general.messages.successful_delete"),
          t("order.messages.order_delete_successful")
        );
        cancel();
        closePopup();
      });
  }

  return (
    <div className="flex flex-col p-2 bg-bg-primary2 shadow rounded gap-2">
      <div className="flex flex-row w-full justify-between">
        <span className="font-bold">
          {t("order.order_id")}: {order.id}
        </span>
        <span className="text-sm">
          {format(new Date(order.created_at), "yyyy. MM. dd HH:mm:ss")}
        </span>
      </div>
      <span>
        {t("order.order_status")}:{" "}
        <strong>{t(`order.order_statuses.${order.order_status}`)}</strong>
      </span>
      <span>
        {t("order.total_price")}: <strong>{order.total_price} Ft</strong>
      </span>
      <div className="w-full h-px bg-table-border"></div>
      <div className="flex flex-row justify-between">
        <NavLink
          to={`/orderDetails?order=${order.id}`}
          className="flex flex-row gap-2 items-center hover:bg-bg-primary w-fit px-2 py-1 rounded hover:shadow cursor-pointer"
        >
          {t("order.order_details")}
          <MdOutlineKeyboardArrowRight className="text-xl" />
        </NavLink>
        {order.order_status === OrderStatus.PENDING && (
          <Button onClick={() => openCancelOrder()}>
            {t("order.cancel_order")}
          </Button>
        )}
      </div>
    </div>
  );
}
export default OrderItem;
