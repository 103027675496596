import { t } from "i18next";
import { useEffect, useState } from "react";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import Button from "src/components/form-components/Button";
import Loading from "src/components/loading/Loading";
import axiosInstance from "src/shared/axiosInstance";

function VerifyRegistration() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [verifyLoading, setVerifyLoading] = useState(true);
  const { showAlert } = useAlert();

  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = searchParams.get("token");
    if (!verifyToken) {
      navigate("/");
      return;
    }
    axiosInstance
      .put("/register/verify", null, { params: { token: verifyToken } })
      .then(() => {
        setVerifyLoading(false);
      })
      .catch(() => {
        showAlert(
          AlertType.ERROR,
          t("general.error"),
          t("general.messages.VERIFY_ERROR")
        );
        navigate("/");
      });
  }, []);

  return !verifyLoading ? (
    <div className="w-full flex items-center justify-center p-20 flex-col">
      <IoCheckmarkDoneCircle className="text-9xl text-color-primary" />

      <span className="text-xl font-semibold">
        {t("register.successful_registration")}
      </span>
      <span>{t("register.successful_register_verify")}</span>
      <NavLink to={"/login"}>
        <Button className="mt-5">{t("login.title")}</Button>
      </NavLink>
    </div>
  ) : (
    <div className="flex items-center justify-center h-full w-full">
      <Loading></Loading>
    </div>
  );
}
export default VerifyRegistration;
