import { t } from "i18next";
import React, { JSXElementConstructor, useEffect, useState } from "react";
import { FaCaretDown } from "react-icons/fa";
import SortHeader from "src/components/table/SortHeader";
import TablePaginator, {
  DEFAULT_PAGE_SIZE,
} from "src/components/table/TablePaginator";

export interface OrderData {
  order_by: string;
  order: "asc" | "desc";
}

export interface PageData {
  limit: number;
  page: number;
}

export interface FilterData {
  [key: string]: any;
}

interface TableProps<T extends { [key: string]: any }> {
  dataSource: T[];
  columns: string[];
  DetailComponent?: JSXElementConstructor<any>;
  customColumns?: { [key: string]: (row: any) => React.JSX.Element };
  translateKey?: string;
  pageData?: PageData;
  changePage: (page: number, limit: number) => void;
  dataSourceSize?: number;
  primaryKey?: string;
  rowClick?: (row: T) => void;
  order?: OrderData;
  orderChange?: (order: OrderData | undefined) => void;
  orderKeys?: string[];
}

const Table = <T extends { [key: string]: any }>({
  dataSource,
  columns,
  DetailComponent,
  customColumns,
  translateKey,
  pageData = { page: 1, limit: DEFAULT_PAGE_SIZE },
  changePage,
  dataSourceSize = 0,
  primaryKey = "id",
  rowClick,
  order,
  orderChange,
  orderKeys,
}: TableProps<T>) => {
  const [expandedRow, setExpandedRow] = useState<string>();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [currentOrder, setCurrentOrder] = useState<OrderData | undefined>();

  useEffect(() => {
    setCurrentOrder(order);
  }, [order]);

  function handleOrderChange(order: OrderData | undefined) {
    if (!orderChange) return;
    orderChange(order);
  }

  function handlePageChange(page: number) {
    setCurrentPage(page);
    changePage(page, pageSize);
  }

  function handleSizeChange(size: number) {
    setPageSize(size);
    changePage(currentPage, size);
  }

  return (
    <div>
      <table className="w-full divide-y divide-table-border border border-table-border bg-bg-primary2 table-fixed shadow rounded">
        <thead className="bg-bg-secondary text-color-on-bg-secondary max-md:hidden">
          <tr>
            {columns.map((column, index) => (
              <th key={index}>
                <SortHeader
                  orderKeys={orderKeys}
                  name={column}
                  changeOrder={(order: OrderData | undefined) =>
                    handleOrderChange(order)
                  }
                  order={order}
                  translateKey={translateKey}
                />
              </th>
            ))}
            {DetailComponent && <th className="w-10"></th>}
          </tr>
        </thead>
        <tbody>
          {dataSource.map((row, index) => (
            <React.Fragment key={index}>
              <tr
                className={`cursor-pointer max-md:block max-md:w-full border-l-2 border-table-border border ${
                  expandedRow === row[primaryKey]
                    ? "bg-bg-primary !border-l-bg-secondary border-b-transparent"
                    : ""
                }`}
                onClick={() => {
                  if (rowClick) {
                    rowClick(row);
                    return;
                  }
                  if (!DetailComponent) return;
                  row[primaryKey] === expandedRow
                    ? setExpandedRow("")
                    : setExpandedRow(row[primaryKey]);
                }}
              >
                {columns.map((column, index) => {
                  if (customColumns?.[column]) {
                    return (
                      <td
                        className="max-md:block max-md:w-full max-md:box-border"
                        key={index}
                      >
                        <div className="w-full flex flex-row gap-2">
                          <span className="md:hidden font-extrabold">
                            {translateKey
                              ? t(`${translateKey}.${columns[index]}`)
                              : t(columns[index])}
                            :
                          </span>
                          <div className="break-all">
                            {customColumns?.[column](row)}
                          </div>
                        </div>
                      </td>
                    );
                  }
                  return (
                    <td
                      className="max-md:block max-md:w-full max-md:box-border"
                      key={index}
                    >
                      <div className="flex flex-row gap-2">
                        <span className="md:hidden font-extrabold">
                          {translateKey
                            ? t(`${translateKey}.${columns[index]}`)
                            : t(columns[index])}
                          :
                        </span>
                        <div className="break-all">{row[column]}</div>
                      </div>
                    </td>
                  );
                })}
                {DetailComponent && (
                  <td className="max-md:block max-md:w-full max-md:box-border">
                    <div className="flex w-full justify-center">
                      <FaCaretDown
                        className={`text-xl transition-transform duration-200 ${
                          expandedRow === row[primaryKey] ? " rotate-180" : ""
                        }`}
                      />
                    </div>
                  </td>
                )}
              </tr>
              {expandedRow === row[primaryKey] && DetailComponent && (
                <tr className="bg-bg-primary w-full max-md:block max-md:w-full border-l-2 !border-l-bg-secondary">
                  {expandedRow === row[primaryKey] && (
                    <td
                      colSpan={columns.length + 1}
                      className="max-md:block max-md:w-full max-md:box-border"
                    >
                      {<DetailComponent detailData={row} />}
                    </td>
                  )}
                </tr>
              )}
            </React.Fragment>
          ))}
          {dataSource.length === 0 && (
            <tr>
              <td colSpan={columns.length + (DetailComponent ? 1 : 0)}>
                <div className="text-center text-text-secondary p-2">
                  {t("table.no_data")}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <TablePaginator
        length={dataSourceSize}
        page={currentPage}
        itemsPerPage={pageSize}
        onPaginate={(page) => handlePageChange(page)}
        onPageSizeChange={(limit) => handleSizeChange(limit)}
      />
    </div>
  );
};
export default Table;
