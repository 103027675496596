import { t } from "i18next";

export interface InputFieldProps {
  type?: string;
  value?: string | number;
  placeholder?: string;
  change?: (e: any) => void;
  autoComplete?: string;
  error?: string[];
  errorKey?: string[];
  required?: boolean;
  className?: string;
  onSubmit?: (value: number | string) => void;
  disabled?: boolean;
}

function InputField({
  type = "text",
  value,
  placeholder,
  change,
  autoComplete = "off",
  error,
  errorKey,
  required = false,
  className,
  onSubmit,
  disabled = false,
}: InputFieldProps) {
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter" && onSubmit) {
      onSubmit(value || "");
    }
  };

  return (
    <div className={`min-w-60 w-full ${className}`}>
      <span className="text-sm text-text-secondary">
        {placeholder} {required && <sup className="text-color-primary">*</sup>}
      </span>
      <div
        className={`bg-bg-primary rounded-3 shadow px-2 py-1 border-[1px] min-h-9 ${
          error || errorKey
            ? "border-color-error animate-[input-error_0.2s_linear]"
            : "border-text-secondary"
        } ${disabled ? "bg-text-disabled" : ""}`}
      >
        <input
          className="bg-transparent placeholder:text-white border-0 outline-0 autofill:shadow-[inset_0_0_0px_1000px_rgb(242,242,248)] w-full"
          type={type}
          value={value === null || value === undefined ? "" : value}
          required={required}
          autoComplete={autoComplete}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
            change && change(e.target.value)
          }
          onKeyDown={handleKeyDown}
          disabled={disabled}
        />
      </div>
      {error && <span className="text-color-error text-sm">{error}</span>}
      <div className="flex flex-col">
        {errorKey &&
          errorKey.map((errorItem, index) => (
            <span key={index} className="text-color-error text-sm">
              {t(`general.messages.${errorItem}`)}
            </span>
          ))}
      </div>
    </div>
  );
}

export default InputField;
