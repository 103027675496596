import { t } from "i18next";
import { useState } from "react";

interface FileInputProps {
  title?: string;
  accept?: string;
  change?: (files: FileList) => void;
  error?: string[];
  errorKey?: string[];
  required?: boolean;
  className?: string;
  disabled?: boolean;
  color?: string;
  multi: boolean;
}

function FileInput({
  title,
  change,
  error,
  errorKey,
  required,
  className,
  disabled,
  color = "color-primary",
  multi = false,
  accept,
}: FileInputProps) {
  const [fileCount, setFileCount] = useState(0);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setFileCount(files.length);
    } else {
      setFileCount(0);
    }
    if (change && files) {
      change(files);
    }
  };

  return (
    <div className="flex flex-row items-center gap-2">
      <label
        htmlFor="file-upload"
        className={`${className} w-fit relative rounded-3 shadow py-1 text-color-on-primary font-bold flex flex-row gap-2 items-center justify-center px-4 transition-colors duration-150 min-h-9 ${
          disabled
            ? "bg-text-disabled text-text-secondary cursor-not-allowed"
            : `bg-${color} hover:bg-${color}-hover`
        }`}
      >
        {title || t("general.select_files")}
      </label>
      <input
        id="file-upload"
        type="file"
        accept={accept}
        multiple={multi}
        onChange={handleFileChange}
      />
      <span>
        {fileCount > 0
          ? t("general.selected_files", { count: fileCount })
          : t("general.no_selected_file")}
      </span>
    </div>
  );
}
export default FileInput;
