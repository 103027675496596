import { t } from "i18next";
import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import {
  orderStatusColors,
  orderStatusIcons,
  paymentStatusColors,
  paymentStatusIcons,
  productStatusColors,
  productStatusIcons,
} from "src/admin/pages/orders/StatusUtils";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import Button from "src/components/form-components/Button";
import ImageList from "src/components/image-viewer/ImageList";
import ImageUploadPopup from "src/components/image-viewer/ImageUploadPopup";
import { usePopup } from "src/components/popup/ModalContext";
import { OrderDetailsData } from "src/data/Order";
import axiosInstance from "src/shared/axiosInstance";

interface AdminOrderDetailsProps {
  detailData?: OrderDetailsData;
}

function AdminOrderDetails({ detailData = undefined }: AdminOrderDetailsProps) {
  const { openPopup, closePopup } = usePopup();
  const { showAlert } = useAlert();

  if (!detailData) return <div></div>;

  const [order, setOrder] = useState<OrderDetailsData>(detailData);

  useEffect(() => {
    setOrder(detailData);
  }, [detailData]);

  const ProductStatusIcon = productStatusIcons[order.product_status];
  const productStatusColor = productStatusColors[order.product_status];

  const OrderStatusIcon = orderStatusIcons[order.order_status];
  const orderStatusColor = orderStatusColors[order.order_status];

  const PaymentStatusIcon = paymentStatusIcons[order.payment_status];
  const paymentStatusColor = paymentStatusColors[order.payment_status];

  function handleImageUpload(images: string[]) {
    axiosInstance
      .put("/admin/order", {
        id: order.id,
        images: images?.map((image) => ({ image_data: image })),
      })
      .then(() => {
        showAlert(
          AlertType.SUCCESS,
          t("general.messages.successful_upload"),
          t("general.messages.images_uploaded_successfully")
        );
        closePopup();
        order.images = [
          ...order.images,
          ...images.map((image) => ({ id: 0, image_url: image })),
        ];
      });
  }

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-row gap-10 flex-wrap">
        <div className=" flex flex-col text-text-secondary">
          <span className="text font-semibold text-text-primary">
            {t("admin_order.user_data")}
          </span>

          {detailData.user ? (
            <>
              <span className="underline cursor-pointer">{`${detailData.user.last_name} ${detailData.user.first_name} (ID: ${detailData.user.id})`}</span>
              <span>{detailData.user.email}</span>
              <span>{detailData.user.phone_number}</span>
            </>
          ) : (
            <span>{t("admin_order.quest_user")}</span>
          )}
        </div>
        <div className=" flex flex-col text-text-secondary">
          <span className="text font-semibold text-text-primary">
            {t("admin_order.shipment_data")}
          </span>
          <span>{`${order.shipment_last_name} ${order.shipment_first_name}`}</span>
          <span>{`${order.shipment_address_zip_code} ${order.shipment_address_city}, ${order.shipment_address_street_address}`}</span>
          <span>{`${order.shipment_address_floor_number}. emelet, ${order.shipment_address_door_number}. ajtó`}</span>
        </div>
        <div className=" flex flex-col text-text-secondary">
          <span className="text font-semibold text-text-primary">
            {t("admin_order.billing_data")}
          </span>
          <span>{`${order.billing_last_name} ${order.billing_first_name}`}</span>
          <span>{`${order.billing_address_zip_code} ${order.billing_address_city}, ${order.billing_address_street_address}`}</span>
          <span>{`${order.billing_address_floor_number}. emelet, ${order.billing_address_door_number}. ajtó`}</span>
        </div>
      </div>
      <div>
        <span className="text font-semibold text-text-primary">
          {t("admin_order.product_list")}
        </span>
        <table className="w-full divide-y divide-table-border border border-table-border bg-bg-primary2 table-fixed">
          <thead>
            <tr>
              <th>{t("admin_order.product")}</th>
              <th>{t("admin_order.quantity")}</th>
              <th>{t("admin_order.price")}</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-table-border">
            {detailData.products.map((product, index) => (
              <tr key={index}>
                <td className="">
                  <div className="flex flex-col gap-1">
                    <NavLink
                      to={`/product/${product.product_id}`}
                      className="text-text-secondary underline cursor-pointer"
                    >
                      {product.product_id}
                    </NavLink>
                    <span>{product.name}</span>
                  </div>
                </td>
                <td>{product.quantity} db</td>
                <td>
                  <div className="flex flex-col gap-1">
                    <span>{product.original_price} Ft/db</span>
                    <span>
                      {product.original_price * (product.quantity ?? 1)} Ft
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* IMAGES */}
        <div className="bg-bg-primary2 shadow rounded mt-2 border border-table-border p-2 flex w-full h-fit flex-col relative">
          <span className="font-semibold flex flex-row gap-2 items-center py-1">
            <span>{t("admin_order.order_image_list")}</span>
            <Button
              icon={<FaPlus />}
              onClick={() =>
                openPopup(
                  t("general.attach_image"),
                  <ImageUploadPopup
                    imagesSelected={(images: string[]) => {
                      handleImageUpload(images);
                    }}
                  />
                )
              }
            >
              {t("admin_order.upload_image")}
            </Button>
          </span>
          <div className="flex flex-row gap-2 h-full w-full items-center justify-center">
            <ImageList
              images={order.images}
              deleteCallback={(id: number) => {
                return axiosInstance
                  .delete("/admin/order/image", {
                    params: { id, order_id: order.id },
                  })
                  .then(() => {
                    showAlert(
                      AlertType.SUCCESS,
                      t("general.messages.successful_delete"),
                      t("general.messages.image_delete_successfully")
                    );
                    setOrder((prev) => ({
                      ...prev,
                      images: prev.images.filter((item) => item.id !== id),
                    }));
                  });
              }}
            />
          </div>
        </div>
        {/* ______ */}
        <div className="flex justify-end flex-row gap-2 mt-2 flex-wrap">
          <div className="border border-table-border rounded p-2 flex flex-col bg-bg-primary2 shadow">
            <span className="text font-semibold text-text-primary">
              {t("admin_order.product_data")}
            </span>
            <span className="flex flex-row gap-2">
              TODO: Csomagazonosító:
              <strong className="underline">XYXYXY</strong>
            </span>
            <span className="flex flex-row gap-2 ">TODO: Egyéb infó, stb</span>
            <span
              className={`flex flex-row gap-2 w-fit rounded-full shadow px-2 py-1 items-center bg-status-${productStatusColor} text-text-on-${productStatusColor}`}
            >
              {t(`order.product_statuses.${order.product_status}`)}
              {ProductStatusIcon && <ProductStatusIcon />}
            </span>
          </div>
          <div className="border border-table-border rounded p-2 flex flex-col bg-bg-primary2 shadow">
            <span className="text font-semibold text-text-primary">
              {t("admin_order.order_data")}
            </span>
            <span className="flex flex-row gap-2">
              TODO: Csomagazonosító:
              <strong className="underline">XYXYXY</strong>
            </span>
            <span className="flex flex-row gap-2 ">TODO: Futár infó, stb</span>
            <span
              className={`flex flex-row gap-2 w-fit rounded-full shadow px-2 py-1 items-center bg-status-${orderStatusColor} text-text-on-${orderStatusColor}`}
            >
              {t(`order.order_statuses.${order.order_status}`)}
              {OrderStatusIcon && <OrderStatusIcon />}
            </span>
          </div>
          <div className="border border-table-border rounded p-2 flex flex-col bg-bg-primary2 shadow">
            <span className="text font-semibold text-text-primary">
              {t("admin_order.payment_data")}
            </span>
            <span className="flex flex-row gap-2">
              TODO: Visa
              <strong className="text-text-secondary">
                1111 **** **** 1111
              </strong>
            </span>
            <span className="flex flex-row gap-2 ">
              TODO: Bank neve, megjegyzés stb
            </span>
            <span
              className={`flex flex-row gap-2 w-fit rounded-full shadow px-2 py-1 items-center bg-status-${paymentStatusColor} text-text-on-${paymentStatusColor}`}
            >
              {t(`order.payment_statuses.${order.payment_status}`)}
              {PaymentStatusIcon && <PaymentStatusIcon />}
            </span>
          </div>
          <div className="border border-table-border rounded p-2 flex flex-col bg-bg-primary2 shadow">
            <span className="text font-semibold text-text-primary">
              {t("admin_order.discount_data")}
            </span>
            <span className="flex flex-row gap-2">
              TODO: Felhasznált kupon:
              <strong className="underline">XYXYXY</strong>
            </span>
            <span className="flex flex-row gap-2 ">
              TODO: Kedvezmény típus: 10%
            </span>
            <span className="flex flex-row gap-2 ">TODO: Egyéb infó, stb</span>
          </div>
          <div className="border border-table-border rounded p-2 flex flex-col bg-bg-primary2 shadow">
            <div className="flex flex-row gap-10 w-full justify-between">
              <span>{t("admin_order.sub_price")}</span>
              <span>
                {order.products.reduce(
                  (acc, value) => acc + value.original_price * value.quantity,
                  0
                )}{" "}
                Ft
              </span>
            </div>
            <div className="flex flex-row gap-10 w-full justify-between">
              <span>{t("admin_order.shipment_price")}</span>
              <span>{order.shipment_type.price} Ft</span>
            </div>
            <div className="flex flex-row gap-10 w-full justify-between">
              <span>{t("admin_order.payment_price")}</span>
              <span>{order.payment_type.price} Ft</span>
            </div>
            <div className="flex flex-row gap-10 w-full justify-between">
              <span>{t("admin_order.discount")}</span>
              <span>-</span>
            </div>
            <div className="flex flex-row gap-10 w-full justify-between text-lg font-bold">
              <span>{t("admin_order.total_price")}</span>
              <span>{order.total_price} Ft</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AdminOrderDetails;
