import { AxiosError, AxiosResponse } from "axios";
import { t } from "i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import { useAuth } from "src/components/auth/AuthContext";
import ForgotPassword from "src/components/auth/forgot-password/ForgotPassword";
import Button from "src/components/form-components/Button";
import InputField from "src/components/form-components/InputField";
import { usePopup } from "src/components/popup/ModalContext";
import { ErrorItem, ErrorMessageResponse } from "src/data/Api";
import { LoginResponse } from "src/data/Auth";
import axiosInstance from "src/shared/axiosInstance";

interface LoginForm {
  email: string;
  password: string;
}

function Login() {
  const { login, setUserPermissions } = useAuth();
  const { openPopup } = usePopup();
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginForm, setLoginForm] = useState<LoginForm>({
    email: "",
    password: "",
  });

  const [loginErrors, setLoginErrors] = useState<ErrorItem>();

  function loginSubmit() {
    setLoginErrors({});
    setLoginLoading(true);
    axiosInstance
      .post<LoginResponse>("/login", loginForm)
      .then((response: AxiosResponse<LoginResponse>) => {
        localStorage.setItem("login_token", response.data.login_token);
        localStorage.setItem("id", response.data.id.toString());
        axiosInstance.defaults.headers["token"] = response.data.login_token;
        axiosInstance.defaults.headers["id"] = response.data.id;
        setUserPermissions(response.data.permissions);
        login(response.data.id);
        showAlert(AlertType.SUCCESS, t("general.messages.login_successful"));
        setLoginLoading(false);
      })
      .catch((error: AxiosError<ErrorMessageResponse>) => {
        setLoginErrors(error.response?.data.message);
        setLoginLoading(false);
      });
  }

  return (
    <div className="flex justify-center p-10">
      <div className="h-fit flex flex-col items-center w-96">
        <h1 className="font-bold text-3xl text-gray-700 mb-2">
          {t("login.title")}
        </h1>
        <form className="flex flex-col w-full gap-4">
          <InputField
            required
            placeholder={t("login.email")}
            value={loginForm.email}
            change={(value) =>
              setLoginForm((prev) => ({ ...prev, email: value }))
            }
            errorKey={loginErrors?.email}
          ></InputField>
          <InputField
            placeholder={t("login.password")}
            type="password"
            autoComplete="password"
            value={loginForm.password}
            change={(value) =>
              setLoginForm((prev) => ({ ...prev, password: value }))
            }
            errorKey={loginErrors?.password}
          ></InputField>
        </form>
        {loginErrors?.account && (
          <span className="mt-2 text-color-error text-sm">
            {t(`general.messages.${loginErrors?.account}`)}
          </span>
        )}
        <Button
          className="w-fit m-5"
          onClick={() => loginSubmit()}
          type="button"
          loading={loginLoading}
        >
          {t("login.submit")}
        </Button>
        <div className="flex flex-col gap-2 items-center text-text-secondary">
          <a
            className="underline cursor-pointer"
            onClick={() =>
              openPopup(t("login.forgot_password"), <ForgotPassword />)
            }
          >
            {t("login.forgot_password")}
          </a>
          <a
            className="underline cursor-pointer"
            onClick={() => navigate("/register")}
          >
            {t("register.title")}
          </a>
        </div>
      </div>
    </div>
  );
}

export default Login;
