import { t } from "i18next";
import { useState } from "react";
import { FaEdit, FaPlus } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useQuery } from "react-query";
import UserGroupDetails from "src/admin/pages/user-groups/UserGroupDetails";
import UserGroupForm from "src/admin/pages/user-groups/UserGroupForm";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import Button from "src/components/form-components/Button";
import { FullscreenLoading } from "src/components/loading/Loading";
import { usePopup } from "src/components/popup/ModalContext";
import Table, { OrderData, PageData } from "src/components/table/Table";
import { DEFAULT_PAGE_SIZE } from "src/components/table/TablePaginator";
import { UserGroup } from "src/data/UserGroup";
import axiosInstance from "src/shared/axiosInstance";

const getUserGroups = async (pageData: PageData, order?: OrderData) => {
  const response = await axiosInstance.get(`/admin/user/group`, {
    params: {
      limit: pageData.limit,
      page: pageData.page,
      ...order,
    },
  });
  return response.data;
};

interface UserGroupsProps {}

function UserGroups({}: UserGroupsProps) {
  const { openPopup, closePopup } = usePopup();
  const { showAlert } = useAlert();

  const [orderData, setOrderData] = useState<OrderData | undefined>();
  const [pageData, setPageData] = useState<PageData>({
    limit: DEFAULT_PAGE_SIZE,
    page: 1,
  });

  const {
    data: userGroups,
    isLoading: userGroupsLoading,
    refetch,
  } = useQuery<any>(
    ["userGroups", pageData, orderData],
    () => getUserGroups(pageData, orderData),
    {
      keepPreviousData: true,
    }
  );

  function openUserGroupCreateForm() {
    openPopup(
      t("user_groups.create_title"),
      <UserGroupForm afterSubmit={refetch} />
    );
  }

  const customColumns = {
    members: (row: UserGroup) => <span>{row.members.length}</span>,
    actions: (row: UserGroup) => (
      <div className="flex flex-row gap-2 text-xl">
        <FaEdit
          onClick={(event) => {
            event.stopPropagation();
            openPopup(
              t("user_groups.edit_group"),
              <UserGroupForm afterSubmit={refetch} groupData={row} />
            );
          }}
        />
        <MdDelete
          onClick={(event) => {
            event.stopPropagation();
            openPopup(
              t("user_groups.delete_group"),
              <div className="flex flex-col gap-1">
                <span>{t("user_groups.delete_group_confirm")}</span>
                <Button
                  onClick={() => {
                    axiosInstance
                      .delete("/admin/user/group", {
                        params: { id: row.id },
                      })
                      .then(() => {
                        closePopup();
                        showAlert(
                          AlertType.INFO,
                          t("general.messages.successful_delete"),
                          t("user_groups.group_deleted")
                        );
                        refetch();
                      });
                  }}
                >
                  {t("general.delete")}
                </Button>
                <Button onClick={closePopup} color="secondary">
                  {t("general.cancel")}
                </Button>
              </div>
            );
          }}
        />
      </div>
    ),
  };

  if (userGroupsLoading) {
    return <FullscreenLoading />;
  }

  return (
    <div className="w-full px-10 py-2">
      <div className="w-full mb-2 flex flex-row gap-2">
        <div className="flex flex-row justify-between w-full items-end">
          <div className="flex flex-row gap-1"></div>
          <Button onClick={openUserGroupCreateForm} icon={<FaPlus />}>
            {t("user_groups.create_new_group")}
          </Button>
        </div>
      </div>
      {userGroups && (
        <Table
          dataSource={userGroups.data}
          dataSourceSize={userGroups.number_of_users}
          columns={["name", "description", "members", "actions"]}
          translateKey="user_groups"
          customColumns={customColumns}
          pageData={pageData}
          changePage={(page: number, limit: number) =>
            setPageData({ page, limit })
          }
          DetailComponent={UserGroupDetails}
          orderKeys={["created_at", "updated_at"]}
          order={orderData}
          orderChange={(order: OrderData | undefined) => {
            setOrderData(order);
          }}
        />
      )}
    </div>
  );
}
export default UserGroups;
