import { AxiosResponse } from "axios";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { FaEdit, FaPlus } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useQuery } from "react-query";
import DiscountDetails from "src/admin/pages/discounts/DiscountDetails";
import DiscountForm from "src/admin/pages/discounts/DiscountForm";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import Button from "src/components/form-components/Button";
import SelectField from "src/components/form-components/SelectField";
import { FullscreenLoading } from "src/components/loading/Loading";
import { usePopup } from "src/components/popup/ModalContext";
import DateCell from "src/components/table/date-cell/DateCell";
import Table, {
  FilterData,
  OrderData,
  PageData,
} from "src/components/table/Table";
import { DEFAULT_PAGE_SIZE } from "src/components/table/TablePaginator";
import {
  Discount,
  DiscountProductFilter,
  DiscountUserFilter,
  DiscountUserGroupFilter,
} from "src/data/Discount";
import axiosInstance from "src/shared/axiosInstance";

const getDiscounts = async (
  pageData: PageData,
  filter: FilterData,
  order?: OrderData
) => {
  const response = await axiosInstance.get(`/admin/discount`, {
    params: {
      limit: pageData.limit,
      page: pageData.page,
      ...Object.fromEntries(
        Object.entries(filter).filter(([_, value]) => value !== "")
      ),
      ...order,
    },
  });
  return response.data;
};

function Discounts() {
  const { openPopup, closePopup } = usePopup();
  const { showAlert } = useAlert();

  const [filter, setFilter] = useState<FilterData>({});
  const [orderData, setOrderData] = useState<OrderData | undefined>();
  const [pageData, setPageData] = useState<PageData>({
    limit: DEFAULT_PAGE_SIZE,
    page: 1,
  });

  const [productFilter, setProductFilter] = useState<DiscountProductFilter[]>(
    []
  );
  const [userFilter, setUserFilter] = useState<DiscountUserFilter[]>([]);
  const [userGroupFilter, setUserGroupFilter] = useState<
    DiscountUserGroupFilter[]
  >([]);

  const {
    data: discounts,
    isLoading: discountsLoading,
    refetch,
  } = useQuery<any>(
    ["discounts", pageData, orderData, filter],
    () => getDiscounts(pageData, filter, orderData),
    {
      keepPreviousData: true,
    }
  );

  function openDiscountForm() {
    openPopup(
      t("discounts.create_title"),
      <DiscountForm
        productFilterData={productFilter}
        userFilterData={userFilter}
        userGroupFilterData={userGroupFilter}
        afterSubmit={() => {
          refetch();
          showAlert(
            AlertType.SUCCESS,
            t("general.messages.successful_create"),
            t("discounts.discount_created")
          );
        }}
      />
    );
  }

  const customColumns = {
    created_at: (row: Discount) => <DateCell date={row.created_at} />,
    updated_at: (row: Discount) => <DateCell date={row.updated_at} />,
    assigned_to: (row: Discount) => {
      if (row.user) {
        return <span>{t("discounts.assign_to_user")}</span>;
      }
      if (row.user_group) {
        return <span>{t("discounts.assign_to_user_group")}</span>;
      }
      if (row.product) {
        return <span>{t("discounts.assign_to_product")}</span>;
      }
      return (
        <span className="text-text-secondary">
          {t("discounts.undefined_assignment")}
        </span>
      );
    },
    actions: (row: Discount) => (
      <div className="flex flex-row gap-2 text-xl">
        <FaEdit
          onClick={(event) => {
            event.stopPropagation();
            openPopup(
              t("discounts.edit_discount"),
              <DiscountForm
                productFilterData={productFilter}
                userFilterData={userFilter}
                userGroupFilterData={userGroupFilter}
                afterSubmit={() => {
                  refetch();
                  closePopup();
                  showAlert(
                    AlertType.SUCCESS,
                    t("general.messages.successful_update"),
                    t("discounts.discount_updated")
                  );
                }}
                discountData={row}
              />
            );
          }}
        />
        <MdDelete
          onClick={(event) => {
            event.stopPropagation();
            openPopup(
              t("discounts.delete_discount"),
              <div className="flex flex-col gap-1">
                <span>{t("discounts.delete_discount_confirm")}</span>
                <Button
                  onClick={() => {
                    axiosInstance
                      .delete("/admin/discount", {
                        params: { id: row.id },
                      })
                      .then(() => {
                        closePopup();
                        showAlert(
                          AlertType.INFO,
                          t("general.messages.successful_delete"),
                          t("discounts.discount_deleted")
                        );
                        refetch();
                      });
                  }}
                >
                  {t("general.delete")}
                </Button>
                <Button onClick={closePopup} color="secondary">
                  {t("general.cancel")}
                </Button>
              </div>
            );
          }}
        />
      </div>
    ),
    discount: (row: Discount) => <span>{row.discount} %</span>,
  };

  useEffect(() => {
    axiosInstance
      .get("/admin/discount/user/filter")
      .then((response: AxiosResponse<DiscountUserFilter[]>) => {
        setUserFilter(response.data);
      });
    axiosInstance
      .get("/admin/discount/product/filter")
      .then((response: AxiosResponse<DiscountProductFilter[]>) => {
        setProductFilter(response.data);
      });
    axiosInstance
      .get("/admin/discount/user/group/filter")
      .then((response: AxiosResponse<DiscountUserGroupFilter[]>) => {
        setUserGroupFilter(response.data);
      });
  }, []);

  if (discountsLoading) {
    return <FullscreenLoading />;
  }

  return (
    <div className="w-full px-10 py-2">
      <div className="w-full mb-2 flex flex-row gap-2">
        <div className="flex flex-row justify-between w-full items-end flex-wrap gap-y-2">
          <div className="flex flex-row gap-1 flex-wrap">
            <SelectField
              displayKey="full_name"
              placeholder={t("discounts.user")}
              options={[null, ...userFilter]}
              value={userFilter.find((item) => item.id === filter.user_id)}
              change={(value: DiscountUserFilter) => {
                setFilter((prev) => ({ ...prev, user_id: value.id }));
              }}
            />
            <SelectField
              displayKey="name"
              placeholder={t("discounts.user_group")}
              options={[null, ...userGroupFilter]}
              value={userGroupFilter.find(
                (item) => item.id === filter.user_group_id
              )}
              change={(value: DiscountUserGroupFilter) => {
                setFilter((prev) => ({ ...prev, user_group_id: value.id }));
              }}
            />
            <SelectField
              displayKey="name"
              placeholder={t("discounts.product")}
              options={[null, ...productFilter]}
              value={productFilter.find(
                (item) => item.product_id === filter.product_id
              )}
              change={(value: DiscountProductFilter) => {
                setFilter((prev) => ({
                  ...prev,
                  product_id: value.product_id,
                }));
              }}
            />
          </div>
          <Button onClick={openDiscountForm} icon={<FaPlus />}>
            {t("discounts.create_new_discount")}
          </Button>
        </div>
      </div>
      {discounts && (
        <Table
          dataSource={discounts.data}
          dataSourceSize={discounts.number_of_discounts}
          columns={[
            "discount",
            "assigned_to",
            "created_at",
            "updated_at",
            "actions",
          ]}
          customColumns={customColumns}
          translateKey="discounts"
          pageData={pageData}
          changePage={(page: number, limit: number) =>
            setPageData({ page, limit })
          }
          orderKeys={["created_at", "updated_at"]}
          order={orderData}
          orderChange={(order: OrderData | undefined) => {
            setOrderData(order);
          }}
          DetailComponent={DiscountDetails}
        />
      )}
    </div>
  );
}
export default Discounts;
