import { AxiosResponse } from "axios";
import { t } from "i18next";
import { useQuery } from "react-query";
import AdminCommentPopup from "src/admin/pages/product-return/AdminCommentPopup";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import { useAuth } from "src/components/auth/AuthContext";
import Button from "src/components/form-components/Button";
import ImageList from "src/components/image-viewer/ImageList";
import ImageUploadPopup from "src/components/image-viewer/ImageUploadPopup";
import { FullscreenLoading } from "src/components/loading/Loading";
import { usePopup } from "src/components/popup/ModalContext";
import { MessageResponse } from "src/data/Api";
import { AdminReturnData } from "src/data/Return";
import axiosInstance from "src/shared/axiosInstance";

const getReturnDetails = async (id: number) => {
  const response = await axiosInstance.get(`/admin/return/product`, {
    params: { id },
  });
  return response.data;
};

interface AdminProductReturnDetailsProps {
  detailData: AdminReturnData;
}

function AdminProductReturnDetails({
  detailData,
}: AdminProductReturnDetailsProps) {
  const { openPopup, closePopup } = usePopup();
  const { showAlert } = useAlert();
  const { loggedInUserId } = useAuth();

  const {
    data: returnData,
    error: returnDataError,
    isLoading: returnDataLoading,
    refetch,
  } = useQuery<AdminReturnData>(
    ["returnData"],
    () => getReturnDetails(detailData.id),
    {
      keepPreviousData: true,
    }
  );

  function openImageSelectPopup() {
    openPopup(
      t("general.attach_image"),
      <ImageUploadPopup
        imagesSelected={(images: string[]) => {
          handleImageUpload(images);
        }}
      />
    );
  }

  function handleImageUpload(images: string[]) {
    axiosInstance
      .put("admin/return/product", {
        ...returnData,
        images: images.map((image) => ({ image_data: image })),
      })
      .then((response: AxiosResponse<MessageResponse>) => {
        refetch();
        showAlert(
          AlertType.SUCCESS,
          t("general.messages.successful_update"),
          t(`general.messages.${response.data.message.success[0]}`)
        );
        closePopup();
      });
  }

  function openCommentEditPopup() {
    openPopup(
      t("product_return.edit_admin_comment"),
      <AdminCommentPopup
        comment={returnData?.admin_comment || ""}
        save={(comment: string) => {
          axiosInstance
            .put("admin/return/product", {
              ...returnData,
              admin_comment: comment,
            })
            .then((response: AxiosResponse<MessageResponse>) => {
              if (returnData) {
                returnData.admin_comment = comment;
              }
              showAlert(
                AlertType.SUCCESS,
                t("general.messages.successful_update"),
                t(`general.messages.${response.data.message.success[0]}`)
              );
              closePopup();
            });
        }}
      />
    );
  }

  if (!returnData || returnDataLoading) {
    return <FullscreenLoading />;
  }

  return (
    <div className="flex flex-col gap-1">
      <div>
        <span className="text-text-secondary">
          {t("product_return.contact")}:{" "}
        </span>
        <span className="font-bold">
          {returnData.email}, {returnData.phone_number}
        </span>
      </div>
      <div>
        <span className="text-text-secondary">
          {t("product_return.user")}:{" "}
        </span>
        <span className="font-bold">
          {returnData.user?.full_name} ({returnData.user?.id})
        </span>
      </div>
      <div>
        <span className="text-text-secondary">
          {t("product_return.admin")}:{" "}
        </span>
        {returnData.admin ? (
          <span className="font-bold">
            {returnData.admin?.last_name} {returnData.admin?.first_name} (
            {returnData.admin?.id})
          </span>
        ) : (
          <span className="font-bold">{t("product_return.no_admin")}</span>
        )}
        {(!returnData.admin || returnData.admin?.id !== loggedInUserId) && (
          <Button
            onClick={() => {
              openPopup(
                t("product_return.assign_to_me"),
                <div className="flex flex-col gap-1">
                  <span>{t("product_return.assign_to_me_confirm")}</span>
                  <Button
                    onClick={() => {
                      axiosInstance
                        .put("admin/return/product", {
                          id: returnData.id,
                          admin_id: loggedInUserId,
                        })
                        .then((response: AxiosResponse<MessageResponse>) => {
                          refetch();
                          showAlert(
                            AlertType.SUCCESS,
                            t("general.messages.successful_update"),
                            t(
                              `general.messages.${response.data.message.success[0]}`
                            )
                          );
                          closePopup();
                        });
                    }}
                  >
                    {t("general.yes")}
                  </Button>
                  <Button color="secondary">{t("general.cancel")}</Button>
                </div>
              );
            }}
          >
            {t("product_return.assign_to_me")}
          </Button>
        )}
      </div>
      <div>
        <span className="text-text-secondary">
          {t("product_return.user_comment")}:{" "}
        </span>
        <span className="font-bold">{returnData.user_comment}</span>
      </div>
      <div>
        <span className="text-text-secondary">
          {t("product_return.admin_comment")}:{" "}
        </span>
        <span className="font-bold">{returnData.admin_comment}</span>
      </div>
      <div>
        <span className="text-text-secondary">
          {t("product_return.user_images")}:
        </span>
        <ImageList images={returnData.user_images} />
      </div>
      <div>
        <span className="text-text-secondary">
          {t("product_return.admin_images")}:{" "}
        </span>
        <ImageList
          images={returnData.admin_images}
          deleteCallback={(id: number) => {
            return axiosInstance
              .delete("/admin/return/product/image", {
                params: { image_id: id, return_order_id: returnData.id },
              })
              .then(() => {
                showAlert(
                  AlertType.SUCCESS,
                  t("general.messages.successful_delete"),
                  t("general.messages.image_delete_successfully")
                );
                returnData.admin_images = returnData.admin_images.filter(
                  (item) => item.id !== id
                );
              });
          }}
        />
      </div>
      <Button className="w-fit" onClick={openImageSelectPopup}>
        {t("product_return.attach_images")}
      </Button>
      <Button className="w-fit" onClick={openCommentEditPopup}>
        {t("product_return.edit_admin_comment")}
      </Button>
    </div>
  );
}
export default AdminProductReturnDetails;
