import { AxiosError, AxiosResponse } from "axios";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import Button from "src/components/form-components/Button";
import InputField from "src/components/form-components/InputField";
import { ErrorItem, ErrorMessageResponse, MessageResponse } from "src/data/Api";
import axiosInstance from "src/shared/axiosInstance";

function PasswordChange() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [passwordForm, setPasswordForm] = useState<{
    password: string;
    password_again: string;
  }>({ password: "", password_again: "" });
  const [error, setError] = useState<ErrorItem>();
  const [token, setToken] = useState<string | undefined>(undefined);

  const { showAlert } = useAlert();
  const navigate = useNavigate();

  useEffect(() => {
    const passwordChangeToken = searchParams.get("token");
    if (!passwordChangeToken) {
      navigate("/");
      return;
    }
    setToken(passwordChangeToken);
  }, []);

  function submitPassword() {
    setError(undefined);
    if (passwordForm.password !== passwordForm.password_again) {
      setError({ password: ["PASWWORD_MATCH_ERROR"] });
      return;
    }
    axiosInstance
      .put("/password", { token, password: passwordForm.password })
      .then((response: AxiosResponse<MessageResponse>) => {
        navigate("/");
        showAlert(
          AlertType.SUCCESS,
          t("general.messages.successful_update"),
          t(`general.messages.${response.data.message.success[0]}`)
        );
      })
      .catch((error: AxiosError<ErrorMessageResponse>) => {
        setError(error.response?.data.message);
        if (error.response?.data.message.token) {
          showAlert(
            AlertType.ERROR,
            t("general.error"),
            t(`general.messages.${error.response.data.message.token[0]}`)
          );
        }
      });
  }

  return (
    <div className="flex justify-center p-10">
      <div className="h-fit flex flex-col items-center w-96 gap-2">
        <h1 className="font-bold text-3xl text-gray-700 mb-2">
          {t("password.select_new_password")}
        </h1>
        <form className="flex flex-col w-full gap-4">
          <InputField
            value={passwordForm.password}
            placeholder={t("password.password")}
            change={(value) =>
              setPasswordForm((prev) => ({ ...prev, password: value }))
            }
            type="password"
          />
          <InputField
            value={passwordForm.password_again}
            placeholder={t("password.password_again")}
            change={(value) =>
              setPasswordForm((prev) => ({
                ...prev,
                password_again: value,
              }))
            }
            type="password"
            errorKey={error?.password}
          />
        </form>
        <Button onClick={() => submitPassword()}>{t("general.save")}</Button>
      </div>
    </div>
  );
}

export default PasswordChange;
