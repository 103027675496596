import { AxiosResponse } from "axios";
import { t } from "i18next";
import { useState } from "react";
import { FaClock, FaHome, FaPhone } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { MdOutlineMenu } from "react-icons/md";
import { NavLink, Outlet } from "react-router-dom";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import { useAuth } from "src/components/auth/AuthContext";
import { PermissionTag } from "src/components/auth/Permissions";
import SmallCartList from "src/components/cart/SmallCartList";
import Button from "src/components/form-components/Button";
import { MessageResponse } from "src/data/Api";
import axiosInstance from "src/shared/axiosInstance";

function Layout() {
  const { logout, isLoggedIn, hasPermission } = useAuth();
  const { showAlert } = useAlert();
  const [menuOpen, setMenuOpen] = useState(false);

  function handleMenuOpen() {
    setMenuOpen(!menuOpen);
  }

  function handleLogout() {
    axiosInstance
      .post("/logout", null)
      .then((response: AxiosResponse<MessageResponse>) => {
        localStorage.removeItem("id");
        localStorage.removeItem("login_token");
        showAlert(
          AlertType.SUCCESS,
          t("general.messages.logout"),
          t(`general.messages.${response.data.message.logout}`)
        );

        logout();
      });
  }

  return (
    <div className="flex flex-col w-full h-full bg-bg-primary overflow-auto selection:bg-table-border">
      <header className="w-full min-h-12 bg-bg-secondary shadow flex flex-row gap-20 items-center justify-center text-color-on-bg-secondary text-sm max-xl:flex-col max-xl:gap-0 px-2 max-xl:min-h-max">
        <div className="flex flex-row max-xl:w-full items-center justify-between h-full max-xl:p-2">
          <NavLink to={"/"}>
            <div className="flex flex-col">
              <span className="text-color-primary text-2xl font-extrabold title-font leading-4 mt-1">
                NADOVI
              </span>
              <span className="font-thin text-sm">AUTÓALKATRÉSZ</span>
            </div>
          </NavLink>
          <div className="flex flex-row gap-5 items-center text-3xl xl:hidden">
            <SmallCartList />
            <MdOutlineMenu onClick={handleMenuOpen} />
          </div>
        </div>
        <div
          className={`flex flex-row gap-20 max-xl:flex-col max-xl:self-start max-xl:gap-2 w-fit max-xl:w-full ${
            !menuOpen ? "max-xl:h-0 max-xl:overflow-hidden" : "max-xl:pb-2"
          }`}
        >
          <div className="flex flex-row gap-x-5 gap-y-2 flex-wrap">
            <span className="flex flex-row gap-2 items-center">
              <FaPhone className="text-color-primary" />
              +36 30 2222 5555
            </span>
            <span className="flex flex-row gap-2 items-center">
              <FaClock className="text-color-primary" />
              HÉTFŐ - PÉNTEK: 08 - 17
            </span>
            <span className="flex flex-row gap-2 items-center">
              <IoMail className="text-color-primary" />
              info@nadovi.hu
            </span>
            <div className="w-full bg-table-border h-px rounded xl:hidden"></div>
          </div>
          <div className="flex flex-row gap-20 items-center max-xl:flex-col-reverse max-xl:gap-2 max-xl:items-start">
            <div className="flex flex-row gap-1 max-xl:flex-col w-full">
              <NavLink to={"/"} onClick={() => setMenuOpen(false)}>
                <Button
                  className="w-full h-full text-lg"
                  icon={<FaHome />}
                ></Button>
              </NavLink>
              {!isLoggedIn ? (
                <NavLink
                  to={"/login"}
                  className="w-full"
                  onClick={() => setMenuOpen(false)}
                >
                  <Button className="w-full">{t("general.login")}</Button>
                </NavLink>
              ) : (
                <>
                  <NavLink
                    to={"/profile"}
                    className="w-full"
                    onClick={() => setMenuOpen(false)}
                  >
                    <Button className="w-full">{t("general.profile")}</Button>
                  </NavLink>
                  {hasPermission(PermissionTag.ADMIN_LOGIN_PANEL) && (
                    <NavLink className="w-full" to={"/admin"}>
                      <Button className="w-full" color="primary">
                        {t("general.admin")}
                      </Button>
                    </NavLink>
                  )}
                  <Button
                    onClick={() => {
                      handleLogout();
                      setMenuOpen(false);
                    }}
                  >
                    {t("general.logout")}
                  </Button>
                </>
              )}
            </div>
            <div className="max-xl:hidden">
              <SmallCartList />
            </div>
          </div>
        </div>
      </header>
      <div className="flex-1">
        <Outlet />
      </div>
      <footer className="bg-bg-secondary shadow w-full h-fit px-2 py-5 text-color-on-bg-secondary flex flex-row gap-x-20 justify-center flex-wrap gap-y-5 max-sm:flex-col max-xl:justify-start">
        <div className="w-96 flex flex-col gap-2">
          <h1 className="font-extrabold text-4xl title-font">
            NADOVI AUTÓSZERVIZ
          </h1>
          <span className="font-thin">
            Szervizünk elkötelezett a minőségi és korrekt munka iránt
          </span>
          <span>Véleménye fontos számunkra és hasznos lehet másoknak is.</span>
          <span className="font-semibold">Ossza meg velünk!</span>
          <Button className="w-fit">Vélemény írása</Button>
        </div>
        <div className="max-w-96 flex flex-col gap-2">
          <strong>Telephelyünk</strong>
          <span className="font-thin">
            2000 Szentendre, Dózsa György út 22.
          </span>
          <strong>Nyitvatartás</strong>
          <span className="font-thin">Hétfő-Péntek 08-17 óráig</span>
          <strong>E-mail cím</strong>
          <span className="font-thin">info@nadovi.hu</span>
          <strong>Telefonszám</strong>
          <span className="font-thin">+36 20 444 3006</span>
        </div>
        {/* <div className="max-w-96 flex flex-col gap-2">
              <strong className="text-xl">Gyors elérés</strong>
              <span className="font-thin">Valami 1</span>
              <span className="font-thin">Valami 2</span>
              <span className="font-thin">Valami 3</span>
            </div> */}
        <div className="max-w-96 flex flex-col gap-2">
          <strong className="text-xl">Ügyfélszolgálat</strong>
          <NavLink to={"/return"}>
            <span className="font-thin hover:underline">
              Termék visszaküldés
            </span>
          </NavLink>
          <span className="font-thin">Valami 2</span>
          <span className="font-thin">Valami 3</span>
        </div>
      </footer>
    </div>
  );
}
export default Layout;
