import { t } from "i18next";
import { useEffect, useState } from "react";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { OrderData } from "src/components/table/Table";

interface SortHeaderProps {
  name: string;
  order?: OrderData;
  changeOrder: (order: OrderData | undefined) => void;
  translateKey?: string;
  orderKeys?: string[];
}

function SortHeader({
  order,
  changeOrder,
  name,
  translateKey,
  orderKeys,
}: SortHeaderProps) {
  const [itemOrder, setItemOrder] = useState<OrderData | undefined>();

  useEffect(() => {
    setItemOrder(order);
  }, [order]);

  function handleOrderChange() {
    let nextDirection: "asc" | "desc" | null = null;

    if (!order || order.order_by !== name) {
      nextDirection = "asc";
    } else {
      if (itemOrder?.order === "asc") {
        nextDirection = "desc";
      }
      if (itemOrder?.order === "desc") {
        nextDirection = null;
      }
    }
    if (nextDirection === null) {
      changeOrder(undefined);
    } else {
      changeOrder({ order: nextDirection, order_by: name });
    }
  }

  return (
    <div
      className="flex flex-row gap-2 items-center group cursor-pointer select-none"
      onClick={() => {
        orderKeys?.includes(name) && handleOrderChange();
      }}
    >
      {translateKey ? t(`${translateKey}.${name}`) : t(name)}

      {itemOrder?.order_by === name && itemOrder?.order === "asc" && (
        <FaSortUp />
      )}

      {itemOrder?.order_by === name && itemOrder?.order === "desc" && (
        <FaSortDown />
      )}

      {orderKeys?.includes(name) && itemOrder?.order_by !== name && (
        <FaSort className="text-text-secondary" />
      )}
    </div>
  );
}
export default SortHeader;
