import { t } from "i18next";
import { useEffect, useState } from "react";
import { FaImage } from "react-icons/fa";
import {
  MdDelete,
  MdOutlineArrowBack,
  MdOutlineArrowForward,
  MdOutlineClose,
} from "react-icons/md";
import Button from "src/components/form-components/Button";
import ImageDeletePopup from "src/components/image-viewer/ImageDeletePopup";
import { ImageData } from "src/components/image-viewer/ImageList";
import Loading from "src/components/loading/Loading";
import { usePopup } from "src/components/popup/ModalContext";

interface ImageViewerProps {
  closeImageViewer: () => void;
  images: ImageData[];
  index: number;
  deleteCallback?: (imageId: number) => Promise<any>;
}
function ImageViewer({
  images,
  index,
  closeImageViewer,
  deleteCallback,
}: ImageViewerProps) {
  const [viewerImages, setViewerImages] = useState<ImageData[]>();
  const [currentIndex, setCurrentIndex] = useState(0);

  const { openPopup } = usePopup();
  const [scale, setScale] = useState(100);

  useEffect(() => {
    setViewerImages(images);
  }, [images]);

  useEffect(() => {
    if (currentIndex > images.length - 1) {
      setCurrentIndex(0);
    }
    if (viewerImages?.length === 0) {
      closeImageViewer();
    }
  }, [viewerImages]);

  useEffect(() => {
    setCurrentIndex(index);
  }, [index]);

  function stepImages(add: number) {
    let newIndex = currentIndex + add;

    if (newIndex < 0) {
      newIndex = images.length - 1;
    }
    if (newIndex > images.length - 1) {
      newIndex = 0;
    }
    setCurrentIndex(newIndex);
  }

  function deleteImage(image: ImageData) {
    if (!deleteCallback) return;
    openPopup(
      t("general.image_delete_title"),
      <ImageDeletePopup
        afterConfirm={() => {
          deleteCallback(image.id).then(() => {
            if (viewerImages) {
              setViewerImages(
                viewerImages.filter((item) => item.id !== image.id)
              );
            }
          });
        }}
      />
    );
  }

  const handleZoom = (event: React.WheelEvent) => {
    const delta = event.deltaY > 0 ? -10 : 10;
    const newScale = Math.min(Math.max(scale + delta, 50), 200);

    setScale(newScale);
  };

  if (!viewerImages) {
    return (
      <div>
        <Loading></Loading>
      </div>
    );
  }

  return (
    <div className="absolute flex w-full h-full top-0 left-0 z-10 select-none overflow-hidden">
      <div className="w-full h-full bg-bg-secondary bg-opacity-70"></div>
      <div className="absolute w-full h-full flex flex-col gap-2 p-2">
        <div className="w-full flex justify-between">
          <div>
            {deleteCallback && (
              <Button
                icon={<MdDelete className="text-xl" />}
                onClick={() => deleteImage(viewerImages[currentIndex])}
              >
                {t("admin_order.delete_image")}
              </Button>
            )}
          </div>
          <div
            className="text-color-on-primary text-4xl font-bold bg-bg-secondary rounded flex items-center justify-center z-20"
            onClick={() => closeImageViewer()}
          >
            <MdOutlineClose />
          </div>
        </div>
        <div className="flex-1 flex flex-col gap-2 flex-grow min-h-0">
          <div className="relative flex-1 flex-grow min-h-0 w-full flex items-center justify-center">
            <div
              className="absolute left-2 text-4xl bg-bg-secondary text-color-on-primary rounded-full p-2 cursor-pointer bg-opacity-50"
              onClick={() => stepImages(-1)}
            >
              <MdOutlineArrowBack />
            </div>
            <img
              onWheel={handleZoom}
              className={`object-contain max-w-full max-h-full z-10`}
              src={viewerImages[currentIndex]?.image_url}
              style={{
                transform: `scale(${scale}%)`,
                transition: "transform 0.2s",
              }}
            />
            <div
              className="absolute right-2 text-4xl bg-bg-secondary text-color-on-primary rounded-full p-2 cursor-pointer bg-opacity-50"
              onClick={() => stepImages(1)}
            >
              <MdOutlineArrowForward />
            </div>
          </div>
          <div className="h-32 flex flex-row gap-2">
            {viewerImages.map((image, index) => (
              <div
                key={index}
                className="h-28 w-28 rounded relative overflow-hidden"
                onClick={() => setCurrentIndex(index)}
              >
                {currentIndex === index && (
                  <div className="absolute bg-color-on-primary bg-opacity-60 w-full h-full flex items-center justify-center">
                    <FaImage className="text-3xl text-text-primary" />
                  </div>
                )}
                <img
                  className="w-28 h-28 rounded object-cover"
                  src={image.image_url}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageViewer;
