import { useState } from "react";
import { useQuery } from "react-query";
import ReviewDetails from "src/admin/pages/reviews/ReviewDetails";
import { FullscreenLoading } from "src/components/loading/Loading";
import Table, { PageData } from "src/components/table/Table";
import { DEFAULT_PAGE_SIZE } from "src/components/table/TablePaginator";
import { AdminReviewListResponse } from "src/data/Review";
import axiosInstance from "src/shared/axiosInstance";

export const getAllReview = async (
  pageData: PageData
  //   filter?: FilterData,
  //   order?: OrderData
) => {
  const response = await axiosInstance.get(`/admin/reviews`, {
    params: {
      limit: pageData.limit,
      page: pageData.page,
      //   ...Object.fromEntries(
      //     Object.entries(filter || []).filter(([_, value]) => value)
      //   ),
      //   ...order,
    },
  });
  return response.data;
};

function Reviews() {
  const [pageData, setPageData] = useState<PageData>({
    limit: DEFAULT_PAGE_SIZE,
    page: 1,
  });

  const {
    data: reviews,
    error,
    isLoading: reviewsLoading,
    refetch,
  } = useQuery<AdminReviewListResponse>(
    ["reviews", pageData],
    () => getAllReview(pageData),
    {
      keepPreviousData: true,
    }
  );

  if (reviewsLoading) {
    return <FullscreenLoading />;
  }

  return (
    <div className="w-full px-10 py-2">
      <div className="w-full mb-2 flex flex-row gap-2 flex-wrap"></div>
      {reviews && (
        <Table
          dataSource={reviews.data}
          dataSourceSize={reviews.number_of_reviews}
          columns={[]}
          translateKey="admin_users"
          pageData={pageData}
          DetailComponent={ReviewDetails}
          changePage={(page: number, limit: number) =>
            setPageData({ page, limit })
          }
          //   orderKeys={["created_at", "last_login"]}
          //   order={orderData}
          //   orderChange={(order: OrderData | undefined) => {
          //     setOrderData(order);
          //   }}
        />
      )}
    </div>
  );
}
export default Reviews;
