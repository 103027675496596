import { t } from "i18next";
import { UserGroup } from "src/data/UserGroup";

interface UserGroupDetailsProps {
  detailData: UserGroup;
}

function UserGroupDetails({ detailData }: UserGroupDetailsProps) {
  return (
    <div>
      <span className="text-text-secondary">
        {t("user_groups.member_list")}:
      </span>
      <div className="flex flex-col gap-1 max-h-56 overflow-auto pe-2">
        {detailData.members.length === 0 && (
          <span>{t("user_groups.no_user")}</span>
        )}
        {detailData.members.map((member) => (
          <div className="bg-bg-primary2 w-full p-2 shadow rounded">
            <span>
              {member.full_name}{" "}
              <small className="text-text-secondary">({member.email})</small>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
export default UserGroupDetails;
