import { AxiosResponse } from "axios";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { FaEdit, FaPlus } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useQuery } from "react-query";
import PromotionForm from "src/admin/pages/promotions/PromotionForm";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import Button from "src/components/form-components/Button";
import SelectField from "src/components/form-components/SelectField";
import { FullscreenLoading } from "src/components/loading/Loading";
import { usePopup } from "src/components/popup/ModalContext";
import DateCell from "src/components/table/date-cell/DateCell";
import Table, {
  FilterData,
  OrderData,
  PageData,
} from "src/components/table/Table";
import { DEFAULT_PAGE_SIZE } from "src/components/table/TablePaginator";
import {
  AdminPromotionListResponse,
  ProductFilter,
  Promotion,
} from "src/data/Promotion";
import axiosInstance from "src/shared/axiosInstance";

const getPromotions = async (
  pageData: PageData,
  filter: FilterData,
  order?: OrderData
) => {
  const response = await axiosInstance.get(`/admin/promotion`, {
    params: {
      limit: pageData.limit,
      page: pageData.page,
      ...Object.fromEntries(
        Object.entries(filter).filter(([_, value]) => value !== "")
      ),
      ...order,
    },
  });
  return response.data;
};

interface PromotionsProps {}

function Promotions({}: PromotionsProps) {
  const { openPopup, closePopup } = usePopup();
  const { showAlert } = useAlert();

  const [productFilter, setProductFilter] = useState<ProductFilter[]>([]);

  const [filter, setFilter] = useState<FilterData>({});
  const [orderData, setOrderData] = useState<OrderData | undefined>();
  const [pageData, setPageData] = useState<PageData>({
    limit: DEFAULT_PAGE_SIZE,
    page: 1,
  });

  const {
    data: promotions,
    error: promotionsError,
    isLoading: promotionsLoading,
    refetch,
  } = useQuery<AdminPromotionListResponse>(
    ["promotions", pageData, orderData, filter],
    () => getPromotions(pageData, filter, orderData),
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    axiosInstance
      .get("/admin/promotion/product/filter")
      .then((response: AxiosResponse<ProductFilter[]>) => {
        setProductFilter(response.data);
      });
  }, []);

  function openPromotionForm() {
    openPopup(
      t("promotions.create_title"),
      <PromotionForm
        afterSubmit={() => {
          refetch();
          closePopup();
          showAlert(
            AlertType.SUCCESS,
            t("general.messages.successful_create"),
            t("promotions.promotion_created")
          );
        }}
        productData={productFilter}
      />
    );
  }

  const customColumns = {
    start_date: (row: Promotion) => <DateCell date={row.start_date} />,
    end_date: (row: Promotion) => <DateCell date={row.end_date} />,
    created_at: (row: Promotion) => <DateCell date={row.created_at} />,
    updated_at: (row: Promotion) => <DateCell date={row.updated_at} />,
    product: (row: Promotion) => <span>{row.product.name}</span>,
    actions: (row: Promotion) => (
      <div className="flex flex-row gap-2 text-xl">
        <FaEdit
          onClick={(event) => {
            event.stopPropagation();
            openPopup(
              t("promotions.edit_promotion"),
              <PromotionForm
                productData={productFilter}
                afterSubmit={() => {
                  refetch();
                  closePopup();
                  showAlert(
                    AlertType.SUCCESS,
                    t("general.messages.successful_update"),
                    t("promotions.promotion_updated")
                  );
                }}
                promotionData={row}
              />
            );
          }}
        />
        <MdDelete
          onClick={(event) => {
            event.stopPropagation();
            openPopup(
              t("promotions.delete_promotion"),
              <div className="flex flex-col gap-1">
                <span>{t("promotions.delete_promotion_confirm")}</span>
                <Button
                  onClick={() => {
                    axiosInstance
                      .delete("/admin/promotion", {
                        params: { id: row.id },
                      })
                      .then(() => {
                        closePopup();
                        showAlert(
                          AlertType.INFO,
                          t("general.messages.successful_delete"),
                          t("promotions.promotion_deleted")
                        );
                        refetch();
                      });
                  }}
                >
                  {t("general.delete")}
                </Button>
                <Button onClick={closePopup} color="secondary">
                  {t("general.cancel")}
                </Button>
              </div>
            );
          }}
        />
      </div>
    ),
  };

  if (promotionsLoading) {
    return <FullscreenLoading />;
  }

  return (
    <div className="w-full px-10 py-2">
      <div className="w-full mb-2 flex flex-row gap-2">
        <div className="flex flex-row justify-between w-full items-end flex-wrap gap-y-2">
          <div className="flex flex-row gap-1 flex-wrap">
            <SelectField
              displayKey="name"
              placeholder={t("promotions.product")}
              options={[null, ...productFilter]}
              value={productFilter.find(
                (item) => item.product_id === filter.product_id
              )}
              change={(value: ProductFilter) => {
                setFilter((prev) => ({
                  ...prev,
                  product_id: value.product_id,
                }));
              }}
            />
          </div>
          <Button onClick={openPromotionForm} icon={<FaPlus />}>
            {t("promotions.create_new_promotion")}
          </Button>
        </div>
      </div>
      {promotions && (
        <Table
          dataSource={promotions.data}
          dataSourceSize={promotions.number_of_promotions}
          columns={[
            "product",
            "amount",
            "start_date",
            "end_date",
            "created_at",
            "updated_at",
            "actions",
          ]}
          customColumns={customColumns}
          translateKey="promotions"
          pageData={pageData}
          changePage={(page: number, limit: number) =>
            setPageData({ page, limit })
          }
          orderKeys={["created_at", "updated_at"]}
          order={orderData}
          orderChange={(order: OrderData | undefined) => {
            setOrderData(order);
          }}
        />
      )}
    </div>
  );
}
export default Promotions;
