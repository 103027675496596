import { AxiosResponse } from "axios";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { PermissionTag } from "src/components/auth/Permissions";
import { VerifyResponse } from "src/data/Auth";
import axiosInstance from "src/shared/axiosInstance";

interface AuthContextType {
  isLoggedIn: boolean;
  loading: boolean;
  loggedInUserId: number | undefined;
  login: (id?: number) => void;
  logout: () => void;
  hasPermission: (permissionToCheck: PermissionTag) => boolean;
  setUserPermissions: (permissions: PermissionTag[]) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [loggedInUserId, setLoggedInUserId] = useState<number>();
  const [permissions, setPermissions] = useState<PermissionTag[] | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    checkAuth();
  }, []);

  function checkAuth() {
    axiosInstance
      .get<VerifyResponse>("/login/verify")
      .then((response: AxiosResponse<VerifyResponse>) => {
        if (response.data.message.permission) {
          setPermissions(response.data.message.permission);
        }
        const userId = localStorage.getItem("id");
        login(userId ? +userId : undefined);
      })
      .catch(() => {
        logout();
      });
  }

  const login = (id?: number) => {
    setLoggedInUserId(id);
    setIsLoggedIn(true);
    setLoading(false);
  };

  const logout = () => {
    delete axiosInstance.defaults.headers["token"];
    delete axiosInstance.defaults.headers["id"];
    setLoggedInUserId(undefined);
    setIsLoggedIn(false);
    setLoading(false);
    setPermissions(undefined);
  };

  const setUserPermissions = (permissions: PermissionTag[]) => {
    setPermissions(permissions);
  };

  const hasPermission = (permissionToCheck: PermissionTag) => {
    if (!permissions) return false;
    return permissions.includes(permissionToCheck);
  };

  return (
    <AuthContext.Provider
      value={{
        setUserPermissions,
        hasPermission,
        loggedInUserId,
        isLoggedIn,
        loading,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
