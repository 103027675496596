import { FaSearch } from "react-icons/fa";
import { useQuery } from "react-query";
import Loading from "src/components/loading/Loading";
import { Product } from "src/data/Product";
import ProductItem from "src/pages/product-list/ProductItem";
import axiosInstance from "src/shared/axiosInstance";

function ProductList() {
  const getProducts = async () => {
    const response = await axiosInstance.get<Product[]>("/product/all");
    return response.data;
  };

  const {
    data: products,
    error,
    isLoading,
  } = useQuery<Product[], Error>("/product/all", getProducts);

  return (
    <div>
      <div className="w-full h-52 bg-banner-bg bg-cover bg-center flex items-center justify-center flex-col gap-2">
        <div className="w-fit flex flex-col gap-4 p-2">
          <span className="title-font text-color-on-primary font-bold text-3xl drop-shadow-sm text-center">
            NADOVI AUTÓALKATRÉSZ{" "}
            <span className="italic text-color-primary">WEBSHOP</span>
          </span>
          <div className="bg-bg-primary2 text-text-primary py-1 px-2 rounded flex justify-between items-center">
            <input
              className="text-lg placeholder-text-primary w-full outline-0 border-0"
              type="text"
              placeholder="Mire van szükséged?"
            />
            <FaSearch className="text-color-primary text-lg" />
          </div>
        </div>
      </div>

      <span>Termékek</span>
      <div className="flex flex-row gap-2 flex-wrap">
        {isLoading && <Loading />}
        {!isLoading &&
          products?.map((product, index) => (
            <ProductItem key={index} product={product} />
          ))}
      </div>
    </div>
  );
}
export default ProductList;
