import { t } from "i18next";
import { useState } from "react";
import Button from "src/components/form-components/Button";
import TextArea from "src/components/form-components/TextArea";
import { usePopup } from "src/components/popup/ModalContext";

interface AdminCommentPopupProps {
  comment: string;
  save: (comment: string) => void;
}

function AdminCommentPopup({ comment, save }: AdminCommentPopupProps) {
  const [adminComment, setAdminComment] = useState(comment);

  const { closePopup } = usePopup();

  return (
    <div className="flex flex-col gap-1">
      <TextArea
        value={adminComment}
        change={(value: string) => {
          setAdminComment(value);
        }}
        placeholder={t("product_return.admin_comment")}
      />
      <Button
        onClick={() => {
          save(adminComment);
        }}
      >
        {t("general.save")}
      </Button>
      <Button
        color="secondary"
        onClick={() => {
          closePopup();
        }}
      >
        {t("general.cancel")}
      </Button>
    </div>
  );
}
export default AdminCommentPopup;
