import { AxiosError, AxiosResponse } from "axios";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import Button from "src/components/form-components/Button";
import InputField from "src/components/form-components/InputField";
import SelectField from "src/components/form-components/SelectField";
import { ErrorItem, ErrorMessageResponse, MessageResponse } from "src/data/Api";
import { CategoryCreateRequest, ProductCategory } from "src/data/Product";
import axiosInstance from "src/shared/axiosInstance";

interface ProductCategoryFormProps {
  afterCreate: () => void;
  category?: ProductCategory;
  parents?: { id: number; name: string }[];
}

function ProductCategoryForm({
  afterCreate,
  category,
  parents,
}: ProductCategoryFormProps) {
  const { showAlert } = useAlert();
  const [error, setError] = useState<ErrorItem>();
  const [allParents, setAllParents] =
    useState<{ id: number; name: string }[]>();

  const [categoryForm, setCategoryForm] = useState<{
    id?: number;
    name: string;
    parents: { id: number }[];
  }>({
    name: "",
    parents: [],
  });

  useEffect(() => {
    setAllParents(parents);
  }, [parents]);

  useEffect(() => {
    if (!category) return;
    setCategoryForm({
      id: category.id,
      name: category.name,
      parents: category.parents.map((parent) => ({
        id: parent.id,
        name: parent.name,
      })),
    });
  }, [category]);

  const handleCreate = () => {
    const requestBody: CategoryCreateRequest = {
      name: categoryForm.name,
    };
    if (categoryForm.parents.length > 0) {
      requestBody.parents = categoryForm.parents.map((parent) => ({
        parent_id: parent.id,
      }));
    }
    if (categoryForm.id) {
      requestBody.id = categoryForm.id;
      axiosInstance
        .put("/admin/category", requestBody)
        .then((response: AxiosResponse<MessageResponse>) => {
          showAlert(
            AlertType.SUCCESS,
            t("general.messages.successful_update"),
            t(`general.messages.${response.data.message.success[0]}`)
          );
          afterCreate();
        })
        .catch((error: AxiosError<ErrorMessageResponse>) =>
          setError(error.response?.data.message)
        );
    } else {
      axiosInstance
        .post("/admin/category", requestBody)
        .then((response: AxiosResponse<MessageResponse>) => {
          showAlert(
            AlertType.SUCCESS,
            t("general.messages.successful_create"),
            t(`general.messages.${response.data.message.success[0]}`)
          );
          afterCreate();
        })
        .catch((error: AxiosError<ErrorMessageResponse>) =>
          setError(error.response?.data.message)
        );
    }
  };

  return (
    <div className="flex flex-col w-full gap-1">
      <InputField
        placeholder={t("product_categories.name")}
        value={categoryForm.name}
        errorKey={error?.name}
        change={(value) =>
          setCategoryForm((prev) => ({ ...prev, name: value }))
        }
      />

      <SelectField
        placeholder={t("product_categories.select_parents")}
        options={allParents || []}
        value={categoryForm.parents}
        displayKey="name"
        change={(selected) =>
          setCategoryForm((prev) => ({ ...prev, parents: selected }))
        }
        multi
      />
      <Button onClick={handleCreate}>
        {categoryForm.id ? t("general.save") : t("general.create")}
      </Button>
    </div>
  );
}
export default ProductCategoryForm;
