import { AxiosError, AxiosResponse } from "axios";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import Button from "src/components/form-components/Button";
import InputField from "src/components/form-components/InputField";
import SelectField from "src/components/form-components/SelectField";
import TextArea from "src/components/form-components/TextArea";
import { FullscreenLoading } from "src/components/loading/Loading";
import { usePopup } from "src/components/popup/ModalContext";
import { ErrorItem, ErrorMessageResponse, MessageResponse } from "src/data/Api";
import { CategoryParents, Product, ProductDetails } from "src/data/Product";
import axiosInstance from "src/shared/axiosInstance";

export const getProductDetails = async (product_id: string) => {
  const response = await axiosInstance.get(`/admin/product`, {
    params: {
      product_id,
    },
  });
  return response.data;
};

interface ProductFormProps {
  categories: CategoryParents[];
  afterSave: () => void;
  product?: Product;
  mode?: "edit" | "create" | "read";
}

function ProductForm({
  categories,
  afterSave,
  product,
  mode = "create",
}: ProductFormProps) {
  const { showAlert } = useAlert();
  const { closePopup } = usePopup();
  const [productForm, setProductForm] = useState<Partial<ProductDetails>>();
  const [formError, setFormError] = useState<ErrorItem>();
  const [currentMode, setCurrentMode] = useState(mode);

  const { data: productDetails } = useQuery<ProductDetails | undefined>(
    ["productDetails", product],
    () => {
      if (!product || mode === "create") return undefined;
      return getProductDetails(product.product_id);
    },
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    setProductForm(productDetails);
  }, [productDetails]);

  function handleSubmit() {
    if (currentMode === "create") {
      axiosInstance
        .post("/admin/product", productForm)
        .then((response: AxiosResponse<MessageResponse>) => {
          showAlert(
            AlertType.SUCCESS,
            t("general.messages.successful_create"),
            t(`admin_products.${response.data.message.success[0]}`)
          );
          closePopup();
          afterSave();
        })
        .catch((error: AxiosError<ErrorMessageResponse>) =>
          setFormError(error.response?.data.message)
        );
    } else if (currentMode === "edit") {
      axiosInstance
        .put("/admin/product", productForm)
        .then((response: AxiosResponse<MessageResponse>) => {
          showAlert(
            AlertType.SUCCESS,
            t("general.messages.successful_update"),
            t(`general.messages.${response.data.message.success[0]}`)
          );
          closePopup();
          afterSave();
        })
        .catch((error: AxiosError<ErrorMessageResponse>) =>
          setFormError(error.response?.data.message)
        );
    }
  }

  function changeToEdit() {
    setCurrentMode("edit");
  }

  function resetForm() {
    setCurrentMode("read");
    setProductForm(productDetails);
  }

  if (!product) {
    return <FullscreenLoading />;
  }

  return (
    <div className="flex w-full flex-col gap-1">
      {product && (
        <span>
          {t("admin_products.product_id")}:{" "}
          <strong>{product.product_id}</strong>
        </span>
      )}

      <a
        href={`/product/${product.product_id}`}
        target="_blank"
        className="w-fit"
      >
        <Button outline>{t("admin_products.open_product_page")}</Button>
      </a>
      <InputField
        placeholder={t("admin_products.name")}
        value={productForm?.name}
        change={(value) => setProductForm((prev) => ({ ...prev, name: value }))}
        required
        disabled={currentMode === "read"}
        errorKey={formError?.name}
      />
      <div className="flex flex-row gap-1 max-lg:flex-col">
        <InputField
          value={productForm?.original_price}
          change={(value) =>
            setProductForm((prev) => ({ ...prev, original_price: value }))
          }
          placeholder={t("admin_products.price")}
          type="number"
          errorKey={formError?.original_price}
          disabled={currentMode === "read"}
          required
        />
        <InputField
          value={productForm?.weight}
          change={(value) =>
            setProductForm((prev) => ({ ...prev, weight: value }))
          }
          placeholder={t("admin_products.weight")}
          errorKey={formError?.weight}
          type="number"
          required
          disabled={currentMode === "read"}
        />
      </div>
      <InputField
        value={productForm?.warranty_years}
        change={(value) =>
          setProductForm((prev) => ({ ...prev, warranty_years: value }))
        }
        errorKey={formError?.warranty_years}
        required
        placeholder={t("admin_products.warranty_years")}
        type="number"
        disabled={currentMode === "read"}
      />
      <SelectField
        value={productForm?.category}
        change={(value) =>
          setProductForm((prev) => ({
            ...prev,
            category: value,
            category_id: value.id,
          }))
        }
        disabled={currentMode === "read"}
        required
        placeholder={t("admin_products.category")}
        options={categories}
        errorKey={formError?.category_id}
        displayKey="name"
      ></SelectField>
      <InputField
        errorKey={formError?.manufacturer}
        placeholder={t("admin_products.manufacturer")}
        value={productForm?.manufacturer}
        change={(value) =>
          setProductForm((prev) => ({ ...prev, manufacturer: value }))
        }
        disabled={currentMode === "read"}
      />
      <InputField
        placeholder={t("admin_products.color")}
        errorKey={formError?.color}
        value={productForm?.color}
        change={(value) =>
          setProductForm((prev) => ({ ...prev, color: value }))
        }
        disabled={currentMode === "read"}
      />
      <TextArea
        placeholder={t("admin_products.description")}
        errorKey={formError?.description}
        value={productForm?.description}
        change={(value) =>
          setProductForm((prev) => ({ ...prev, description: value }))
        }
        disabled={currentMode === "read"}
      />
      {currentMode === "create" && (
        <Button onClick={handleSubmit}>{t("general.create")}</Button>
      )}
      {currentMode === "edit" && (
        <div className="flex flex-row gap-1 w-full">
          <Button className="w-full" onClick={handleSubmit}>
            {t("general.save")}
          </Button>
          <Button
            className="w-full"
            onClick={() => resetForm()}
            color="secondary"
          >
            {t("general.cancel")}
          </Button>
        </div>
      )}
      {currentMode === "read" && (
        <Button onClick={changeToEdit}>{t("general.edit")}</Button>
      )}
    </div>
  );
}
export default ProductForm;
