import { AxiosResponse } from "axios";
import { t } from "i18next";
import { useEffect, useState } from "react";
import Loading from "src/components/loading/Loading";
import { OrderDetailsData, OrderStatus } from "src/data/Order";
import OrderItem from "src/pages/profile/OrderItem";
import axiosInstance from "src/shared/axiosInstance";

function OrdersList() {
  const [orders, setOrders] = useState<OrderDetailsData[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosInstance
      .get<OrderDetailsData[]>("/order")
      .then((response: AxiosResponse<OrderDetailsData[]>) => {
        setOrders(response.data);
        setLoading(false);
      });
  }, []);

  return (
    <div className="w-full rounded">
      <h1 className="text-lg font-bold mb-2">{t("order.previous_orders")}</h1>
      <div className="flex flex-col gap-3">
        {orders.map((order, index) => (
          <OrderItem
            order={order}
            key={index}
            cancel={() => {
              order.order_status = OrderStatus.CANCELLED;
            }}
          />
        ))}
        {loading && (
          <div className="w-full flex justify-center p-10">
            <Loading />
          </div>
        )}
        {loading === false && orders.length === 0 && (
          <span>{t("order.no_orders")}</span>
        )}
      </div>
    </div>
  );
}
export default OrdersList;
