import { AxiosResponse } from "axios";
import { t } from "i18next";
import { useEffect, useState } from "react";
import AdminOrderDetails from "src/admin/pages/orders/AdminOrderDetails";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import SelectField from "src/components/form-components/SelectField";
import { FullscreenLoading } from "src/components/loading/Loading";
import { usePopup } from "src/components/popup/ModalContext";
import DateCell from "src/components/table/date-cell/DateCell";
import OptionChangeCell from "src/components/table/option-cell/OptionChangeCell";
import Table, { PageData } from "src/components/table/Table";
import { DEFAULT_PAGE_SIZE } from "src/components/table/TablePaginator";
import {
  OrderDetailsData,
  OrderFilterResponse,
  OrderStatus,
  PaginatedOrderResponse,
  ProductStatus,
} from "src/data/Order";
import axiosInstance from "src/shared/axiosInstance";

function Orders() {
  const { openPopup, closePopup } = usePopup();
  const { showAlert } = useAlert();
  const [orders, setOrders] = useState<OrderDetailsData[]>();
  const [statusData, setStatusData] = useState<OrderFilterResponse>();
  const [pageData, setPageData] = useState<PageData>({
    limit: DEFAULT_PAGE_SIZE,
    page: 1,
  });
  const [filter, setFilter] = useState<{ key: string; value: any }[]>([]);

  const columns = [
    "id",
    "created_at",
    "contact",
    "order_status",
    "payment_status",
    "product_status",
    "total_price",
  ];

  const customColumns = {
    contact: (row: OrderDetailsData) => (
      <div className="flex flex-col">
        <span>{row.email}</span>
        <span>{row.phone_number}</span>
      </div>
    ),
    created_at: (row: OrderDetailsData) => <DateCell date={row.created_at} />,
    total_price: (row: OrderDetailsData) => <span>{row.total_price} Ft</span>,
    total_weight: (row: OrderDetailsData) => <span>{row.total_weight} g</span>,
    order_status: (row: OrderDetailsData) => (
      <OptionChangeCell
        valueKey="order.order_statuses"
        dataName={t("admin_order.order_status")}
        value={row.order_status}
        update={(state: string) => changeStatus("order_status", row.id, state)}
        afterChange={() => {}}
        options={getPossibleOrderStatuses(row.order_status, row.product_status)}
      />
    ),
    product_status: (row: OrderDetailsData) => (
      <OptionChangeCell
        valueKey="order.product_statuses"
        dataName={t("admin_order.product_status")}
        value={row.product_status}
        update={(state: string) =>
          changeStatus("product_status", row.id, state)
        }
        afterChange={() => {}}
        options={getPossibleProductStatuses(
          row.product_status,
          row.order_status
        )}
      />
    ),
    payment_status: (row: OrderDetailsData) => (
      <OptionChangeCell
        valueKey="order.payment_statuses"
        dataName={t("admin_order.payment_status")}
        value={row.payment_status}
        update={(state: string) =>
          changeStatus("payment_status", row.id, state)
        }
        afterChange={() => {}}
        options={statusData?.payment_status || []}
      />
    ),
  };

  function changeStatus(
    key: "order_status" | "product_status" | "payment_status",
    id: string,
    status: string
  ) {
    return axiosInstance.put("/admin/order", { id, [key]: status }).then(() => {
      closePopup();
      showAlert(
        AlertType.SUCCESS,
        t("general.messages.successful_update"),
        t("admin_order.status_update_message", {
          status: t(`admin_order.${key}`),
        })
      );
      setOrders((prev) =>
        prev?.map((item) =>
          item.id === id ? { ...item, [key]: status } : item
        )
      );
    });
  }

  function getPossibleProductStatuses(
    product_status: ProductStatus,
    order_status: OrderStatus
  ) {
    //product_statust akkor lehet Pending-re állítani, ha az order_status Processing
    if (order_status !== OrderStatus.PROCESSING) return [product_status];

    return statusData?.product_status || [];
  }

  function getPossibleOrderStatuses(
    order_status: OrderStatus,
    product_status: ProductStatus
  ) {
    if (!statusData) return [];

    //order_statust-t Rejected-re bármikor lehet rakni
    let possibleStatuses: string[] = [order_status, OrderStatus.REJECTED];

    //order_status-t Cancelled-ről csak Pending-re lehet rakni
    //order_status-t Rejected-ről csak Pending-re lehet rakni
    if (
      order_status === OrderStatus.CANCELLED ||
      order_status === OrderStatus.REJECTED
    ) {
      possibleStatuses.push(OrderStatus.PENDING);
      return possibleStatuses;
    }

    //order_status-t Processing-re csak Pending staatusról lehet átrakni
    if (order_status === OrderStatus.PENDING) {
      possibleStatuses.push(OrderStatus.PROCESSING);
      return possibleStatuses;
    }

    //order_status-t Shipping-re csak Processing staatusról lehet átrakni
    //order_status-t akkor lehet Shipping-re rakni, ha a product_status Packed
    if (
      order_status === OrderStatus.PROCESSING &&
      product_status === ProductStatus.PACKED
    ) {
      possibleStatuses.push(OrderStatus.SHIPPING);
      return possibleStatuses;
    }

    //order_status-t Completed-re csak Shipping statusról lehet átrakni
    if (order_status === OrderStatus.SHIPPING) {
      possibleStatuses.push(OrderStatus.COMPLETED);
      return possibleStatuses;
    }

    return possibleStatuses;
  }

  useEffect(() => {
    axiosInstance
      .get<OrderFilterResponse>("/admin/orders/filters")
      .then((response: AxiosResponse<OrderFilterResponse>) => {
        setStatusData(response.data);
      });
  }, []);

  function getOrders() {
    axiosInstance
      .get<PaginatedOrderResponse>("/admin/orders", {
        params: {
          limit: pageData.limit,
          page: pageData.page,

          ...filter.reduce((acc: any, { key, value }) => {
            if (value) acc[key] = value;
            return acc;
          }, {}),
        },
      })
      .then((response: AxiosResponse<PaginatedOrderResponse>) => {
        setOrders(response.data.data);
        setPageData((prev) => ({
          ...prev,
          dataSourceSize: response.data.number_of_orders,
        }));
      });
  }

  useEffect(() => {
    getOrders();
  }, [filter, pageData.page, pageData.limit]);

  function changeFilter(key: string, value: any) {
    if (filter.find((item) => item.key === key)) {
      setFilter((prev) =>
        prev.map((filter) => (filter.key === key ? { key, value } : filter))
      );
    } else {
      setFilter((prev) => [...prev, { key, value }]);
    }
  }

  if (!orders) {
    return <FullscreenLoading />;
  }

  return (
    <div className="w-full px-10 py-2">
      <div className="w-full mb-2 flex flex-row gap-2 flex-wrap">
        {statusData && (
          <>
            <SelectField
              displayTranslateKey="order.order_statuses"
              placeholder={t("admin_order.order_status")}
              value={filter.find((item) => item.key === "order_status")?.value}
              options={["", ...statusData.order_status_filters]}
              change={(value) => changeFilter("order_status", value)}
            ></SelectField>
            <SelectField
              displayTranslateKey="order.payment_statuses"
              placeholder={t("admin_order.payment_status")}
              value={
                filter.find((item) => item.key === "payment_status")?.value
              }
              options={["", ...statusData.payment_status_filters]}
              change={(value) => changeFilter("payment_status", value)}
            ></SelectField>
            <SelectField
              placeholder={t("admin_order.product_status")}
              displayTranslateKey="order.product_statuses"
              value={
                filter.find((item) => item.key === "product_status")?.value
              }
              options={["", ...statusData.product_status_filters]}
              change={(value) => changeFilter("product_status", value)}
            ></SelectField>
          </>
        )}
      </div>
      <Table
        dataSource={orders}
        columns={columns}
        customColumns={customColumns}
        translateKey="admin_order"
        DetailComponent={AdminOrderDetails}
        pageData={pageData}
        changePage={(page: number, limit: number) =>
          setPageData({ page, limit })
        }
      />
    </div>
  );
}
export default Orders;
