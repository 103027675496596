import { useEffect, useRef, useState } from "react";
import {
  FaCaretLeft,
  FaCaretRight,
  FaCheckCircle,
  FaRegStar,
  FaShoppingCart,
  FaStar,
} from "react-icons/fa";
import { FaRegStarHalfStroke } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import Button from "src/components/form-components/Button";
import { useImageViewer } from "src/components/image-viewer/ImageViewerContext";

interface ProductDetailsProps {}

function ProductDetails({}: ProductDetailsProps) {
  const { id } = useParams();

  const [productId, setProductId] = useState<string>();

  const imgScrollRef = useRef<HTMLDivElement | null>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const { openImageViewer } = useImageViewer();

  const [starHoverIndex, setStarHoverIndex] = useState<number | null>(null);
  const [selectedStar, setSelectedStar] = useState<number | null>(null);

  const updateScrollButtons = () => {
    if (imgScrollRef.current) {
      setCanScrollLeft(imgScrollRef.current.scrollLeft > 0);
      setCanScrollRight(
        imgScrollRef.current.scrollLeft <
          imgScrollRef.current.scrollWidth - imgScrollRef.current.clientWidth
      );
    }
  };

  const scrollLeft = () => {
    if (!imgScrollRef.current) return;
    imgScrollRef.current.scrollBy({
      left: -150,
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    if (!imgScrollRef.current) return;
    imgScrollRef.current.scrollBy({
      left: 150,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const currentRef = imgScrollRef.current;

    const handleScroll = () => {
      updateScrollButtons();
    };

    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, [imgScrollRef]);

  const openImage = () => {
    openImageViewer(
      [
        {
          id: 1,
          image_url:
            "https://www.futomuwebshop.hu/fotky3387/fotos/_vyr_1231st1222628.png",
        },
      ],
      0
    );
  };

  useEffect(() => {
    setProductId(id);
  }, []);

  return (
    <div className="w-full h-full flex justify-center p-5">
      <div className="w-[65%] max-xl:w-full">
        <div className="flex flex-row gap-5 max-md:flex-col">
          <div className="flex flex-col w-96 h-96 max-md:w-full select-none">
            <div className="flex-1 flex-grow min-h-0 w-full flex justify-center items-center">
              <img
                onClick={openImage}
                className="object-contain rounded max-w-full max-h-full h-fit w-fit"
                src="https://www.futomuwebshop.hu/fotky3387/fotos/_vyr_1231st1222628.png"
                alt="Triscan hajtótengely 460mm"
              />
            </div>
            <div className="flex flex-row gap-2 items-center justify-between w-full p-1">
              <div
                className={`px-1 h-full flex items-center justify-center rounded ${
                  canScrollLeft
                    ? "hover:bg-table-border"
                    : "text-text-secondary opacity-20"
                }`}
                onClick={scrollLeft}
              >
                <FaCaretLeft />
              </div>
              <div
                className="flex flex-row flex-1 gap-2 items-center overflow-auto scrollbar-hidden"
                ref={imgScrollRef}
              >
                <img src="https://placehold.co/50x50" alt="" />
                <img src="https://placehold.co/50x50" alt="" />
                <img src="https://placehold.co/50x50" alt="" />
                <img src="https://placehold.co/50x50" alt="" />
                <img src="https://placehold.co/50x50" alt="" />
                <img src="https://placehold.co/50x50" alt="" />
                <img src="https://placehold.co/50x50" alt="" />
                <img src="https://placehold.co/50x50" alt="" />
                <img src="https://placehold.co/50x50" alt="" />
                <img src="https://placehold.co/50x50" alt="" />
              </div>
              <div
                className={`px-1 h-full flex items-center justify-center rounded ${
                  canScrollRight
                    ? "hover:bg-table-border"
                    : "text-text-secondary opacity-20"
                }`}
                onClick={scrollRight}
              >
                <FaCaretRight />
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-1 flex-1 relative">
            <h1 className="title-font text-3xl font-bold text-color-primary uppercase">
              Triscan hajtótengely 460mm
            </h1>
            <div className="flex flex-row gap-5 items">
              <div>
                <span className="text-green flex flex-row gap-1 items-center">
                  <FaCheckCircle /> Raktáron
                </span>
              </div>
              <div className="flex flex-row gap-1 text-yellow text-xl drop-shadow-sm">
                <FaStar />
                <FaStar />
                <FaRegStarHalfStroke />
                <FaRegStar />
                <FaRegStar />
              </div>
            </div>
            <div className="flex gap-2 items-center">
              <span className="text-lg font-bold">25 555.- Ft</span>
              <span>(-44 %)</span>
              <span className="text-sm font-bold text-color-primary line-through">
                45 555.- Ft
              </span>
            </div>
            <span className="text-text-secondary text-sm">
              Az árak forintban értendők, és tartalmazzák az áfa értékét!
            </span>
            <div className="h-px bg-table-border w-full my-2" />
            <div className="flex-1">
              <div className="font-thin text-color-primary text-lg">
                A termékről
              </div>
              <div>
                A Triscan hajtótengely egy kiváló minőségű alkatrész, amelyet
                jellemzően különböző járművek hajtásláncában használnak. A 460
                mm hosszú tengelyt pontosan a jármű típusához igazodva
                tervezték, és rendkívül strapabíró anyagból készül, amely
                ellenáll a nagy terhelésnek és kopásnak. A Triscan hajtótengely
                biztosítja az egyenletes teljesítményátvitelt a motor és a
                kerekek között, hozzájárulva ezzel a sima, stabil vezetési
                élményhez és a jármű optimális működéséhez.
              </div>
            </div>
            <div className="h-px bg-table-border w-full my-2" />
            <div className="flex flex-row gap-2">
              <span className="font-thin text-color-primary">
                Várható kiszállítás:
              </span>
              <span>2 - 3 munkanap</span>
            </div>
            <div className="flex flex-row gap-1 max-sm:flex-col">
              <div className="flex items-center bg-bg-secondary border border-bg-secondary rounded-3">
                <div className="px-2 text-color-on-primary cursor-pointer">
                  -
                </div>
                <div className="bg-bg-primary flex-1 px-3 h-full flex items-center justify-center">
                  1
                </div>
                <div className="px-2 text-color-on-primary cursor-pointer">
                  +
                </div>
              </div>
              <Button icon={<FaShoppingCart />}>Kosárba</Button>
              <Button color="secondary">Tovább a pénztárhoz</Button>
            </div>
          </div>
        </div>
        <div className="h-px bg-table-border w-full my-5" />
        <div className="flex flex-col gap-2">
          <span className="font-thin text-lg text-color-primary">
            Termék műszaki adatai
          </span>
          <div className="flex flex-row gap-1">
            <span className="font-thin text-text-secondary">Súly:</span>
            <span>1320 g</span>
          </div>
          <div className="flex flex-row gap-1">
            <span className="font-thin text-text-secondary">Hossz:</span>
            <span>500 mm</span>
          </div>
          <div className="flex flex-row gap-1">
            <span className="font-thin text-text-secondary">
              Beépítési oldal:
            </span>
            <span>Bal első, jobb első</span>
          </div>
          <div className="flex flex-row gap-1">
            <span className="font-thin text-text-secondary">Gyártó:</span>
            <span>Valami valami</span>
          </div>
          <div className="flex flex-row gap-1">
            <span className="font-thin text-text-secondary">Cikkszám:</span>
            <span>202252</span>
          </div>
        </div>
        <div className="h-px bg-table-border w-full my-5" />
        <div className="flex flex-col gap-1">
          <span className="font-thin text-lg text-color-primary">
            Mihez jó?
          </span>
          <span className="text-sm text-text-secondary">
            Az alkatrész az lábbi típusokkal kompatibilis:
          </span>
          <div className="flex flex-col gap-1">
            <details className="border rounded-3 border-table-border p-1 px-2 bg-bg-primary2">
              <summary className="cursor-pointer font-semibold">Audi</summary>
              <ul className="list-disc ps-10">
                <li>A4 Allroad B9</li>
                <li>A4 B8</li>
              </ul>
            </details>
            <details className="border rounded-3 border-table-border p-1 px-2 bg-bg-primary2">
              <summary className="cursor-pointer font-semibold">
                Volkswagen
              </summary>
              <ul className="list-disc ps-10">
                <li>Golf VII</li>
                <li>Golf V</li>
              </ul>
            </details>
          </div>
        </div>
        <div className="h-px bg-table-border w-full my-5" />
        <div className="flex flex-col gap-2">
          <span className="font-thin text-lg text-color-primary">
            Értékelések
          </span>
          <div className="flex flex-col gap-10">
            <div className="flex flex-row gap-10 max-md:flex-col">
              <div className="h-full flex justify-center flex-col items-center">
                <div className="text-5xl text-text-secondary text-center">
                  2.45
                </div>
                <div className="flex flex-row gap-1 text-yellow text-xl drop-shadow-sm">
                  <FaStar />
                  <FaStar />
                  <FaRegStarHalfStroke />
                  <FaRegStar />
                  <FaRegStar />
                </div>
                <span className="text-text-secondary">34 vélemény</span>
              </div>
              <div className="flex flex-col max-md:w-full">
                {[...Array(5)].map((_, index) => (
                  <div className="flex flex-row gap-2 items-center">
                    <span className="w-24 text-end">{5 - index} csillag</span>
                    <div className="w-60 max-md:w-full h-3 bg-table-border rounded-full overflow-hidden">
                      <div
                        className={`w-[50%] bg-${5 - index}-star h-full`}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex flex-col gap-2 max-md:w-full">
                <span className="text-text-secondary">
                  Oszd meg véleményed a termékről!
                </span>
                <div className="flex flex-row text-yellow text-3xl drop-shadow-sm">
                  {[...Array(5)].map((_, index) => (
                    <div
                      key={index}
                      onMouseEnter={() => setStarHoverIndex(index)}
                      onMouseLeave={() => setStarHoverIndex(null)}
                      onClick={() => setSelectedStar(index)}
                    >
                      {index <= (starHoverIndex ?? selectedStar ?? -1) ? (
                        <FaStar />
                      ) : (
                        <FaRegStar />
                      )}
                    </div>
                  ))}
                </div>
                <Button className="w-fit" color="secondary">
                  Vélemény írása
                </Button>
              </div>
            </div>
            <div className="flex flex-col divide-y divide-table-border divide-">
              {[...Array(5)].map((_, index) => (
                <div className=" flex flex-row gap-5 p-5">
                  <div className="rounded-full overflow-hidden w-14 h-14">
                    <img src="https://placehold.co/50x50" />
                  </div>
                  <div className="flex-1">
                    <span className="font-bold">Szanda Soma</span>
                    <div className="flex flex-row gap-1 text-yellow drop-shadow-sm">
                      <FaStar />
                      <FaStar />
                      <FaRegStar />
                      <FaRegStar />
                      <FaRegStar />
                    </div>
                    <span className="text-sm text-color-secondary">
                      2024. 03. 04
                    </span>
                    <p>
                      Nagyon jó minőségű termék! Gyorsan megérkezett, és a
                      csomagolása is rendben volt. Egyedül a mérete volt kicsit
                      kisebb, mint vártam.
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProductDetails;
