import { t } from "i18next";
import { MdModeEdit } from "react-icons/md";
import { usePopup } from "src/components/popup/ModalContext";
import OptionChangePopup from "src/components/table/option-cell/OptionChangePopup";

interface OptionChangeCellProps {
  options: any[];
  value: any;
  update: (value: any) => Promise<any>;
  afterChange: (value: any) => void;
  dataName: string;
  valueKey: string;
  disabled?: boolean;
}

function OptionChangeCell({
  options,
  value,
  update,
  afterChange,
  dataName,
  valueKey,
  disabled = false,
}: OptionChangeCellProps) {
  const { openPopup } = usePopup();

  return (
    <div
      className={`flex flex-row gap-2 items-center p-1 px-2 rounded-full w-fit border border-transparent ${
        !disabled
          ? "hover:border-table-border hover:shadow hover:bg-bg-primary cursor-pointer"
          : ""
      }`}
      onClick={(e) => {
        if (disabled) return;
        e.stopPropagation();
        openPopup(
          t("table.option_change_popup_title", { name: dataName }),

          <OptionChangePopup
            valueKey={valueKey}
            value={value}
            options={options}
            update={update}
            dataName={dataName}
            afterChange={afterChange}
          />
        );
      }}
    >
      <span>{t(`${valueKey}.${value}`)}</span>
      <span className="text-lg">{!disabled && <MdModeEdit />}</span>
    </div>
  );
}

export default OptionChangeCell;
