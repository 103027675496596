import { AxiosError, AxiosResponse } from "axios";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import Button from "src/components/form-components/Button";
import InputField from "src/components/form-components/InputField";
import SelectField from "src/components/form-components/SelectField";
import TextArea from "src/components/form-components/TextArea";
import { usePopup } from "src/components/popup/ModalContext";
import { ErrorItem, ErrorMessageResponse } from "src/data/Api";
import { User } from "src/data/User";
import { UserGroup, UserGroupFormType } from "src/data/UserGroup";
import axiosInstance from "src/shared/axiosInstance";

interface UserGroupFormProps {
  afterSubmit: () => void;
  groupData?: UserGroup;
  edit?: boolean;
}

function UserGroupForm({ afterSubmit, groupData }: UserGroupFormProps) {
  const { closePopup } = usePopup();
  const { showAlert } = useAlert();
  const [userGroupForm, setUserGroupForm] =
    useState<Partial<UserGroupFormType>>();
  const [error, setError] = useState<ErrorItem>();

  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    if (groupData) {
      setUserGroupForm({
        ...groupData,
        member_ids: groupData.members.map((member) => member.id),
      });
    }
  }, [groupData]);

  useEffect(() => {
    axiosInstance
      .get(`/admin/user/group/users`)
      .then((response: AxiosResponse<User[]>) => setUsers(response.data));
  }, []);

  function submit() {
    if (groupData) {
      axiosInstance
        .put("/admin/user/group", userGroupForm)
        .then(() => {
          showAlert(
            AlertType.INFO,
            t("general.messages.successful_update"),
            t("user_groups.group_updated")
          );
          closePopup();
          afterSubmit && afterSubmit();
        })
        .catch((error: AxiosError<ErrorMessageResponse>) => {
          setError(error.response?.data.message);
        });
    } else {
      axiosInstance
        .post("/admin/user/group", userGroupForm)
        .then(() => {
          showAlert(
            AlertType.INFO,
            t("general.messages.successful_create"),
            t("user_groups.group_created")
          );
          closePopup();
          afterSubmit && afterSubmit();
        })
        .catch((error: AxiosError<ErrorMessageResponse>) => {
          setError(error.response?.data.message);
        });
    }
  }

  return (
    <div className="flex flex-col gap-1">
      <InputField
        value={userGroupForm?.name}
        change={(value: string) =>
          setUserGroupForm((prev) => ({ ...prev, name: value }))
        }
        placeholder={t("user_groups.name")}
        errorKey={error?.name}
      />
      <TextArea
        value={userGroupForm?.description}
        change={(value: string) =>
          setUserGroupForm((prev) => ({ ...prev, description: value }))
        }
        placeholder={t("user_groups.description")}
        errorKey={error?.description}
      />
      <SelectField
        multi
        options={users}
        value={users.filter((item) =>
          userGroupForm?.member_ids?.includes(item.id)
        )}
        displayKey="full_name"
        placeholder={t("user_groups.members")}
        change={(value: User[]) => {
          setUserGroupForm((prev) => ({
            ...prev,
            member_ids: value.map((item) => item.id),
          }));
        }}
        errorKey={error?.member_ids}
      />
      <Button onClick={submit}>
        {groupData ? t("general.save") : t("general.create")}
      </Button>
      <Button onClick={closePopup} color="secondary">
        {t("general.cancel")}
      </Button>
    </div>
  );
}
export default UserGroupForm;
