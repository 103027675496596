import { AxiosError, AxiosResponse } from "axios";
import { t } from "i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import Button from "src/components/form-components/Button";
import InputField from "src/components/form-components/InputField";
import { ErrorItem, ErrorMessageResponse, MessageResponse } from "src/data/Api";
import axiosInstance from "src/shared/axiosInstance";

export interface RegisterForm {
  email: string;
  password: string;
  password_again: string;
  first_name: string;
  last_name: string;
  phone_number: string;
}

export interface RegisterResponse {}

function Register() {
  const { showAlert } = useAlert();
  const [registerLoading, setRegisterLoading] = useState(false);
  const navigate = useNavigate();
  const [registerForm, setRegisterForm] = useState<RegisterForm>({
    email: "",
    password: "",
    password_again: "",
    first_name: "",
    last_name: "",
    phone_number: "",
  });

  const [registerErrors, setRegisterErrors] = useState<ErrorItem>();

  function submitRegister() {
    setRegisterErrors({});
    setRegisterLoading(true);
    if (registerForm.password !== registerForm.password_again) {
      setRegisterErrors({ password_again: ["PASWWORD_MATCH_ERROR"] });
    }
    axiosInstance
      .post<MessageResponse>("/register", registerForm)
      .then((response: AxiosResponse<MessageResponse>) => {
        showAlert(
          AlertType.SUCCESS,
          t("register.title"),
          t(`general.messages.${response.data.message.success}`)
        );
        setRegisterLoading(false);
      })
      .catch((error: AxiosError<ErrorMessageResponse>) => {
        setRegisterLoading(false);
        setRegisterErrors((prev) => ({
          ...prev,
          ...error.response?.data.message,
        }));
      });
  }

  return (
    <div className="flex justify-center p-10">
      <div className="h-fit flex flex-col items-center w-96">
        <h1 className="font-bold text-3xl text-gray-700 mb-2">
          {t("register.title")}
        </h1>
        <form className="flex flex-col w-full gap-4">
          <InputField
            placeholder={t("register.email")}
            type="email"
            value={registerForm.email}
            change={(value) =>
              setRegisterForm((prev) => ({ ...prev, email: value }))
            }
            errorKey={registerErrors?.email}
          />
          <InputField
            placeholder={t("register.last_name")}
            type="text"
            value={registerForm.last_name}
            change={(value) =>
              setRegisterForm((prev) => ({
                ...prev,
                last_name: value,
              }))
            }
            errorKey={registerErrors?.last_name}
          />
          <InputField
            placeholder={t("register.first_name")}
            type="text"
            value={registerForm.first_name}
            change={(value) =>
              setRegisterForm((prev) => ({
                ...prev,
                first_name: value,
              }))
            }
            errorKey={registerErrors?.first_name}
          />
          <InputField
            placeholder={t("register.phone_number")}
            type="phone_number"
            value={registerForm.phone_number}
            change={(value) =>
              setRegisterForm((prev) => ({
                ...prev,
                phone_number: value,
              }))
            }
            errorKey={registerErrors?.phone_number}
          />
          <InputField
            placeholder={t("register.password")}
            autoComplete="new-password"
            type="password"
            value={registerForm.password}
            change={(value) =>
              setRegisterForm((prev) => ({ ...prev, password: value }))
            }
            errorKey={registerErrors?.password}
          />
          <InputField
            placeholder={t("register.password_again")}
            type="password"
            autoComplete="new-password"
            value={registerForm.password_again}
            change={(value) =>
              setRegisterForm((prev) => ({
                ...prev,
                password_again: value,
              }))
            }
            errorKey={registerErrors?.password_again}
          />
          <Button
            onClick={() => submitRegister()}
            type="button"
            loading={registerLoading}
          >
            {t("register.submit")}
          </Button>
        </form>
        <a
          className="text-text-secondary underline cursor-pointer mt-5"
          onClick={() => navigate("/login")}
        >
          {t("register.back_to_login")}
        </a>
      </div>
    </div>
  );
}

export default Register;
