import axios, { AxiosResponse } from "axios";
import { environment } from "src/environment";
import { checkForFullname } from "src/shared/fullNameInterceptor";

const loginToken = localStorage.getItem("login_token") ?? undefined;
const id = localStorage.getItem("id") ?? undefined;

const axiosInstance = axios.create({
  baseURL: environment.serverUrl,
  headers:
    loginToken && id
      ? {
          token: loginToken,
          id,
        }
      : {},
});

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    checkForFullname(response.data);
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      if (
        error.response.status === 400 &&
        error.response.data.message.token &&
        window.location.pathname !== "/"
      ) {
        window.location.href = "/";
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
