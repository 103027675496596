import { t } from "i18next";
import { useState } from "react";
import { BiSolidCategoryAlt } from "react-icons/bi";
import { BsFillMegaphoneFill } from "react-icons/bs";
import {
  FaBox,
  FaHome,
  FaShoppingCart,
  FaStar,
  FaUndo,
  FaUser,
} from "react-icons/fa";
import { FaGear, FaUserGroup } from "react-icons/fa6";
import { MdDiscount, MdOutlineMenu } from "react-icons/md";
import { TiArrowBack } from "react-icons/ti";
import { Outlet } from "react-router-dom";
import MenuItem from "src/admin/layout/MenuItem";
import { useAuth } from "src/components/auth/AuthContext";
import { PermissionTag } from "src/components/auth/Permissions";

function AdminLayout() {
  const { hasPermission } = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);

  function handleMenuOpen() {
    setMenuOpen(!menuOpen);
  }

  const menuItems = [
    {
      title: t("admin_menu.back_to_store"),
      icon: <TiArrowBack />,
      link: "/",
    },
    {
      title: t("admin_menu.home"),
      icon: <FaHome />,
      link: "",
    },
    {
      title: t("admin_menu.orders"),
      icon: <FaShoppingCart />,
      link: "orders",
      permission: PermissionTag.ADMIN_ORDERS_PANEL,
    },
    {
      title: t("admin_menu.users"),
      icon: <FaUser />,
      link: "users",
      permission: PermissionTag.ADMIN_USERS_PANEL,
    },
    {
      title: t("admin_menu.user_groups"),
      icon: <FaUserGroup />,
      link: "userGroups",
      permission: PermissionTag.ADMIN_USER_GROUPS_PANEL,
    },
    {
      title: t("admin_menu.products"),
      icon: <FaBox />,
      link: "products",
      permission: PermissionTag.ADMIN_PRODUCTS_PANEL,
    },
    {
      title: t("admin_menu.reviews"),
      icon: <FaStar />,
      link: "reviews",
      permission: PermissionTag.ADMIN_LOGIN_PANEL, //TODO: Perm
    },
    {
      title: t("admin_menu.product_categories"),
      icon: <BiSolidCategoryAlt />,
      link: "productCategories",
      permission: PermissionTag.ADMIN_PRODUCT_CATEGORY_PANEL,
    },
    {
      title: t("admin_menu.product_returns"),
      icon: <FaUndo />,
      link: "productReturns",
      permission: PermissionTag.ADMIN_RETURN_PRODUCT_PANEL,
    },
    {
      title: t("admin_menu.discounts"),
      icon: <MdDiscount />,
      link: "discounts",
      permission: PermissionTag.ADMIN_DISCOUNTS_PANEL,
    },
    {
      title: t("admin_menu.promotions"),
      icon: <BsFillMegaphoneFill />,
      link: "promotions",
      permission: PermissionTag.ADMIN_PROMOTIONS_PANEL,
    },
    {
      title: t("admin_menu.settings"),
      icon: <FaGear />,
      link: "settings",
    },
  ];

  return (
    <div className="flex flex-row bg-bg-primary w-full h-full max-md:flex-col overflow-hidden">
      <nav className="flex h-full bg-bg-secondary shadow w-56 min-w-56 text-color-on-bg-secondary flex-col max-md:max-w-full max-md:w-full max-md:h-fit relative">
        <div className="flex flex-roe justify-between px-2 py-1">
          <div className="flex flex-col">
            <span className="text-color-primary text-2xl font-extrabold title-font leading-4 mt-1">
              NADOVI
            </span>
            <span className="font-thin text-sm">WEBADMIN</span>
          </div>
          <div
            className="items-center justify-center text-2xl hidden max-md:flex"
            onClick={handleMenuOpen}
          >
            <MdOutlineMenu />
          </div>
        </div>

        <div
          className={`max-md:overflow-hidden overflow-auto ${
            menuOpen ? "max-md:h-fit" : "max-md:h-0"
          }`}
        >
          <div className="p-2 flex flex-col gap-1">
            {menuItems.map(
              (menuItem, index) =>
                (menuItem.permission
                  ? hasPermission(menuItem.permission)
                  : true) && (
                  <MenuItem
                    key={index}
                    link={menuItem.link}
                    icon={menuItem.icon}
                    itemSelected={() => setMenuOpen(false)}
                  >
                    {menuItem.title}
                  </MenuItem>
                )
            )}
          </div>
        </div>
      </nav>

      <div className="flex-1 flex px-1 overflow-auto h-screen">
        <Outlet></Outlet>
      </div>
    </div>
  );
}

export default AdminLayout;
