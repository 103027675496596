import { AxiosError, AxiosResponse } from "axios";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { MdDelete, MdOutlineAdd, MdOutlineRemove } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import { useAuth } from "src/components/auth/AuthContext";
import Button from "src/components/form-components/Button";
import Checkbox from "src/components/form-components/Checkbox";
import FileInput from "src/components/form-components/FileInput";
import InputField from "src/components/form-components/InputField";
import SelectField from "src/components/form-components/SelectField";
import TextArea from "src/components/form-components/TextArea";
import ImageList from "src/components/image-viewer/ImageList";
import { usePopup } from "src/components/popup/ModalContext";
import { ErrorItem, ErrorMessageResponse } from "src/data/Api";
import { OrderDetailsData } from "src/data/Order";
import { ProductData, ProductReturnForm } from "src/data/Return";
import axiosInstance from "src/shared/axiosInstance";

function ProductReturn() {
  const location = useLocation();
  const { state } = location;
  const { showAlert } = useAlert();
  const { isLoggedIn } = useAuth();
  const { openPopup, closePopup } = usePopup();
  const navigate = useNavigate();

  const [mode, setMode] = useState("create");
  const [orderData, setOrderData] = useState<OrderDetailsData | null>();

  const [selectedProducts, setSelectedProducts] = useState<
    { product_id: string; name: string; quantity: number }[]
  >([]);
  const [productReturnForm, setProductReturnForm] =
    useState<Partial<ProductReturnForm>>();
  const [error, setError] = useState<ErrorItem>();

  const [productId, setProductId] = useState<string>("");
  const [productLoading, setProductLoading] = useState(false);

  useEffect(() => {
    if (state) {
      setMode("edit");
      setProductReturnForm(state.returnData);
      getorderData(state.returnData.order_id);
      setSelectedProducts(state.returnData.products);
    }
  }, []);

  function getorderData(orderId: string) {
    axiosInstance
      .get(`/order/${orderId}`)
      .then((response: AxiosResponse<OrderDetailsData>) => {
        setOrderData(response.data);
      })
      .catch(() => {
        setOrderData(null);
      });
  }

  function imageSelectHandler(files: FileList) {
    let selectedImages = Array.from(files);
    const readers = selectedImages.map((file) => {
      return new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result as string);
        };
        reader.readAsDataURL(file);
      });
    });

    Promise.all(readers).then((base64Array) => {
      setProductReturnForm((prev) => ({
        ...prev,
        images: base64Array.map((image) => ({ image_data: image })),
      }));
    });
  }

  function searchProduct() {
    setProductLoading(true);
    axiosInstance
      .get("/product/data", { params: { product_id: productId } })
      .then((response: AxiosResponse<ProductData>) => {
        setProductLoading(false);
        setSelectedProducts((prev) => [
          ...prev,
          { ...response.data, quantity: 1 },
        ]);
        setProductId("");
      })

      .catch((error: AxiosError<ErrorMessageResponse>) => {
        setProductLoading(false);
        showAlert(
          AlertType.ERROR,
          t("general.error"),
          t(`general.messages.${error.response?.data.message.product_id[0]}`)
        );
      });
  }

  function submit() {
    axiosInstance[mode === "create" ? "post" : "put"]("/product/return", {
      ...productReturnForm,
      products: selectedProducts.map((product) => ({
        product_id: product.product_id,
        quantity: product.quantity,
      })),
    })
      .then(() => {
        if (mode === "create") {
          navigate("/profile/returns");
          showAlert(
            AlertType.SUCCESS,
            t("general.messages.successful_create"),
            "Sikeresen létrehoztad a visszavét kérelmet"
          );
        }
        if (mode === "edit") {
          navigate("/profile/returns", {
            state: { highlight: state.returnData.id },
          });
          showAlert(
            AlertType.SUCCESS,
            t("general.messages.successful_update"),
            "Sikeresen módosítottad a visszavét kérelmet"
          );
        }
      })
      .catch((error: AxiosError<ErrorMessageResponse>) => {
        setError(error.response?.data.message);
      });
  }

  function handleProductChecked(state: boolean, product: ProductData) {
    const selectedItem = selectedProducts.find(
      (item) => item.product_id === product.product_id
    );
    if (selectedItem) {
      setSelectedProducts(
        selectedProducts.filter(
          (item) => item.product_id !== product.product_id
        )
      );
    } else {
      setSelectedProducts((prev) => [
        ...prev,
        { name: product.name, product_id: product.product_id, quantity: 1 },
      ]);
    }
  }

  return (
    <div className="w-full flex justify-center">
      <div className="max-w-[60%] w-[80%] max-xl:w-full max-xl:max-w-full p-2">
        <h1 className="w-full text-xl font-bold mb-2">
          {mode === "create"
            ? t("product_return.title")
            : t("product_return.edit_title")}
        </h1>
        <div className="w-full h-px bg-table-border rounded m-2"></div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-2 max-md:flex-col">
            <InputField
              className="w-full"
              required
              placeholder={t("product_return.order_id")}
              value={productReturnForm?.order_id}
              change={(value) => {
                if (isLoggedIn) {
                  getorderData(value);
                }
                setProductReturnForm((prev) => ({ ...prev, order_id: value }));
              }}
              errorKey={error?.order_id}
            />
            <SelectField
              className="w-full"
              required
              placeholder={t("product_return.type")}
              displayTranslateKey="product_return.types"
              options={["warranty", "withdrawal"]}
              value={productReturnForm?.type}
              change={(value) =>
                setProductReturnForm((prev) => ({ ...prev, type: value }))
              }
              errorKey={error?.type}
            />
          </div>
          {!isLoggedIn && (
            <div className="flex flex-col">
              <span className="font-bold">
                {t("product_return.select_return_products")}
              </span>
              <small>
                {t("product_return.select_return_products_description")}
              </small>
              {error?.products && (
                <small className="text-sm text-color-error">
                  {t(`general.messages.${error.products}`)}
                </small>
              )}
              {error?.product && (
                <small className="text-sm text-color-error">
                  {t(`general.messages.${error.product}`)}
                </small>
              )}

              <div className="flex flex-row gap-1 items-end">
                <InputField
                  className="w-fit"
                  required
                  value={productId}
                  change={(value) => setProductId(value)}
                  placeholder={t("product_return.product_id")}
                />
                <Button
                  loading={productLoading}
                  disabled={
                    selectedProducts.find(
                      (item) => item.product_id === productId
                    ) && true
                  }
                  onClick={searchProduct}
                  icon={<FaPlus />}
                ></Button>
              </div>
              {selectedProducts.length > 0 && (
                <>
                  <span>{t("product_return.selected_products")}:</span>
                  <div className="flex flex-row gap-2">
                    {selectedProducts?.map((product, index) => (
                      <div
                        key={index}
                        className="bg-bg-primary2 shadow rounded w-fit py-1 px-2 border border-table-border flex flex-col gap-2 max-w-60 items-start"
                      >
                        <div className="flex-1 flex flex-row gap-2 w-full">
                          <div className="flex flex-col flex-1">
                            <span>{product.name}</span>
                            <small className="text-sm text-text-secondary">
                              ({product.product_id})
                            </small>
                          </div>
                          <MdDelete
                            className="text-xl"
                            onClick={() =>
                              setSelectedProducts((prev) => [
                                ...prev.filter(
                                  (item) =>
                                    item.product_id !== product.product_id
                                ),
                              ])
                            }
                          />
                        </div>
                        <div className="flex flex-row gap-2 items-center self-center p-2 select-none w-full">
                          <MdOutlineRemove
                            className="text-2xl bg-bg-primary rounded shadow cursor-pointer"
                            onClick={() => {
                              setSelectedProducts((prev) => [
                                ...prev.map((item) =>
                                  item.product_id === product.product_id
                                    ? { ...item, quantity: item.quantity - 1 }
                                    : item
                                ),
                              ]);
                            }}
                          />
                          <span className="px-2">{product.quantity} darab</span>
                          <MdOutlineAdd
                            className="text-2xl bg-bg-primary rounded shadow cursor-pointer"
                            onClick={() => {
                              setSelectedProducts((prev) => [
                                ...prev.map((item) =>
                                  item.product_id === product.product_id
                                    ? { ...item, quantity: item.quantity + 1 }
                                    : item
                                ),
                              ]);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          )}

          {isLoggedIn && (
            <div className="flex flex-col">
              <span className="font-bold">
                {t("product_return.select_return_products")}
              </span>
              {error?.products && (
                <small className="text-sm text-color-error">
                  {t(`general.messages.${error.products}`)}
                </small>
              )}
              {error?.product && (
                <small className="text-sm text-color-error">
                  {t(`general.messages.${error.product}`)}
                </small>
              )}
              {!orderData && (
                <small className="">
                  {t(`product_return.select_order_id`)}
                </small>
              )}
              <div className="flex flex-col gap-1">
                {orderData?.products.map((product) => (
                  <div
                    key={product.product_id}
                    className="flex flex-row justify-between p-2 bg-bg-primary2 rounded shadow border border-table-border w-full h-fit"
                  >
                    <Checkbox
                      checked={
                        selectedProducts.find(
                          (item) => item.product_id === product.product_id
                        ) && true
                      }
                      checkCallback={(state: boolean) =>
                        handleProductChecked(state, product)
                      }
                      description={product.product_id}
                    >
                      {product.name}
                    </Checkbox>
                    {selectedProducts.find(
                      (item) => item.product_id === product.product_id
                    ) && (
                      <div className="flex flex-row gap-2 items-center p-2 select-none w-fit">
                        <MdOutlineRemove
                          className="text-2xl bg-bg-primary rounded shadow cursor-pointer"
                          onClick={() => {
                            setSelectedProducts((prev) => [
                              ...prev.map((item) =>
                                item.product_id === product.product_id
                                  ? { ...item, quantity: item.quantity - 1 }
                                  : item
                              ),
                            ]);
                          }}
                        />
                        <span className="px-2">
                          {
                            selectedProducts.find(
                              (item) => item.product_id === product.product_id
                            )?.quantity
                          }{" "}
                          darab
                        </span>
                        <MdOutlineAdd
                          className="text-2xl bg-bg-primary rounded shadow cursor-pointer"
                          onClick={() => {
                            setSelectedProducts((prev) => [
                              ...prev.map((item) =>
                                item.product_id === product.product_id
                                  ? { ...item, quantity: item.quantity + 1 }
                                  : item
                              ),
                            ]);
                          }}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
          <div>
            <span className="font-bold">{t("product_return.more_data")}</span>
            <div className="flex flex-row gap-1 w-full max-md:flex-col">
              <InputField
                className="w-full"
                required
                placeholder={t("product_return.email")}
                value={productReturnForm?.email}
                change={(value) =>
                  setProductReturnForm((prev) => ({ ...prev, email: value }))
                }
                errorKey={error?.email}
              />
              <InputField
                className="w-full"
                required
                placeholder={t("product_return.phone_number")}
                value={productReturnForm?.phone_number}
                change={(value) =>
                  setProductReturnForm((prev) => ({
                    ...prev,
                    phone_number: value,
                  }))
                }
                errorKey={error?.phone_number}
              />
            </div>
            <TextArea
              required
              placeholder={t("product_return.comment")}
              value={productReturnForm?.user_comment}
              change={(value) =>
                setProductReturnForm((prev) => ({
                  ...prev,
                  user_comment: value,
                }))
              }
              errorKey={error?.user_comment}
            ></TextArea>
          </div>
          {mode === "create" && (
            <div className="flex flex-col gap-1">
              <span className="font-bold">
                {t("product_return.upload_images")}
                <small className="text-text-secondary">
                  ({t("product_return.optional")})
                </small>
              </span>
              <FileInput
                title={t("general.select_images")}
                multi
                accept="image/*"
                change={imageSelectHandler}
              />

              {productReturnForm?.images && (
                <ImageList
                  images={productReturnForm.images.map((image) => ({
                    image_url: image.image_data,
                    id: 0,
                  }))}
                />
              )}
            </div>
          )}
        </div>
        <div className="w-full h-px bg-table-border rounded mt-5 mb-3"></div>

        <Button onClick={() => submit()}>
          {mode === "create" ? t("product_return.submit") : t("general.save")}
        </Button>
      </div>
    </div>
  );
}
export default ProductReturn;
