import { AxiosResponse } from "axios";
import { t } from "i18next";
import { useState } from "react";
import { FaEdit, FaPlus } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useQuery } from "react-query";
import ProductCategoryForm from "src/admin/pages/product-categories/ProductCategoryForm";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";

import Button from "src/components/form-components/Button";
import InputField from "src/components/form-components/InputField";
import { FullscreenLoading } from "src/components/loading/Loading";
import { usePopup } from "src/components/popup/ModalContext";
import DateCell from "src/components/table/date-cell/DateCell";
import Table, { PageData } from "src/components/table/Table";
import { DEFAULT_PAGE_SIZE } from "src/components/table/TablePaginator";
import { MessageResponse } from "src/data/Api";
import {
  CategoryParents,
  PaginatedCategoriesResponse,
  ProductCategory,
} from "src/data/Product";
import axiosInstance from "src/shared/axiosInstance";

export const getAllCategories = async (
  pageData: PageData,
  filter: { [key: string]: string }
) => {
  const response = await axiosInstance.get(`/admin/categories`, {
    params: {
      limit: pageData.limit,
      page: pageData.page,
      ...Object.fromEntries(
        Object.entries(filter).filter(([_, value]) => value !== "")
      ),
    },
  });
  return response.data;
};

export const getAllParentCategory = async () => {
  const response = await axiosInstance.get(`/admin/categories/parents`, {});
  return response.data;
};

interface ProductCategoriesProps {}

function ProductCategories({}: ProductCategoriesProps) {
  const { openPopup, closePopup } = usePopup();
  const { showAlert } = useAlert();
  const [filter, setFilter] = useState<{ name: string }>({ name: "" });
  const [pageData, setPageData] = useState<PageData>({
    limit: DEFAULT_PAGE_SIZE,
    page: 1,
  });

  const {
    data: categories,
    isLoading: categoriesLoading,
    refetch,
  } = useQuery<PaginatedCategoriesResponse>(
    ["categories", pageData],
    () => getAllCategories(pageData, filter),
    {
      keepPreviousData: true,
    }
  );

  const { data: categoryParents } = useQuery<CategoryParents[]>(
    ["categoryParents"],
    () => getAllParentCategory(),
    {
      keepPreviousData: true,
    }
  );

  const customColumns = {
    created_at: (row: ProductCategory) => <DateCell date={row.created_at} />,
    number_of_products: (row: ProductCategory) => (
      <span>{row.number_of_products} db</span>
    ),
    actions: (row: ProductCategory) => (
      <div className="flex flex-row gap-2 text-xl">
        <FaEdit
          onClick={(event) => {
            event.stopPropagation();
            openPopup(
              t("product_categories.edit_category"),
              <ProductCategoryForm
                afterCreate={handleRefetch}
                category={row}
                parents={categoryParents}
              />
            );
          }}
        />
        <MdDelete
          onClick={(event) => {
            event.stopPropagation();
            openPopup(
              t("product_categories.delete_category"),
              <div className="flex flex-col gap-1 w-full">
                <span>
                  {t("product_categories.confirm_delete", { name: row.name })}
                </span>
                <Button
                  onClick={() => {
                    axiosInstance
                      .delete("/admin/category", {
                        params: { id: row.id },
                      })
                      .then((response: AxiosResponse<MessageResponse>) => {
                        handleRefetch();
                        showAlert(
                          AlertType.INFO,
                          t("general.messages.successful_delete"),
                          t(
                            `general.messages.${response.data.message.success[0]}`
                          )
                        );
                      });
                  }}
                >
                  {t("general.delete")}
                </Button>
                <Button onClick={() => closePopup()} color="secondary">
                  {t("general.cancel")}
                </Button>
              </div>
            );
          }}
        />
      </div>
    ),
  };

  const handleRefetch = () => {
    closePopup();
    refetch();
  };

  if (categoriesLoading) {
    return <FullscreenLoading />;
  }

  return (
    <div className="w-full px-10 py-2">
      <div className="w-full mb-2 flex flex-row gap-2">
        <div className="flex flex-row justify-between w-full items-end flex-wrap gap-y-2">
          <InputField
            className="!w-fit"
            placeholder={t("product_categories.name")}
            value={filter.name}
            change={(value) => setFilter((prev) => ({ ...prev, name: value }))}
            onSubmit={() => {
              refetch();
            }}
          ></InputField>
          <Button
            className="h-fit w-fit"
            icon={<FaPlus />}
            onClick={() =>
              openPopup(
                t("product_categories.create_category"),
                <ProductCategoryForm
                  afterCreate={handleRefetch}
                  parents={categoryParents}
                />
              )
            }
          >
            {t("product_categories.create_category")}
          </Button>
        </div>
      </div>
      {categories && (
        <Table
          dataSource={categories.data}
          dataSourceSize={categories.number_of_categories}
          columns={[
            "id",
            "name",
            "number_of_products",
            "created_at",
            "actions",
          ]}
          translateKey="product_categories"
          pageData={pageData}
          changePage={(page: number, limit: number) =>
            setPageData({ page, limit })
          }
          customColumns={customColumns}
        />
      )}
    </div>
  );
}
export default ProductCategories;
