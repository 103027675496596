import { t } from "i18next";
import Button from "src/components/form-components/Button";
import { usePopup } from "src/components/popup/ModalContext";

interface ImageDeletePopupProps {
  afterConfirm: () => void;
}

function ImageDeletePopup({ afterConfirm }: ImageDeletePopupProps) {
  const { closePopup } = usePopup();

  return (
    <div className="flex flex-col gap-1">
      {t("general.delete_image_description")}
      <Button
        onClick={() => {
          afterConfirm();
          closePopup();
        }}
      >
        {t("general.delete")}
      </Button>
      <Button color="secondary" onClick={closePopup}>
        {t("general.cancel")}
      </Button>
    </div>
  );
}
export default ImageDeletePopup;
