import { ImageData } from "src/components/image-viewer/ImageList";
import { User } from "src/data/User";

export interface ProductReturnData {
  id: number;
  order_id: string;
  products: {
    product_id: string;
    name: string;
    quantity: number;
  }[];
  email: string;
  user_comment: string;
  type: string;
  phone_number: string;
  images: ImageData[];
  state: ReturnState;
}

export type ProductReturnForm = Omit<ProductReturnData, "images"> & {
  images: { image_data: string }[];
};

export interface ProductData {
  name: string;
  product_id: string;
}

export enum ReturnState {
  RECEIVED = "Received",
  CANCELED_BY_USER = "Canceled by User",
  PROCESSING = "Processing",
  COMPLETED = "Completed",
}

export interface AdminReturnData {
  id: number;
  type: string;
  order_id: string;
  phone_number: string;
  email: string;
  state: ReturnState;
  user: User;
  admin?: User;
  user_comment: string;
  admin_comment: string;
  user_images: ImageData[];
  admin_images: ImageData[];
  created_at: string;
  updated_at: string;
  products: {
    product_id: string;
    name: string;
    quantity: number;
  }[];
}

export interface AdminReturnListResponse {
  data: AdminReturnData[];
  page: number;
  limit: number;
  number_of_return_orders: number;
}

export interface AdminReturnFilters {
  state_filters: ReturnState;
  user_filters: User[];
  admin_filters: User[];
}
