import { AxiosError } from "axios";
import { t } from "i18next";
import { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { getAllParentCategory } from "src/admin/pages/product-categories/ProductCategories";
import ProductForm from "src/admin/pages/products/ProductForm";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import Button from "src/components/form-components/Button";
import InputField from "src/components/form-components/InputField";
import SelectField from "src/components/form-components/SelectField";
import { FullscreenLoading } from "src/components/loading/Loading";
import { usePopup } from "src/components/popup/ModalContext";
import DateCell from "src/components/table/date-cell/DateCell";
import OptionChangeCell from "src/components/table/option-cell/OptionChangeCell";
import Table, {
  FilterData,
  OrderData,
  PageData,
} from "src/components/table/Table";
import { DEFAULT_PAGE_SIZE } from "src/components/table/TablePaginator";
import { ErrorMessageResponse } from "src/data/Api";
import {
  AdminProductListItem,
  CategoryParents,
  PaginatedProductResponse,
  Product,
  ProductFilterResponse,
} from "src/data/Product";
import axiosInstance from "src/shared/axiosInstance";

export const getAllProducts = async (
  pageData: PageData,
  filter: FilterData,
  order?: OrderData
) => {
  const response = await axiosInstance.get(`/admin/products`, {
    params: {
      limit: pageData.limit,
      page: pageData.page,
      ...Object.fromEntries(
        Object.entries(filter).filter(([_, value]) => value !== "")
      ),
      ...order,
    },
  });
  return response.data;
};

export const getProductFilters = async () => {
  const response = await axiosInstance.get(`/admin/products/filters`);
  return response.data;
};

interface ProductsProps {}

function Products({}: ProductsProps) {
  const { openPopup } = usePopup();
  const { showAlert } = useAlert();

  const [filter, setFilter] = useState<FilterData>({});
  const [filterValue, setFilterValue] = useState<FilterData>({});
  const [orderData, setOrderData] = useState<OrderData | undefined>();
  const [pageData, setPageData] = useState<PageData>({
    limit: DEFAULT_PAGE_SIZE,
    page: 1,
  });

  const {
    data: products,
    error: productsError,
    isLoading: productsLoading,
    refetch,
  } = useQuery<PaginatedProductResponse>(
    ["products", pageData, orderData, filter],
    () => getAllProducts(pageData, filter, orderData),
    {
      keepPreviousData: true,
    }
  );

  const { data: productFilters } = useQuery<ProductFilterResponse>(
    ["productFilters"],
    () => getProductFilters(),
    {
      keepPreviousData: true,
    }
  );

  const { data: productCategories } = useQuery<CategoryParents[]>(
    ["productCategories"],
    () => getAllParentCategory(),
    {
      keepPreviousData: true,
    }
  );

  function changeState(id: string, state: string) {
    return axiosInstance
      .delete("/admin/product", { params: { product_id: id, state } })
      .then((response) => {
        showAlert(
          AlertType.SUCCESS,
          t("general.messages.successful_delete"),
          t(`general.messages.${response.data.message.success[0]}`)
        );
      })
      .catch((error: AxiosError<ErrorMessageResponse>) =>
        showAlert(
          AlertType.ERROR,
          t(`general.messages.${error.response?.data.message.state}`)
        )
      );
  }

  const customColumns = {
    state: (row: AdminProductListItem) => (
      <OptionChangeCell
        valueKey="admin_products.product_statuses"
        dataName={t("admin_products.state")}
        value={row.state}
        update={(state: string) => changeState(row.product_id, state)}
        afterChange={() => {}}
        options={["Active", "Deleted"]}
      />
    ),
    category: (row: AdminProductListItem) => <span>{row.category.name}</span>,
    created_at: (row: AdminProductListItem) => (
      <DateCell date={row.created_at} />
    ),
    updated_at: (row: AdminProductListItem) => (
      <DateCell date={row.updated_at} />
    ),
    price: (row: AdminProductListItem) => <span>{row.price} Ft</span>,
  };

  if (productsLoading) {
    return <FullscreenLoading />;
  }

  return (
    <div className="w-full px-10 py-2">
      <div className="w-full mb-2 flex flex-row gap-2">
        <div className="flex flex-row justify-between w-full items-end flex-wrap gap-y-2">
          <div className="flex flex-row gap-1 flex-wrap">
            <InputField
              className="!w-fit"
              placeholder={t("admin_products.name")}
              value={filterValue.name}
              change={(value) =>
                setFilterValue((prev) => ({ ...prev, name: value }))
              }
              onSubmit={(value) => {
                setFilter((prev) => ({ ...prev, name: value }));
              }}
            />
            {productFilters && (
              <SelectField
                placeholder={t("admin_products.state")}
                displayTranslateKey={"admin_products.product_statuses"}
                value={filter.state}
                options={[null, ...(productFilters?.states || [])]}
                change={(value) =>
                  setFilter((prev) => ({ ...prev, state: value }))
                }
              ></SelectField>
            )}
          </div>

          <Button
            className="h-fit w-fit"
            icon={<FaPlus />}
            onClick={() =>
              openPopup(
                t("admin_products.create_product"),
                <ProductForm
                  categories={productCategories || []}
                  afterSave={refetch}
                />
              )
            }
          >
            {t("admin_products.create_product")}
          </Button>
        </div>
      </div>
      {products && (
        <Table
          primaryKey="product_id"
          dataSource={products.data}
          dataSourceSize={products.number_of_products}
          columns={[
            "product_id",
            "name",
            "state",
            "price",
            "category",
            "created_at",
            "updated_at",
          ]}
          translateKey="admin_products"
          pageData={pageData}
          customColumns={customColumns}
          orderKeys={["created_at", "updated_at"]}
          order={orderData}
          orderChange={(order: OrderData | undefined) => {
            setOrderData(order);
          }}
          rowClick={(row: Product) => {
            openPopup(
              t("admin_products.product_details"),
              <ProductForm
                categories={productCategories || []}
                afterSave={refetch}
                product={row}
                mode="read"
              ></ProductForm>
            );
          }}
          changePage={(page: number, limit: number) =>
            setPageData({ page, limit })
          }
        />
      )}
    </div>
  );
}
export default Products;
