import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { FaAngleDown } from "react-icons/fa";

export interface SelectOptionType {
  id: number;
  name: string;
}

interface SelectFieldProps {
  value?: any;
  placeholder?: string;
  change?: (e: any) => void;
  error?: string[];
  errorKey?: string[];
  required?: boolean;
  options: any[];
  displayKey?: string;
  displayTranslateKey?: string;
  multi?: boolean;
  disabled?: boolean;
  className?: string;
}

function SelectField({
  value,
  placeholder,
  change,
  error,
  errorKey,
  required = false,
  options,
  displayKey,
  displayTranslateKey,
  multi = false,
  disabled = false,
  className,
}: SelectFieldProps) {
  const [dropdown, setDropdown] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0, width: 0 });
  const [dropdownFilter, setDropdownFilter] = useState<string>("");
  const parentRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const filterInputRef = useRef<HTMLInputElement>(null);

  const [selectedOptions, setSelectedOptions] = useState(value);

  function handleBackdropClick(event: any) {
    if (
      parentRef.current &&
      dropdownRef.current &&
      !parentRef.current.contains(event.target) &&
      !dropdownRef.current.contains(event.target)
    ) {
      setDropdown(false);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleBackdropClick);
    return () => {
      document.removeEventListener("mousedown", handleBackdropClick);
    };
  }, []);

  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  useEffect(() => {
    if (parentRef.current && dropdown) {
      const rect = parentRef.current.getBoundingClientRect();
      setPosition({
        top: rect.bottom,
        left: rect.left,
        width: rect.width,
      });
    }
  }, [dropdown, parentRef]);

  useEffect(() => {
    if (!multi) {
      if (!dropdown && change && value !== selectedOptions) {
        change(selectedOptions || "");
      }
      if (!dropdown) {
        setDropdownFilter(getOptionLabel(selectedOptions));
      }
    } else {
      if (!dropdown) {
        setDropdownFilter(getSelectedValue() || "");
        if (change) {
          change(selectedOptions || "");
        }
      }
    }
  }, [dropdown]);

  function handleSelectChange(option: any) {
    if (option === null) {
      setSelectedOptions(null);
      return;
    }
    const selectedIndex =
      selectedOptions &&
      selectedOptions.findIndex(
        (selected: any) => JSON.stringify(selected) === JSON.stringify(option)
      );
    if (selectedIndex > -1) {
      const newItems = [...selectedOptions];
      newItems.splice(selectedIndex, 1);
      setSelectedOptions(newItems);
    } else {
      if (!selectedIndex) {
        setSelectedOptions([option]);
      } else {
        setSelectedOptions((prev: any) => [...prev, option]);
      }
    }
  }

  function getSelectedValue() {
    if (!selectedOptions) return "";
    if (multi) {
      return selectedOptions
        .map((option: any) => (displayKey ? option[displayKey] : option))
        .join(", ");
    }
    if (!multi) {
      return displayKey ? selectedOptions[displayKey] : selectedOptions;
    }
  }

  useEffect(() => {
    if (!multi) {
      setDropdownFilter(getOptionLabel(selectedOptions));
    }
    if (!multi) {
      setDropdown(false);
    }
    if (multi && !dropdown) {
      setDropdownFilter(getSelectedValue() || "");
    }
  }, [selectedOptions]);

  function getOptionLabel(option: any) {
    if (!option) return "";
    return t(
      `${displayTranslateKey ? displayTranslateKey + "." : ""}${
        displayKey ? option[displayKey] : option
      }`
    );
  }

  function getFilteredOptions() {
    if (!dropdownFilter) return options;

    return [
      ...options.filter((item) =>
        getOptionLabel(item)
          .toLowerCase()
          .includes(dropdownFilter.toLowerCase())
      ),
    ];
  }

  return (
    <div className={`min-w-60 ${className}`}>
      <span className="text-sm text-text-secondary">
        {placeholder} {required && <sup className="text-color-primary">*</sup>}
      </span>
      <div
        ref={parentRef}
        className={`bg-bg-primary rounded-3 shadow text-white px-2 py-1 border-[1px] flex items-center min-h-9 ${
          error || errorKey
            ? "border-color-error animate-[input-error_0.2s_linear]"
            : "border-text-secondary"
        } ${disabled ? "bg-text-disabled" : ""}`}
      >
        <input
          ref={filterInputRef}
          type="text"
          className={`w-full bg-transparent outline-none flex-grow ${
            getSelectedValue() ? "" : "text-text-secondary"
          }`}
          onFocus={() => {
            setDropdownFilter("");
            setDropdown(true);
          }}
          value={
            !dropdownFilter && !dropdown
              ? t("general.select_one")
              : dropdownFilter
          }
          onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
            setDropdownFilter(e.target.value)
          }
          disabled={disabled}
        />
        {dropdown &&
          ReactDOM.createPortal(
            <div
              ref={dropdownRef}
              className="bg-bg-primary absolute z-[200] border-table-border border rounded shadow w-full max-h-44 overflow-auto"
              style={{
                top: `${position.top}px`,
                left: `${position.left}px`,
                width: `${position.width}px`,
              }}
            >
              {getFilteredOptions().map((option, index) => (
                <label
                  key={index}
                  className="flex flex-row gap-2 w-full hover:bg-bg-primary2 p-2 cursor-pointer"
                  onClick={(event) => {
                    event.stopPropagation();
                    if (change) {
                      if (!multi) {
                        setSelectedOptions(option);
                      }
                    }
                  }}
                  htmlFor={"option" + index}
                >
                  {multi && (
                    <input
                      type="checkbox"
                      id={"option" + index}
                      name="horns"
                      checked={
                        selectedOptions &&
                        selectedOptions.find(
                          (item: any) =>
                            JSON.stringify(item) === JSON.stringify(option)
                        )
                          ? true
                          : false
                      }
                      onChange={() => handleSelectChange(option)}
                    />
                  )}
                  <label
                    htmlFor={"option" + index}
                    className={
                      getOptionLabel(option) ? "" : "text-text-secondary"
                    }
                  >
                    {getOptionLabel(option) || t("general.select_one")}
                  </label>
                </label>
              ))}
            </div>,
            document.body
          )}
        <FaAngleDown className="text-lg" />
      </div>
      {error && <span className="text-color-error text-sm">{error}</span>}
      <div className="flex flex-col">
        {errorKey &&
          errorKey.map((errorItem, index) => (
            <span key={index} className="text-color-error text-sm">
              {t(`general.messages.${errorItem}`)}
            </span>
          ))}
      </div>
    </div>
  );
}

export default SelectField;
