import { AxiosError } from "axios";
import { t } from "i18next";
import { useState } from "react";
import Button from "src/components/form-components/Button";
import InputField from "src/components/form-components/InputField";
import { ErrorItem, ErrorMessageResponse } from "src/data/Api";
import axiosInstance from "src/shared/axiosInstance";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState<ErrorItem>();
  const [emailSent, setEmailSent] = useState(false);

  function submitForgotPassword() {
    setError(undefined);
    axiosInstance
      .get("/password", { params: { email } })
      .then(() => {
        setEmailSent(true);
      })
      .catch((error: AxiosError<ErrorMessageResponse>) => {
        setError(error.response?.data.message);
      });
  }

  return (
    <div className="w-full">
      {emailSent ? (
        <div>
          <span className="font-semibold text-green-600">
            {t("login.forgot_password_sent")}
          </span>
        </div>
      ) : (
        <div className="flex flex-col gap-1">
          <span>{t("login.forgot_password_description")}</span>
          <InputField
            value={email}
            change={(value) => setEmail(value)}
            placeholder={t("login.email")}
            errorKey={error?.email || error?.account}
          ></InputField>
          <Button onClick={() => submitForgotPassword()}>
            {t("login.send_passrod_reset")}
          </Button>
        </div>
      )}
    </div>
  );
}

export default ForgotPassword;
