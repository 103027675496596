import { t } from "i18next";
import { useState } from "react";
import { useQuery } from "react-query";
import AdminProductReturnDetails from "src/admin/pages/product-return/AdminProductReturnDetails";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import InputField from "src/components/form-components/InputField";
import SelectField from "src/components/form-components/SelectField";
import { FullscreenLoading } from "src/components/loading/Loading";
import DateCell from "src/components/table/date-cell/DateCell";
import OptionChangeCell from "src/components/table/option-cell/OptionChangeCell";
import Table, { OrderData, PageData } from "src/components/table/Table";
import { DEFAULT_PAGE_SIZE } from "src/components/table/TablePaginator";
import {
  AdminReturnData,
  AdminReturnFilters,
  AdminReturnListResponse,
  ReturnState,
} from "src/data/Return";
import { User } from "src/data/User";
import axiosInstance from "src/shared/axiosInstance";

const getAllReturn = async (
  pageData: PageData,
  filter: { [key: string]: string },
  order?: OrderData
) => {
  const response = await axiosInstance.get(`/admin/return/products`, {
    params: {
      limit: pageData.limit,
      page: pageData.page,
      ...Object.fromEntries(
        Object.entries(filter).filter(([_, value]) => value !== "")
      ),
      ...order,
    },
  });
  return response.data;
};

const getProductReturnFilters = async () => {
  const response = await axiosInstance.get(`/admin/return/products/filters`);
  return response.data;
};

interface AdminProductReturnProps {}

function AdminProductReturn({}: AdminProductReturnProps) {
  const { showAlert } = useAlert();
  const [filter, setFilter] = useState<{ [key: string]: string }>({
    user_id: "",
  });
  const [orderData, setOrderData] = useState<OrderData | undefined>();
  const [pageData, setPageData] = useState<PageData>({
    limit: DEFAULT_PAGE_SIZE,
    page: 1,
  });

  const {
    data: productReturns,
    isLoading: productReturnsLoading,
    refetch,
  } = useQuery<AdminReturnListResponse>(
    ["productReturns", pageData, orderData],
    () => getAllReturn(pageData, filter, orderData),
    {
      keepPreviousData: true,
    }
  );

  const { data: productReturnFilters } = useQuery<AdminReturnFilters>(
    ["productReturnFilters"],
    () => getProductReturnFilters(),
    {
      keepPreviousData: true,
    }
  );

  const customColumns = {
    user: (row: AdminReturnData) => (
      <div className="flex flex-col">
        <span>{row.user?.full_name}</span>
        <span>{row.user?.email}</span>
        <span>{row.user?.phone_number}</span>
      </div>
    ),
    admin: (row: AdminReturnData) => {
      return row.admin ? (
        <span>{row.admin?.full_name}</span>
      ) : (
        <span className="text-text-secondary">
          {t("product_return.no_admin")}
        </span>
      );
    },
    state: (row: AdminReturnData) => (
      <OptionChangeCell
        dataName={t("admin_users.user_state")}
        valueKey="product_return.statuses"
        value={row.state}
        update={(state: ReturnState) => {
          return axiosInstance.put("/admin/return/product", {
            id: row.id,
            state,
          });
        }}
        afterChange={(state: ReturnState) => {
          showAlert(
            AlertType.SUCCESS,
            t("general.messages.successful_update"),
            t("admin_users.messages.status_update_successful")
          );
          row.state = state;
        }}
        options={Object.values(ReturnState)}
      />
    ),
    created_at: (row: AdminReturnData) => <DateCell date={row.created_at} />,
    updated_at: (row: AdminReturnData) => <DateCell date={row.updated_at} />,
    type: (row: AdminReturnData) => (
      <span>{t(`product_return.types.${row.type}`)}</span>
    ),
  };

  if (productReturnsLoading) {
    return <FullscreenLoading />;
  }

  return (
    <div className="w-full px-10 py-2">
      <div className="w-full mb-2 flex flex-row gap-2">
        <div className="flex flex-row justify-between w-full items-end">
          {productReturnFilters && (
            <div className="flex flex-row gap-1 flex-wrap">
              <SelectField
                value={productReturnFilters.user_filters.find(
                  (user) => user.id + "" === filter.user_id
                )}
                placeholder={t("product_return.user")}
                displayKey="full_name"
                change={(value: User) => {
                  setFilter((prev) => ({ ...prev, user_id: value.id + "" }));
                  refetch();
                }}
                options={productReturnFilters.user_filters}
              />
              <SelectField
                value={productReturnFilters.user_filters.find(
                  (user) => user.id + "" === filter.admin_id
                )}
                placeholder={t("product_return.admin")}
                displayKey="full_name"
                change={(value: User) => {
                  setFilter((prev) => ({ ...prev, admin_id: value.id + "" }));
                  refetch();
                }}
                options={productReturnFilters.admin_filters}
              />
              <InputField
                className="!w-fit"
                value={filter.email}
                change={(value: string) =>
                  setFilter({ ...filter, email: value + "" })
                }
                onSubmit={() => {
                  refetch();
                }}
                placeholder={t("product_return.email")}
              ></InputField>
              <InputField
                className="!w-fit"
                value={filter.phone_number}
                change={(value: string) =>
                  setFilter({ ...filter, phone_number: value + "" })
                }
                onSubmit={() => {
                  refetch();
                }}
                placeholder={t("product_return.phone_number")}
              ></InputField>
            </div>
          )}
        </div>
      </div>
      {productReturns && (
        <Table
          dataSource={productReturns.data}
          dataSourceSize={productReturns.number_of_return_orders}
          columns={[
            "type",
            "order_id",
            "state",
            "user",
            "admin",
            "created_at",
            "updated_at",
          ]}
          translateKey="product_return"
          pageData={pageData}
          changePage={(page: number, limit: number) =>
            setPageData({ page, limit })
          }
          customColumns={customColumns}
          DetailComponent={AdminProductReturnDetails}
          orderKeys={["created_at", "updated_at"]}
          order={orderData}
          orderChange={(order: OrderData | undefined) => {
            setOrderData(order);
          }}
        />
      )}
    </div>
  );
}
export default AdminProductReturn;
