import i18next from "i18next";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { AlertProvider } from "src/components/alert/AlertProvider";
import { AuthProvider } from "src/components/auth/AuthContext";
import { CartProvider } from "src/components/cart/CartProvider";
import { ImageViewerProvider } from "src/components/image-viewer/ImageViewerContext";
import { PopupProvider } from "src/components/popup/ModalContext";
import App from "./App";
import hu from "./assets/translations/hu.json";
import "./index.css";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "hu",
  defaultNS: "translation",
  resources: {
    hu: { translation: hu },
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <AlertProvider>
        <PopupProvider>
          <ImageViewerProvider>
            <CartProvider>
              <I18nextProvider i18n={i18next}>
                <App />
              </I18nextProvider>
            </CartProvider>
          </ImageViewerProvider>
        </PopupProvider>
      </AlertProvider>
    </AuthProvider>
  </QueryClientProvider>
);
