import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import { ImageData } from "src/components/image-viewer/ImageList";
import ImageViewer from "src/components/image-viewer/ImageViewer";

interface ImageViewerContextType {
  openImageViewer: (
    images: ImageData[],
    index: number,
    deleteCallback?: (imageId: number) => Promise<any>
  ) => void;
  closeImageViewer: () => void;
}

const ImageViewerContext = createContext<ImageViewerContextType | undefined>(
  undefined
);

export const ImageViewerProvider = (props: { children: ReactNode }) => {
  const [viewer, setViewer] = useState<ReactNode | string>(false);
  const [images, setImages] = useState<ImageData[]>([]);
  const [index, setIndex] = useState<number>(0);
  const [callback, setCallBack] = useState<any>();

  const openImageViewer = useCallback(
    (
      images: ImageData[],
      index: number = 0,
      deleteCallback?: (imageId: number) => Promise<any>
    ) => {
      setViewer(true);
      setImages(images);
      setIndex(index);
      setCallBack(() => deleteCallback);
    },
    [setViewer]
  );

  const closeImageViewer = () => {
    setViewer(false);
  };

  return (
    <ImageViewerContext.Provider value={{ openImageViewer, closeImageViewer }}>
      {props.children}
      {viewer && (
        <ImageViewer
          images={images}
          index={index}
          closeImageViewer={closeImageViewer}
          deleteCallback={callback}
        />
      )}
    </ImageViewerContext.Provider>
  );
};

export const useImageViewer = () => {
  const context = useContext(ImageViewerContext);

  if (context === undefined) {
    throw new Error("useImageViewer must be used within a PopupProvider");
  }

  return context;
};
