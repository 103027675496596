import { t } from "i18next";
import { useState } from "react";
import Button from "src/components/form-components/Button";
import SelectField from "src/components/form-components/SelectField";
import { usePopup } from "src/components/popup/ModalContext";

interface OptionChangePopupProps {
  value: any;
  options: any[];
  update: (value: any) => Promise<any>;
  afterChange: (value: any) => void;
  dataName: string;
  valueKey: string;
}

function OptionChangePopup({
  value,
  options,
  update,
  afterChange,
  dataName,
  valueKey,
}: OptionChangePopupProps) {
  const [selectedStatus, setSelectedStatus] = useState(value);
  const { closePopup } = usePopup();

  function handleUpdate() {
    update(selectedStatus)
      .then(() => {
        afterChange(selectedStatus);
        closePopup();
      })
      .catch(() => {});
  }

  return (
    <div className="flex flex-col gap-1">
      <span>
        <span>
          {t("table.option_change_popup_description", { name: dataName })}
        </span>
      </span>
      <SelectField
        options={options}
        value={selectedStatus}
        displayTranslateKey={valueKey}
        placeholder={dataName}
        change={(e) => setSelectedStatus(e)}
      />
      <Button
        onClick={() => handleUpdate()}
        disabled={selectedStatus === value}
      >
        {t("general.save")}
      </Button>
    </div>
  );
}
export default OptionChangePopup;
