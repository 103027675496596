export const checkForFullname = (obj: any) => {
  if (obj && typeof obj === "object") {
    if (obj.first_name && obj.last_name) {
      obj.full_name = `${obj.last_name} ${obj.first_name}`;
    }
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === "object") {
          checkForFullname(obj[key]);
        }
      }
    }
  }
};
