import { ReactNode } from "react";
import Loading from "src/components/loading/Loading";

export interface ButtonProps {
  children?: ReactNode;
  onClick?: (e: any) => void;
  type?: "button" | "submit" | "reset";
  icon?: ReactNode;
  className?: string;
  badge?: number;
  loading?: boolean;
  color?: string;
  disabled?: boolean;
  outline?: boolean;
}

const buttonColors: {
  [key: string]: { bg: string; hover: string; text: string };
} = {
  primary: {
    bg: "color-primary",
    hover: "color-primary-hover",
    text: "color-on-primary",
  },
  secondary: {
    bg: "bg-secondary",
    hover: "bg-secondary-hover",
    text: "color-on-bg-secondary",
  },
};

function Button({
  children,
  type = "button",
  onClick,
  icon,
  className = "",
  loading = false,
  badge,
  color = "primary",
  disabled = false,
  outline = false,
}: ButtonProps) {
  return (
    <button
      className={`${className} relative rounded-3 shadow py-1 font-bold flex flex-row gap-2 items-center justify-center px-4 transition-colors duration-150 min-h-9 ${
        disabled
          ? "bg-text-disabled text-text-secondary cursor-not-allowed"
          : `bg-${buttonColors[color].bg} hover:bg-${buttonColors[color].hover} text-${buttonColors[color].text}`
      } ${
        outline
          ? `bg-transparent border border-${buttonColors[color].bg} hover:bg-transparent text-${buttonColors[color].bg} !text-${buttonColors[color].bg}`
          : ``
      }`}
      type={type}
      disabled={loading}
      onClick={(e) => !disabled && onClick && onClick(e)}
    >
      {badge !== undefined && (
        <small className="absolute w-4 h-4 rounded-full shadow -top-1 -right-1 bg-red-600 text-xs font-medium z-10">
          {badge}
        </small>
      )}
      {loading && (
        <div className="absolute">
          <Loading></Loading>
        </div>
      )}
      <div
        className={`flex flex-row gap-2 items-center ${loading && "opacity-0"}`}
      >
        {icon}
        {children}
      </div>
    </button>
  );
}

export default Button;
