import {
  FaBox,
  FaBoxOpen,
  FaCheck,
  FaClock,
  FaHourglass,
  FaTruck,
} from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { TbLoader } from "react-icons/tb";
import { OrderStatus, PaymentStatus, ProductStatus } from "src/data/Order";

export const orderStatusColors = {
  [OrderStatus.PENDING]: "warn",
  [OrderStatus.PROCESSING]: "warn",
  [OrderStatus.SHIPPING]: "info",
  [OrderStatus.COMPLETED]: "success",
  [OrderStatus.CANCELLED]: "error",
  [OrderStatus.REJECTED]: "error",
};

export const orderStatusIcons = {
  [OrderStatus.PENDING]: FaHourglass,
  [OrderStatus.PROCESSING]: TbLoader,
  [OrderStatus.SHIPPING]: FaTruck,
  [OrderStatus.COMPLETED]: FaCheck,
  [OrderStatus.CANCELLED]: MdCancel,
  [OrderStatus.REJECTED]: MdCancel,
};

export const paymentStatusColors = {
  [PaymentStatus.PAID]: "success",
  [PaymentStatus.PENDING]: "warn",
  [PaymentStatus.REJECTED]: "error",
};

export const paymentStatusIcons = {
  [PaymentStatus.PAID]: FaCheck,
  [PaymentStatus.PENDING]: FaClock,
  [PaymentStatus.REJECTED]: MdCancel,
};

export const productStatusColors = {
  [ProductStatus.PENDING]: "warn",
  [ProductStatus.ORDERED]: "info",
  [ProductStatus.RECEIVED]: "info",
  [ProductStatus.UNPACKED]: "info",
  [ProductStatus.PACKED]: "success",
};

export const productStatusIcons = {
  [ProductStatus.PENDING]: FaHourglass,
  [ProductStatus.ORDERED]: FaTruck,
  [ProductStatus.RECEIVED]: FaBox,
  [ProductStatus.UNPACKED]: FaBoxOpen,
  [ProductStatus.PACKED]: FaBox,
};
