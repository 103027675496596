import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { FaShoppingCart } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useCart } from "src/components/cart/CartProvider";
import SmallCartListItem from "src/components/cart/SmallCartListItem";
import Button from "src/components/form-components/Button";

function CartList() {
  const { cart } = useCart();
  const navigate = useNavigate();

  const [cartVisible, setCartVisible] = useState(false);
  const cartDropdownRef = useRef<HTMLDivElement>(null);

  function toggleCartList() {
    setCartVisible(!cartVisible);
  }

  function handleBackdropClick(event: any) {
    if (
      cartDropdownRef.current &&
      !cartDropdownRef.current.contains(event.target)
    ) {
      setCartVisible(false);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleBackdropClick);
    return () => {
      document.removeEventListener("mousedown", handleBackdropClick);
    };
  }, []);

  return (
    <div className="relative z-10 text-base">
      <div className="relative" onClick={() => toggleCartList()}>
        {cart.length > 0 && (
          <span className="absolute min-w-4 w-fit h-4 bg-color-primary rounded-full shadow -top-2 -right-3 text-xs font-semibold flex items-center justify-center py-2 px-1">
            {cart.reduce((acc, item) => acc + item.quantity, 0)}
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-color-primary opacity-55"></span>
          </span>
        )}
        <FaShoppingCart className="text-xl" />
      </div>

      {cartVisible && (
        <div
          ref={cartDropdownRef}
          className="rounded-sm absolute max-w-64 w-64 bg-bg-secondary top-10 shadow p-2 left-1/2 -translate-x-[90%] flex items-center flex-col max-h-96"
        >
          <div className="absolute left-[90%] -top-[4px] w-0 h-0 -ml-[5px] border-l-[5px] border-r-[5px] border-b-[10px] border-bg-secondary rotate-45" />
          <span>{t("order.cart_content")}</span>
          <div className="w-full h-px bg-text-secondary my-2"></div>
          {cart.length === 0 && (
            <span className="text-text-secondary">{t("order.cart_empty")}</span>
          )}
          <div className="overflow-auto overflow-x-hidden flex flex-col gap-2 p-1 w-full">
            {cart.length > 0 &&
              cart.map((cartItem, index) => (
                <SmallCartListItem
                  cartItem={cartItem}
                  key={index}
                ></SmallCartListItem>
              ))}
          </div>
          {cart.length > 0 && (
            <Button
              className="mt-1 w-full"
              onClick={() => {
                navigate("/checkout");
                setCartVisible(false);
              }}
            >
              {t("order.to_checkout")}
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

export default CartList;
