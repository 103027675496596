import { AxiosError } from "axios";
import { t } from "i18next";
import { useEffect, useState } from "react";
import Button from "src/components/form-components/Button";
import Checkbox from "src/components/form-components/Checkbox";
import InputField from "src/components/form-components/InputField";
import SelectField from "src/components/form-components/SelectField";
import { usePopup } from "src/components/popup/ModalContext";
import { ErrorItem, ErrorMessageResponse } from "src/data/Api";
import {
  Discount,
  DiscountProductFilter,
  DiscountUserFilter,
  DiscountUserGroupFilter,
} from "src/data/Discount";
import axiosInstance from "src/shared/axiosInstance";

interface DiscountFormProps {
  afterSubmit: () => void;
  discountData?: Discount;
  userFilterData: DiscountUserFilter[];
  userGroupFilterData: DiscountUserGroupFilter[];
  productFilterData: DiscountProductFilter[];
}

function DiscountForm({
  afterSubmit,
  discountData,
  userFilterData,
  userGroupFilterData,
  productFilterData,
}: DiscountFormProps) {
  const { closePopup } = usePopup();

  const [discount, setDiscount] = useState<string>();
  const [assignId, setAssignId] = useState<number | string>();
  const [discountType, setDiscountType] = useState<
    "user" | "user_group" | "product"
  >("user_group");

  const [productFilter, setProductFilter] =
    useState<DiscountProductFilter[]>(productFilterData);
  const [userFilter, setUserFilter] =
    useState<DiscountUserFilter[]>(userFilterData);
  const [userGroupFilter, setUserGroupFilter] =
    useState<DiscountUserGroupFilter[]>(userGroupFilterData);
  const [error, setError] = useState<ErrorItem>();

  useEffect(() => {
    if (discountData) {
      if (discountData.user) {
        setDiscountType("user");
        setAssignId(discountData.user.id);
      }
      if (discountData.product) {
        setDiscountType("product");
        setAssignId(discountData.product.product_id);
      }
      if (discountData.user_group) {
        setDiscountType("user_group");
        setAssignId(discountData.user_group.id);
      }
      setDiscount(discountData.discount);
    }
  }, [discountData]);

  function submit() {
    const formData: { [key: string]: any } = {
      discount,
    };
    if (discountType === "user_group") {
      formData.user_group_id = assignId;
    }
    if (discountType === "user") {
      formData.user_id = assignId;
    }
    if (discountType === "product") {
      formData.product_id = assignId;
    }

    if (discountData) {
      formData.id = discountData.id;
    }

    axiosInstance[discountData ? "put" : "post"]("/admin/discount", {
      ...formData,
    })
      .then(() => {
        closePopup();
        afterSubmit();
      })
      .catch((error: AxiosError<ErrorMessageResponse>) => {
        setError(error.response?.data.message);
      });
  }

  return (
    <div className="flex flex-col gap-1">
      <div>
        <span className="text-text-secondary">{t("discounts.assign_to")}:</span>
        <div>
          <Checkbox
            disabled={discountData !== undefined}
            checked={discountType === "user_group"}
            checkCallback={() => {
              setDiscountType("user_group");
              setAssignId(undefined);
            }}
          >
            {t("discounts.assign_to_user_group")}
          </Checkbox>
          <Checkbox
            disabled={discountData !== undefined}
            checked={discountType === "user"}
            checkCallback={() => {
              setDiscountType("user");
              setAssignId(undefined);
            }}
          >
            {t("discounts.assign_to_user")}
          </Checkbox>
          <Checkbox
            disabled={discountData !== undefined}
            checked={discountType === "product"}
            checkCallback={() => {
              setDiscountType("product");
              setAssignId(undefined);
            }}
          >
            {t("discounts.assign_to_product")}
          </Checkbox>
        </div>
      </div>
      {discountType === "user_group" && (
        <SelectField
          disabled={discountData !== undefined}
          placeholder={t("discounts.select_user_group")}
          displayKey="name"
          options={userGroupFilter}
          value={userGroupFilter.find((item) => item.id === assignId)}
          change={(value: DiscountUserGroupFilter) => setAssignId(value.id)}
          errorKey={error?.user_group_id}
        />
      )}
      {discountType === "user" && (
        <SelectField
          disabled={discountData !== undefined}
          displayKey="full_name"
          placeholder={t("discounts.select_user")}
          options={userFilter}
          value={userFilter.find((item) => item.id === assignId)}
          change={(value: DiscountUserFilter) => setAssignId(value.id)}
          errorKey={error?.user_id}
        />
      )}
      {discountType === "product" && (
        <SelectField
          disabled={discountData !== undefined}
          displayKey="name"
          placeholder={t("discounts.select_product")}
          options={productFilter}
          value={productFilter.find((item) => item.product_id === assignId)}
          change={(value: DiscountProductFilter) =>
            setAssignId(value.product_id)
          }
          errorKey={error?.product_id}
        />
      )}
      <InputField
        value={discount}
        change={(value) => setDiscount(value)}
        placeholder={t("discounts.discount")}
        errorKey={error?.discount}
        required
      />
      {error?.request && (
        <span className="text-color-error text-sm">
          {t(`general.messages.${error.request}`)}
        </span>
      )}
      <Button onClick={submit}>
        {discountData ? t("general.save") : t("general.create")}
      </Button>
      <Button color="secondary" onClick={closePopup}>
        {t("general.cancel")}
      </Button>
    </div>
  );
}
export default DiscountForm;
