import { t } from "i18next";
import { useState } from "react";
import { useAlert } from "src/components/alert/AlertProvider";
import Button from "src/components/form-components/Button";
import FileInput from "src/components/form-components/FileInput";
import { usePopup } from "src/components/popup/ModalContext";

interface ImageUploadPopupProps {
  imagesSelected?: (images: string[]) => void;
}

function ImageUploadPopup({ imagesSelected }: ImageUploadPopupProps) {
  const [base64Images, setBase64Images] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();
  const { closePopup } = usePopup();

  function uploadSelectedImages() {
    if (imagesSelected) {
      setLoading(true);
      imagesSelected(base64Images);
    }
  }

  function imageSelectHandler(files: FileList) {
    let selectedImages = Array.from(files);
    const readers = selectedImages.map((file) => {
      return new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result as string);
        };
        reader.readAsDataURL(file);
      });
    });

    Promise.all(readers).then((base64Array) => {
      setBase64Images(base64Array);
    });
  }

  return (
    <div className="flex flex-col gap-1">
      <div>{t("general.select_images")}</div>
      <FileInput
        title={t("general.select_images")}
        multi
        accept="image/*"
        change={imageSelectHandler}
      />
      <Button loading={loading} onClick={uploadSelectedImages}>
        {t("general.upload")}
      </Button>
    </div>
  );
}
export default ImageUploadPopup;
