import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCart } from "src/components/cart/CartProvider";
import Loading from "src/components/loading/Loading";
import { OrderDetailsData } from "src/data/Order";
import OrderDetails from "src/pages/checkout/OrderDetails";

function OrderOverview() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { clearCart } = useCart();

  const [orderOwerviewData, setOrderOwerviewData] =
    useState<OrderDetailsData>();

  useEffect(() => {
    if (!state.order_data) navigate("/");
    clearCart();
    setOrderOwerviewData(state.order_data);
  }, []);

  return orderOwerviewData ? (
    <OrderDetails orderData={orderOwerviewData} overview />
  ) : (
    <Loading></Loading>
  );
}

export default OrderOverview;
