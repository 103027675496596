import { t } from "i18next";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { ProductReturnData } from "src/data/Return";
import ReturnItem from "src/pages/profile/returns/ReturnItem";
import axiosInstance from "src/shared/axiosInstance";

export const getReturns = async () => {
  const response = await axiosInstance.get(`/product/return`);
  return response.data;
};

interface ReturnListProps {}

function ReturnList({}: ReturnListProps) {
  const location = useLocation();
  const { state } = location;

  const {
    data: returns,
    isLoading: returnsLoading,
    refetch,
  } = useQuery<ProductReturnData[]>(["returns"], () => getReturns(), {
    keepPreviousData: true,
  });

  return (
    <div className="w-full rounded">
      <h1 className="text-lg font-bold mb-2">
        {t("product_return.return_list_title")}
      </h1>
      <div className="flex flex-col gap-3">
        {returns?.length === 0 && (
          <span className="text-text-secondary">
            {t("product_return.no_returns")}
          </span>
        )}
        {returns &&
          returns.map((returnData) => (
            <ReturnItem
              key={returnData.id}
              highlight={(state && state.highlight) || undefined}
              returnData={returnData}
            />
          ))}
      </div>
    </div>
  );
}
export default ReturnList;
