import { format, formatDistanceToNow } from "date-fns";
import { hu } from "date-fns/locale";
import { useState } from "react";

interface DateCellProps {
  date: string;
  type?: "date" | "time" | "datetime";
}

const DEFAULT_DATE_FORMAT = "yyyy.MM.dd";
const DEFAULT_TIME_FORMAT = "HH:mm:ss";

function DateCell({ date, type = "datetime" }: DateCellProps) {
  const [relative, setRelative] = useState(false);

  if (!date) {
    return <span>N/A</span>;
  }

  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
        setRelative(!relative);
      }}
      className="text-sm"
    >
      {relative ? (
        <span>
          {formatDistanceToNow(new Date(date), {
            locale: hu,
            addSuffix: true,
          })}
        </span>
      ) : (
        <div className="flex flex-row gap-2">
          <span>{format(new Date(date), DEFAULT_DATE_FORMAT)}</span>
          <span>{format(new Date(date), DEFAULT_TIME_FORMAT)}</span>
        </div>
      )}
    </div>
  );
}
export default DateCell;
