import { t } from "i18next";
import { useEffect, useState } from "react";
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import SelectField from "src/components/form-components/SelectField";

export const DEFAULT_PAGE_SIZE = 10;
const pageSizeOptions = [5, 10, 20, 25, 50, 100, 200];

interface TablePaginatorProps {
  length: number;
  itemsPerPage?: number;
  onPaginate: (page: number) => void;
  onPageSizeChange: (size: number) => void;
  page?: number;
}

function TablePaginator({
  length,
  itemsPerPage = DEFAULT_PAGE_SIZE,
  page = 1,
  onPaginate,
  onPageSizeChange,
}: TablePaginatorProps) {
  const [totalPages, setTotalPages] = useState(
    Math.ceil(length / itemsPerPage)
  );

  useEffect(() => {
    setTotalPages(Math.ceil(length / itemsPerPage));
  }, [itemsPerPage]);

  function changePage(page: number) {
    onPaginate(page);
  }

  function changePageSize(size: number) {
    onPageSizeChange(size);
  }

  function getPageArray() {
    if (totalPages < 5) {
      return [...Array(totalPages)].map((_, i) => i + 1);
    }
    if (page < 4) {
      return [1, 2, 3, 4, 5];
    }
    if (page >= totalPages - 2) {
      return [
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      ];
    }
    return [page - 2, page - 1, page, page + 1, page + 2];
  }

  function getDropdownPagesStart() {
    if (page > 4) {
      let end = page - 3;
      let start = 2;
      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    }
    return [];
  }

  function getDropdownPagesEnd() {
    if (totalPages > 6) {
      if (page <= 3) {
        let end = totalPages - 1;
        let start = 6;
        return Array.from({ length: end - start + 1 }, (_, i) => start + i);
      } else {
        let end = totalPages - 1;
        let start = page + 3;
        return Array.from({ length: end - start + 1 }, (_, i) => start + i);
      }
    }
    return [];
  }

  function dropdownSelected(index: number) {
    changePage(index);
  }

  return (
    <div className="flex items-center flex-row justify-end gap-x-5 mt-2 h-full bg-bg-primary2 w-fit float-right rounded shadow border-table-border border overflow-hidden py-2 px-3 flex-wrap">
      <div className="h-full flex flex-row gap-2 items-center text-sm text-text-secondary">
        {t("table.all_item_count")}: {length}
      </div>
      <div className="h-full flex flex-row gap-2 items-center text-sm text-text-secondary">
        {t("table.items_per_page")}:
        <SelectField
          className="w-20 !min-w-20"
          value={itemsPerPage}
          options={pageSizeOptions}
          change={(size) => changePageSize(size)}
        ></SelectField>
      </div>
      <div className="select-none w-fit h-full flex flex-row text-2xl cursor-pointer items-center title-font bg-bg-primary2 p-2 gap-2">
        <div
          onClick={() => page > 1 && changePage(1)}
          className={`${page === 1 ? "text-text-disabled" : ""}`}
        >
          <MdKeyboardDoubleArrowLeft />
        </div>
        <div
          onClick={() => page > 1 && changePage(page - 1)}
          className={`${page === 1 ? "text-text-disabled" : ""}`}
        >
          <MdKeyboardArrowLeft />
        </div>
        {page > 3 && totalPages > 5 && (
          <>
            <div
              className="text-lg px-2 font-bold"
              onClick={() => changePage(1)}
            >
              1
            </div>
            {page > 4 && totalPages > 6 && (
              <div className="text-lg px-2 font-bold relative">
                <span className="pointer-events-none">...</span>
                <select
                  className="absolute appearance-none top-0 left-0 w-full opacity-0"
                  onChange={(e) => {
                    dropdownSelected(+e.target.value);
                  }}
                >
                  {getDropdownPagesStart().map((item, index) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </>
        )}
        {getPageArray().map(
          (item, index) =>
            item > 0 && (
              <div
                key={index}
                onClick={() => changePage(item)}
                className={`text-lg px-2 font-bold ${
                  page === item
                    ? "text-color-on-primary bg-color-primary rounded shadow"
                    : ""
                }`}
              >
                {item}
              </div>
            )
        )}
        {totalPages > 6 && page < totalPages - 2 && (
          <>
            {page < totalPages - 3 && (
              <div className="text-lg px-2 font-bold relative">
                <span className="pointer-events-none">...</span>
                <select
                  className="absolute appearance-none top-0 left-0 w-full opacity-0"
                  onChange={(e) => {
                    dropdownSelected(+e.target.value);
                  }}
                >
                  {getDropdownPagesEnd().map((item, index) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div
              className="text-lg px-2 font-bold"
              onClick={() => changePage(totalPages)}
            >
              {totalPages}
            </div>
          </>
        )}
        <div
          onClick={() => page < totalPages && changePage(page + 1)}
          className={`${page === totalPages ? "text-text-disabled" : ""}`}
        >
          <MdKeyboardArrowRight />
        </div>
        <div
          onClick={() => page < totalPages && changePage(totalPages)}
          className={`${page === totalPages ? "text-text-disabled" : ""}`}
        >
          <MdKeyboardDoubleArrowRight />
        </div>
      </div>
    </div>
  );
}
export default TablePaginator;
