import { AxiosResponse } from "axios";
import { format } from "date-fns";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loading from "src/components/loading/Loading";
import { OrderDetailsData } from "src/data/Order";
import axiosInstance from "src/shared/axiosInstance";

interface OrderDetailsProps {
  orderData?: OrderDetailsData;
  overview?: boolean;
}

function OrderDetails({ orderData, overview }: OrderDetailsProps) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [order, setOrder] = useState<OrderDetailsData>();

  useEffect(() => {
    if (overview && orderData) {
      setOrder(orderData);
    } else {
      const orderId = searchParams.get("order");
      if (!orderId) {
        navigate("/");
        return;
      }
      axiosInstance
        .get<OrderDetailsData>(`/order/${orderId}`)
        .then((response: AxiosResponse<OrderDetailsData>) => {
          setOrder(response.data);
        });
    }
  }, []);

  if (!order) {
    return <Loading></Loading>;
  }

  return (
    <div className="w-full flex justify-center p-10">
      <div className="max-w-[80%] flex flex-col w-full gap-5 max-lg:max-w-full max-lg:w-full">
        {overview && (
          <div className="w-full flex flex-col text-center">
            <h1 className="text-5xl font-extrabold m-5">{t("order.thanks")}</h1>
            <span>{t("order.order_process_message")}</span>
          </div>
        )}
        {!overview && (
          <h1 className="text-5xl flex flex-row gap-2 text-text-secondary items-center font-thin">
            {t("order.order_details")} ({order.id})
          </h1>
        )}
        {!overview && (
          <>
            <div className="flex flex-row gap-2">
              <span className="text-text-secondary">
                {t("order.order_status")}
              </span>
              <strong className="flex flex-row gap-2 items-center">
                {t(`order.order_statuses.${order.order_status}`)}
              </strong>
            </div>
            <div className="flex flex-row gap-2">
              <span className="text-text-secondary">
                {t("order.payment_status")}
              </span>
              <strong className="flex flex-row gap-2 items-center">TODO</strong>
            </div>
          </>
        )}
        <div className="w-full bg-bg-primary2 rounded shadow p-2 flex flex-col gap-1">
          <div className="flex flex-row gap-2">
            <span className="text-text-secondary">
              {t("order.created_at")}:
            </span>
            <strong>
              {format(new Date(order.created_at), "yyyy. MM. dd HH:mm:ss")}
            </strong>
          </div>
          <div className="flex flex-row gap-2">
            <span className="text-text-secondary">
              {t("order.total_price")}:
            </span>
            <strong>{order.total_price.toLocaleString("hu-HU")} Ft</strong>
          </div>
          <div className="flex flex-row gap-2">
            <span className="text-text-secondary">
              {t("order.package_weight")}:
            </span>
            <strong>{order.total_weight.toLocaleString("hu-HU")} g</strong>
          </div>
        </div>
        <div className="flex flex-row gap-5 flex-wrap max-md:flex-col">
          <div className="flex flex-1 bg-bg-primary2 shadow rounded p-2 flex-col gap-2">
            <h1 className="text-lg font-bold">{t("order.shipping_data")}</h1>
            <div className="flex flex-col">
              <span className="text-text-secondary">
                {t("order.shipment_type")}
              </span>
              <strong>{order.shipment_type.name}</strong>
            </div>
            <div className="flex flex-col">
              <span className="text-text-secondary">
                {t("order.shipment_address")}
              </span>
              <strong>
                {`${order.shipment_address_zip_code} ${order.shipment_address_city}, ${order.shipment_address_street_address}, ${order.shipment_address_floor_number}. emelet, ${order.shipment_address_door_number}. ajtó`}
              </strong>
            </div>
            <div className="flex flex-col">
              <span className="text-text-secondary">
                {t("order.shipment_price")}
              </span>
              <strong>
                {order.shipment_type.price.toLocaleString("hu-HU")} Ft
              </strong>
            </div>
          </div>
          <div className="flex flex-1 bg-bg-primary2 shadow rounded p-2 flex-col gap-2">
            <h1 className="text-lg font-bold">{t("order.billing_data")}</h1>
            <div className="flex flex-col">
              <span className="text-text-secondary">
                {t("order.contact_data")}
              </span>
              <strong>{`${order.billing_last_name} ${order.billing_first_name}, ${order.phone_number}`}</strong>
            </div>
            <div className="flex flex-col">
              <span className="text-text-secondary">
                {t("order.billing_address")}
              </span>
              <strong>
                {`${order.billing_address_zip_code} ${order.billing_address_city}, ${order.billing_address_street_address}, ${order.billing_address_floor_number}. emelet, ${order.billing_address_door_number}. ajtó`}
              </strong>
            </div>
          </div>
          <div className="flex flex-1 bg-bg-primary2 shadow rounded p-2 flex-col gap-2">
            <h1 className="text-lg font-bold">{t("order.payment_data")}</h1>
            <div className="flex flex-col">
              <span className="text-text-secondary">
                {t("order.payment_type")}
              </span>
              <strong>{order.payment_type.name}</strong>
            </div>
            <div className="flex flex-col">
              <span className="text-text-secondary">
                {t("order.payment_price")}
              </span>
              <strong>{order.payment_type.price} Ft</strong>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col gap-2">
          <h1 className="text-lg font-bold">{t("order.products")}</h1>
          {order.products.map((product, index) => (
            <div key={index} className="bg-bg-primary2 shadow rounded p-3">
              <div className="flex flex-row gap-2 items-center max-md:items-start">
                <div className="rounded shadow min-w-32 min-h-32">
                  <img src="https://placehold.co/120x120" alt="" />
                </div>
                <div>
                  <h1 className="text-lg font-bold">{product.name}</h1>
                  <span className="font-thin">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Iure excepturi, ducimus voluptatem eaque ut aperiam, numquam
                    deleniti, similique voluptate cumque ipsum?
                  </span>
                  <div className="flex flex-row gap-2">
                    <span className="text-text-secondary">
                      {t("order.quantity")}:
                    </span>
                    <strong>
                      {product.quantity} {t("order.piece")}
                    </strong>
                  </div>
                  <div className="flex flex-row gap-2">
                    <span className="text-text-secondary">
                      {t("order.price")}:
                    </span>
                    <strong>{product.original_price} Ft</strong>
                  </div>
                  <div className="flex flex-row gap-2">
                    <span className="text-text-secondary">
                      {t("order.weight")}:
                    </span>
                    <strong>{product.weight} g</strong>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OrderDetails;
