import { AxiosError } from "axios";
import { format } from "date-fns";
import { t } from "i18next";
import { useEffect, useState } from "react";
import Button from "src/components/form-components/Button";
import InputField from "src/components/form-components/InputField";
import SelectField from "src/components/form-components/SelectField";
import { usePopup } from "src/components/popup/ModalContext";
import { ErrorItem, ErrorMessageResponse } from "src/data/Api";
import { ProductFilter, Promotion } from "src/data/Promotion";
import axiosInstance from "src/shared/axiosInstance";

interface PromotionFormProps {
  productData: ProductFilter[];
  afterSubmit: () => void;
  promotionData?: Promotion;
}

function PromotionForm({
  productData,
  afterSubmit,
  promotionData,
}: PromotionFormProps) {
  const { closePopup } = usePopup();

  const [promotionForm, setPromotionForm] = useState<Partial<Promotion>>();
  const [error, setError] = useState<ErrorItem>();

  useEffect(() => {
    if (promotionData) {
      setPromotionForm(promotionData);
    }
  }, [promotionData]);

  function submit() {
    const promotionFormData: { [key: string]: any } = {
      start_date:
        promotionForm?.start_date &&
        format(new Date(promotionForm?.start_date), "yyyy-MM-dd HH:mm:ss"),
      end_date:
        promotionForm?.end_date &&
        format(new Date(promotionForm.end_date), "yyyy-MM-dd HH:mm:ss"),
      product_id: promotionForm?.product?.product_id,
      amount: promotionForm?.amount,
    };

    if (promotionData) {
      promotionFormData.id = promotionData.id;
    }

    axiosInstance[promotionData ? "put" : "post"](
      "/admin/promotion",
      promotionFormData
    )
      .then(() => {
        afterSubmit();
      })
      .catch((error: AxiosError<ErrorMessageResponse>) => {
        setError(error.response?.data.message);
      });
  }

  return (
    <div className="flex flex-col gap-1">
      <SelectField
        disabled={productData !== undefined}
        placeholder={t("promotions.product")}
        options={productData}
        displayKey="name"
        value={promotionForm?.product}
        change={(value) =>
          setPromotionForm((prev) => ({ ...prev, product: value }))
        }
        errorKey={error?.product_id}
      />
      <InputField
        type="datetime-local"
        placeholder={t("promotions.start_date")}
        value={promotionForm?.start_date}
        change={(value) =>
          setPromotionForm((prev) => ({ ...prev, start_date: value }))
        }
        errorKey={error?.start_date}
      />
      <InputField
        type="datetime-local"
        placeholder={t("promotions.end_date")}
        value={promotionForm?.end_date}
        change={(value) =>
          setPromotionForm((prev) => ({ ...prev, end_date: value }))
        }
        errorKey={error?.end_date}
      />
      <InputField
        type="number"
        placeholder={t("promotions.amount")}
        value={promotionForm?.amount}
        change={(value) =>
          setPromotionForm((prev) => ({ ...prev, amount: value }))
        }
        errorKey={error?.amount}
      />
      <Button onClick={submit}>{t("general.save")}</Button>
      <Button color="secondary" onClick={closePopup}>
        {t("general.cancel")}
      </Button>
    </div>
  );
}
export default PromotionForm;
