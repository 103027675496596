import { t } from "i18next";
import { NavLink } from "react-router-dom";
import { Discount } from "src/data/Discount";

interface DiscountDetailsProps {
  detailData: Discount;
}

function DiscountDetails({ detailData }: DiscountDetailsProps) {
  return (
    <div>
      {detailData.user && (
        <div className="flex flex-col gap-1">
          <span className="text-text-secondary">
            {t("discounts.assigned_to_user")}:
          </span>
          <span>
            {detailData.user.full_name} ({detailData.user.id})
          </span>
          <span>{detailData.user.email}</span>
        </div>
      )}
      {detailData.product && (
        <div className="flex flex-col gap-1">
          <span className="text-text-secondary">
            {t("discounts.assigned_to_product")}:
          </span>
          <NavLink
            to={`/product/${detailData.product.product_id}`}
            className="underline cursor-pointer"
          >
            {detailData.product.name} ({detailData.product.product_id})
          </NavLink>
        </div>
      )}
      {detailData.user_group && (
        <div className="flex flex-col gap-1">
          <span className="text-text-secondary">
            {t("discounts.assigned_to_user_group")}:
          </span>
          <span>
            {detailData.user_group.name} ({detailData.user_group.id})
          </span>
        </div>
      )}
    </div>
  );
}
export default DiscountDetails;
