import { AxiosError } from "axios";
import { t } from "i18next";
import { useState } from "react";
import Button from "src/components/form-components/Button";
import InputField from "src/components/form-components/InputField";
import { ErrorMessageResponse } from "src/data/Api";

interface PermissionLevelEditPopupProps {
  value: number;
  change: (level: number) => Promise<any>;
  afterSave: (level: number) => void;
}

function PermissionLevelEditPopup({
  value,
  change,
  afterSave,
}: PermissionLevelEditPopupProps) {
  const [currentLevel, setCurrentLevel] = useState(value);
  const [error, setError] = useState<{ [key: string]: string[] }>();

  function handleChange() {
    change(currentLevel)
      .then(() => {
        afterSave(currentLevel);
      })
      .catch((error: AxiosError<ErrorMessageResponse>) =>
        setError(error.response?.data?.message)
      );
  }

  return (
    <div className="flex flex-col gap-1 w-full">
      <span>{t("admin_users.permission_level_description")}</span>
      <InputField
        placeholder={t("admin_users.permission_level")}
        type="number"
        value={currentLevel}
        change={(e) => setCurrentLevel(e)}
        errorKey={error?.permission_level}
      ></InputField>
      <Button onClick={() => handleChange()}>{t("general.save")}</Button>
    </div>
  );
}
export default PermissionLevelEditPopup;
