function Settings() {
  return (
    <div className="w-full">
      <div className="p-2 flex flex-col">
        <h1 className="text-lg font-semibold">Settings kategória 1</h1>
        <div className="my-2">settings content</div>
        <div className="w-full px-10 bg-table-border h-px"></div>
      </div>
      <div className="p-2 flex flex-col">
        <h1 className="text-lg font-semibold">Settings kategória 2</h1>
        <div className="my-2">settings content</div>
        <div className="w-full px-10 bg-table-border h-px"></div>
      </div>
    </div>
  );
}
export default Settings;
