import { Navigate, Route, Routes } from "react-router-dom";
import AdminLayout from "src/admin/layout/AdminLayout";
import Discounts from "src/admin/pages/discounts/Discounts";
import Orders from "src/admin/pages/orders/Orders";
import ProductCategories from "src/admin/pages/product-categories/ProductCategories";
import AdminProductReturn from "src/admin/pages/product-return/AdminProductReturn";
import Products from "src/admin/pages/products/Products";
import Promotions from "src/admin/pages/promotions/Promotions";
import Reviews from "src/admin/pages/reviews/Reviews";
import Settings from "src/admin/pages/settings/Settings";
import UserGroups from "src/admin/pages/user-groups/UserGroups";
import Users from "src/admin/pages/users/Users";
import { useAuth } from "src/components/auth/AuthContext";
import { PermissionTag } from "src/components/auth/Permissions";

function AdminPage() {
  const { hasPermission } = useAuth();

  return (
    <Routes>
      <Route path="" element={<AdminLayout />}>
        <Route
          path="orders"
          element={
            hasPermission(PermissionTag.ADMIN_ORDERS_PANEL) ? (
              <Orders />
            ) : (
              <Navigate to={"/admin"} />
            )
          }
        ></Route>
        <Route path="discounts" element={<Discounts />}></Route>
        <Route path="settings" element={<Settings />}></Route>
        <Route path="users" element={<Users />}></Route>
        <Route path="products" element={<Products />}></Route>
        <Route path="productCategories" element={<ProductCategories />}></Route>
        <Route path="productReturns" element={<AdminProductReturn />}></Route>
        <Route path="userGroups" element={<UserGroups />}></Route>
        <Route path="discounts" element={<Discounts />}></Route>
        <Route path="promotions" element={<Promotions />}></Route>
        <Route path="reviews" element={<Reviews />}></Route>
        <Route path="*" element={<Navigate to={""} />}></Route>
      </Route>
    </Routes>
  );
}

export default AdminPage;
