import { AxiosError } from "axios";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import Button from "src/components/form-components/Button";
import InputField from "src/components/form-components/InputField";
import { usePopup } from "src/components/popup/ModalContext";
import { ShippingData } from "src/data/Profile";
import axiosInstance from "src/shared/axiosInstance";

interface ShippingDataFormProps {
  newData?: boolean;
  currentShippingData?: ShippingData;
  shippingData?: ShippingData[];
  saveCallback?: (newData: ShippingData) => void;
}

function ShippingDataForm({
  newData,
  currentShippingData,
  shippingData,
  saveCallback,
}: ShippingDataFormProps) {
  const { closePopup } = usePopup();
  const { showAlert } = useAlert();
  const [shippingDataForm, setShippingDataForm] = useState<ShippingData>();
  const [shippingDataFormErrors, setShippingDataFormErrors] = useState<{
    [key: string]: string[];
  }>();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (currentShippingData) {
      setShippingDataForm(currentShippingData);
    }
  }, [currentShippingData]);

  function save() {
    if (newData) {
      setError(false);
      axiosInstance
        .put("/profile", {
          shipping_addresses: [
            ...(shippingData || []),
            {
              ...shippingDataForm,
              primary_shipping_address: shippingData?.length === 0,
            },
          ],
        })
        .then(() => {
          showAlert(
            AlertType.SUCCESS,
            t("general.messages.successful_create"),
            t("profile.messages.new_shipment_address_created")
          );
          if (saveCallback && shippingDataForm) {
            saveCallback({
              ...shippingDataForm,
              primary_shipping_address: false,
            });
          }
          closePopup();
        })
        .catch(() => {
          setError(true);
        });
    } else {
      axiosInstance
        .put("/shipping-address", shippingDataForm)
        .then(() => {
          showAlert(
            AlertType.SUCCESS,
            t("general.messages.successful_update"),
            t("profile.messages.new_shipment_address_updated")
          );
          if (saveCallback && shippingDataForm) {
            saveCallback({ ...shippingDataForm });
          }
          closePopup();
        })
        .catch((error: AxiosError<any>) => {
          setShippingDataFormErrors(error.response?.data.message);
        });
    }
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row gap-2 max-lg:flex-col">
        <InputField
          className="max-lg:w-full"
          placeholder={t("address.zip_code")}
          value={shippingDataForm?.zip_code}
          errorKey={shippingDataFormErrors?.zip_code}
          change={(value) =>
            setShippingDataForm((prev: any) => ({
              ...prev,
              zip_code: value,
            }))
          }
        ></InputField>
        <InputField
          className="max-lg:w-full"
          placeholder={t("address.city")}
          value={shippingDataForm?.city}
          errorKey={shippingDataFormErrors?.city}
          change={(value) =>
            setShippingDataForm((prev: any) => ({
              ...prev,
              city: value,
            }))
          }
        ></InputField>
      </div>
      <InputField
        className="w-full"
        placeholder={t("address.street_address")}
        value={shippingDataForm?.street_address}
        errorKey={shippingDataFormErrors?.street_address}
        change={(value) =>
          setShippingDataForm((prev: any) => ({
            ...prev,
            street_address: value,
          }))
        }
      ></InputField>
      <div className="flex flex-row gap-2 max-lg:flex-col">
        <InputField
          className="max-lg:w-full"
          placeholder={t("address.floor_number")}
          value={shippingDataForm?.floor_number}
          errorKey={shippingDataFormErrors?.floor_number}
          change={(value) =>
            setShippingDataForm((prev: any) => ({
              ...prev,
              floor_number: value,
            }))
          }
        ></InputField>
        <InputField
          className="max-lg:w-full"
          placeholder={t("address.door_number")}
          value={shippingDataForm?.door_number}
          errorKey={shippingDataFormErrors?.door_number}
          change={(value) =>
            setShippingDataForm((prev: any) => ({
              ...prev,
              door_number: value,
            }))
          }
        ></InputField>
      </div>

      {error && (
        <span className="text-sm text-color-error">
          {t("general.messages.general_data_error")}
        </span>
      )}
      <Button onClick={save}>{t("general.save")}</Button>
    </div>
  );
}
export default ShippingDataForm;
