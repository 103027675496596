import { t } from "i18next";
import { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { MdDelete, MdOutlineAdd } from "react-icons/md";
import { usePopup } from "src/components/popup/ModalContext";
import GarageForm from "src/pages/profile/garage/GarageForm";

interface GarageProps {}

function Garage({}: GarageProps) {
  const { openPopup } = usePopup();
  const [savedCars, setSavedCars] = useState([]);

  function openGarageForm() {
    openPopup(t("garage.add_new"), <GarageForm />);
  }

  return (
    <div className="w-full h-fit pb-1 flex flex-col gap-2">
      <h1 className="text-lg font-bold">{t("garage.title")}</h1>
      <small className="flex flex-row items-center">
        {t("garage.description")}
      </small>
      <div
        className="bg-bg-primary2 p-2 w-full self-center h-fit flex justify-center items-center gap-2 rounded shadow text-text-secondary cursor-pointer hover:scale-105 transition-all duration-100"
        onClick={openGarageForm}
      >
        <MdOutlineAdd className="text-lg" />
        {t("garage.add_new")}
      </div>
      {/* {loading && (
        <div className="w-full flex justify-center p-2">
          <Loading />
        </div>
      )} */}
      {savedCars.map((car, index) => (
        <div
          key={index}
          className="flex w-full bg-bg-primary2 shadow rounded px-2 py-1 items-center"
        >
          <div className="flex flex-col gap-1 flex-1">
            <span>a</span>
            <span>b</span>
          </div>
          <div className="flex flex-row gap-2 text-2xl items-center pe-2 text-text-secondary">
            <div
              className="hover:text-color-primary p-1 cursor-pointer"
              onClick={() => {}}
            >
              <FaEdit />
            </div>
            <div
              className="hover:text-color-primary p-1 cursor-pointer"
              onClick={() => {}}
            >
              <MdDelete />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
export default Garage;
