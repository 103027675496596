import { t } from "i18next";
import { MdDelete } from "react-icons/md";
import ImageDeletePopup from "src/components/image-viewer/ImageDeletePopup";
import { useImageViewer } from "src/components/image-viewer/ImageViewerContext";
import { usePopup } from "src/components/popup/ModalContext";

export interface ImageData {
  id: number;
  image_url: string;
}

interface ImageListProps {
  images: ImageData[];
  deleteCallback?: (imageId: number) => Promise<any>;
}

function ImageList({ images, deleteCallback }: ImageListProps) {
  const { openImageViewer, closeImageViewer } = useImageViewer();
  const { openPopup } = usePopup();

  return (
    <div className="flex flex-row gap-2 flex-wrap w-full ">
      {images.length === 0 && (
        <span className="text-text-secondary">{t("general.no_images")}</span>
      )}
      {images.map((image, index) => (
        <div
          key={index}
          className={`group w-auto h-28 max-w-52 overflow-hidden rounded shadow hover:scale-[150%] transition-transform duration-100 hover:z-10 hover:shadow-2xl`}
          onClick={() => {
            openImageViewer(images, index, deleteCallback);
          }}
        >
          {deleteCallback && (
            <div
              className={`absolute right-1 top-1 text-color-on-primary shadow-lg bg-bg-secondary bg-opacity-60 p-[1px] rounded text-sm hover:scale-105 cursor-pointer hidden group-hover:flex`}
              onClick={(e) => {
                e.stopPropagation();
                openPopup(
                  t("general.image_delete_title"),
                  <ImageDeletePopup
                    afterConfirm={() => {
                      deleteCallback(image.id);
                    }}
                  />
                );
              }}
            >
              <MdDelete />
            </div>
          )}
          <img
            src={image.image_url}
            className="h-full w-full object-cover flex-grow"
            alt=""
          />
        </div>
      ))}
    </div>
  );
}
export default ImageList;
