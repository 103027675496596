import { AxiosResponse } from "axios";
import { t } from "i18next";
import { useEffect } from "react";
import { FaEdit, FaImage } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { NavLink, useNavigate } from "react-router-dom";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import Button from "src/components/form-components/Button";
import ImageList from "src/components/image-viewer/ImageList";
import ImageUploadPopup from "src/components/image-viewer/ImageUploadPopup";
import { usePopup } from "src/components/popup/ModalContext";
import { MessageResponse } from "src/data/Api";
import { ProductReturnData, ReturnState } from "src/data/Return";
import axiosInstance from "src/shared/axiosInstance";

interface ReturnItemProps {
  returnData: ProductReturnData;
  highlight?: number;
}

function ReturnItem({ returnData, highlight }: ReturnItemProps) {
  const { openPopup, closePopup } = usePopup();
  const { showAlert } = useAlert();
  const navigate = useNavigate();

  function openEditPopup() {
    navigate("/return", {
      state: {
        edit: true,
        returnData: returnData,
      },
    });
  }

  useEffect(() => {
    if (highlight) {
      const element = document.getElementById(`item-${highlight}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        setTimeout(() => {
          element.classList.add("animate-[item-highlight_0.5s_ease-in-out]");
        }, 300);
      }
    }
  }, [highlight]);

  function openImageSelectPopup() {
    openPopup(
      t("general.attach_image"),
      <ImageUploadPopup
        imagesSelected={(images: string[]) => {
          handleImageUpload(images);
        }}
      />
    );
  }

  function handleImageUpload(images: string[]) {
    axiosInstance
      .put("/product/return", { ...returnData, images })
      .then((response: AxiosResponse<MessageResponse>) => {
        returnData.images = [
          ...returnData.images,
          ...images.map((image) => ({ id: 0, image_url: image })),
        ];
        showAlert(
          AlertType.SUCCESS,
          t("general.messages.successful_update"),
          t(`general.messages.${response.data.message.success[0]}`)
        );
        closePopup();
      });
  }

  function openReturnCancelPopup() {
    openPopup(
      t("product_return.return_cancel"),
      <div className="flex flex-col gap-1">
        <span>{t("product_return.return_cancel_confirm")}</span>
        <Button
          onClick={() => {
            axiosInstance
              .delete("/product/return", {
                params: { id: returnData.id },
              })
              .then(() => {
                showAlert(
                  AlertType.SUCCESS,
                  t("product_return.successful_cancel")
                );
                closePopup();
                returnData.state = ReturnState.CANCELED_BY_USER;
              });
          }}
        >
          {t("general.yes")}
        </Button>
        <Button color="secondary" onClick={closePopup}>
          {t("general.cancel")}
        </Button>
      </div>
    );
  }

  return (
    <div
      id={"item-" + returnData.id}
      className={"rounded shadow bg-bg-primary2 w-full p-2 flex flex-col gap-2"}
    >
      <div className="flex flex-row gap-2">
        <span className="text-text-secondary">
          {t("product_return.return_status")}:
        </span>
        <span className="font-bold">
          {t(`product_return.statuses.${returnData.state}`)}
        </span>
      </div>
      <div className="flex flex-row gap-2">
        <span className="text-text-secondary">{t("product_return.type")}:</span>
        <span className="font-bold">
          {t(`product_return.types.${returnData.type}`)}
        </span>
      </div>
      <div className="flex flex-row gap-2">
        <span className="text-text-secondary">
          {t("product_return.order_id")}:
        </span>
        <NavLink to={`/orderDetails?order=${returnData.order_id}`}>
          <span className="font-bold underline hover:text-color-primary">
            {returnData.order_id}
          </span>
        </NavLink>
      </div>
      <div className="flex flex-row gap-2">
        <span className="text-text-secondary">
          {t("product_return.contact")}:
        </span>
        <span className="font-bold">
          {returnData.email}, {returnData.phone_number}
        </span>
      </div>
      <div className="flex flex-row gap-2">
        <span className="text-text-secondary">
          {t("product_return.comment")}:
        </span>
        <span className="font-bold">{returnData.user_comment}</span>
      </div>
      <div className="flex flex-col">
        <span className="text-text-secondary">
          {t("product_return.products")}
        </span>
        <div className="flex flex-row gap-1 flex-wrap p-2">
          {returnData.products.map((product) => (
            <div
              key={product.product_id}
              className="p-2 rounded-sm border bg-bg-primary border-table-border shadow w-fit flex flex-col flex-grow"
            >
              <NavLink to={`/product/${product.product_id}`}>
                <div className="flex gap-1">
                  <span className="font-bold underline hover:text-color-primary">
                    {product.name}
                  </span>
                  <span className="text-sm text-text-secondary">
                    ({product.product_id})
                  </span>
                </div>
              </NavLink>
              <span>
                {product.quantity} {t("product_return.quantity_suffix")}
              </span>
            </div>
          ))}
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-text-secondary">
            {t("product_return.attached_images")}:
          </span>
          <ImageList images={returnData.images} />
        </div>
      </div>
      <div className="h-px bg-table-border w-full px-2"></div>
      <div className="flex flex-row gap-2 flex-wrap">
        {returnData.state === ReturnState.RECEIVED && (
          <>
            <Button onClick={openEditPopup} icon={<FaEdit />}>
              {t("product_return.edit_data")}
            </Button>
            <Button onClick={openImageSelectPopup} icon={<FaImage />}>
              {t("product_return.attach_images")}
            </Button>
          </>
        )}
        {returnData.state === ReturnState.RECEIVED && (
          <Button
            icon={<MdDelete className="text-lg" />}
            onClick={openReturnCancelPopup}
          >
            {t("product_return.return_cancel")}
          </Button>
        )}
      </div>
    </div>
  );
}
export default ReturnItem;
