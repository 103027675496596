import { FaCheck, FaExclamation, FaInfo } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import { Alert, AlertType } from "src/components/alert/AlertProvider";

interface AlertItemProps {
  alert: Alert;
}

function AlertItem({ alert }: AlertItemProps) {
  return (
    <div
      className={`relative h-fit bg-table-border text-text-primary rounded px-6 py-2 shadow text-white flex flex-row gap-6 w-fit text-sm transition-transform duration-300 ease-in-out ${
        alert.show ? "translate-x-0" : "translate-x-[110%]"
      }`}
    >
      <div className="flex items-center justify-center">
        {alert.type == AlertType.INFO && <FaInfo />}
        {alert.type == AlertType.SUCCESS && <FaCheck />}
        {alert.type == AlertType.WARNING && <FaExclamation />}
        {alert.type == AlertType.ERROR && <FaXmark />}
      </div>
      <div className="flex flex-col gap-1">
        <span className="font-semibold">{alert.title}</span>
        {alert.description && (
          <span className="font-thin">{alert.description}</span>
        )}
      </div>
    </div>
  );
}
export default AlertItem;
