export enum PermissionTag {
  ADMIN_LOGIN_PANEL = "ADMIN_LOGIN_PANEL",
  ADMIN_ORDERS_PANEL = "ADMIN_ORDERS_PANEL",
  ADMIN_USERS_PANEL = "ADMIN_USERS_PANEL",
  ADMIN_PRODUCT_CATEGORY_PANEL = "ADMIN_PRODUCT_CATEGORY_PANEL",
  ADMIN_PRODUCTS_PANEL = "ADMIN_PRODUCTS_PANEL",
  ADMIN_RETURN_PRODUCT_PANEL = "ADMIN_RETURN_PRODUCT_PANEL",
  ADMIN_USER_GROUPS_PANEL = "ADMIN_USER_GROUPS_PANEL",
  ADMIN_DISCOUNTS_PANEL = "ADMIN_DISCOUNTS_PANEL",
  ADMIN_PROMOTIONS_PANEL = "ADMIN_PROMOTIONS_PANEL",
}
