import { t } from "i18next";
import Button from "src/components/form-components/Button";
import SelectField from "src/components/form-components/SelectField";
import { usePopup } from "src/components/popup/ModalContext";

interface GarageFormProps {}

function GarageForm({}: GarageFormProps) {
  const { closePopup } = usePopup();

  return (
    <div className="flex flex-col gap-1">
      <SelectField
        options={["Audi", "Volkswagen"]}
        placeholder={t("garage.brand")}
      />
      <SelectField
        options={["Golf I", "Golf II", "Golf III", "Passat"]}
        placeholder={t("garage.model")}
      />
      <Button>{t("general.create")}</Button>
      <Button color="secondary" onClick={closePopup}>
        {t("general.cancel")}
      </Button>
    </div>
  );
}
export default GarageForm;
