import { MdDelete } from "react-icons/md";
import { useCart } from "src/components/cart/CartProvider";
import { CartItem } from "src/data/Cart";

interface CartListItemProps {
  cartItem: CartItem;
}

function CartSmallListItem({ cartItem }: CartListItemProps) {
  const { removeFromCart } = useCart();

  return (
    <div className="w-full rounded-sm border-[1px] border-text-secondary p-2 flex flex-row gap-1 items-center">
      <div className="flex-1">
        <div className="flex flex-row gap-2">
          <span className="font-semibold flex-1">
            {cartItem.name}
            <strong className="text-color-primary ms-2">
              (x{cartItem.quantity})
            </strong>
          </span>
        </div>
        <span className="text-xs">{cartItem.unit_price} Ft</span>
      </div>
      <div
        className="text-xl"
        onClick={() => removeFromCart(cartItem.product_id)}
      >
        <MdDelete />
      </div>
    </div>
  );
}

export default CartSmallListItem;
