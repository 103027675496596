import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import AlertItem from "src/components/alert/AlertItem";

export enum AlertType {
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  ERROR = "ERROR",
  INFO = "INFO",
}

export interface Alert {
  title: string;
  description?: string;
  type: AlertType;
  time?: number;
  show: boolean;
}

const MAX_ALERT_COUNT = 5;

interface AlertContextType {
  showAlert: (type: AlertType, title: string, description?: string) => void;
}

const AlertContext = createContext<AlertContextType | undefined>(undefined);

export const AlertProvider = (props: { children: ReactNode }) => {
  const [alerts, setAlerts] = useState<Alert[]>([]);

  const showAlert = useCallback(
    (type: AlertType, title: string, description?: string) => {
      const alertTime = new Date().valueOf();
      setAlerts((prev) => {
        const updatedAlerts = [
          ...prev,
          { type, title, description, time: alertTime, show: false },
        ];
        if (updatedAlerts.length > MAX_ALERT_COUNT) {
          updatedAlerts.shift();
        }
        return updatedAlerts;
      });

      setTimeout(() => {
        setAlerts((prev) =>
          prev.map((alert) =>
            alert.time === alertTime ? { ...alert, show: true } : alert
          )
        );
      }, 100);
      setTimeout(() => {
        setAlerts((prev) =>
          prev.map((alert) =>
            alert.time === alertTime ? { ...alert, show: false } : alert
          )
        );
        setTimeout(() => {
          setAlerts((prev) => prev.filter((alert) => alert.time !== alertTime));
        }, 1000);
      }, 3000);
    },
    [setAlerts]
  );

  return (
    <AlertContext.Provider value={{ showAlert }}>
      {props.children}
      <div className="fixed right-2 bottom-2 flex flex-col gap-1 items-end z-[210]">
        {alerts.map((alert) => (
          <AlertItem key={alert.time} alert={alert} />
        ))}
      </div>
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  const context = useContext(AlertContext);

  if (context === undefined) {
    throw new Error("useAlert must be used within a PopupProvider");
  }

  return context;
};
