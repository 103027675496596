import { AxiosError, AxiosResponse } from "axios";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { AlertType, useAlert } from "src/components/alert/AlertProvider";
import Button from "src/components/form-components/Button";
import InputField from "src/components/form-components/InputField";
import { MessageResponse } from "src/data/Api";
import { ProfileData } from "src/data/Profile";
import axiosInstance from "src/shared/axiosInstance";

function ProfileForm() {
  const [profileDataForm, setProfileDataForm] = useState<ProfileData>();
  const [profileFormErrors, setProfileFormErrors] = useState<{
    [key: string]: string[];
  }>();
  const [profileSaveLoading, setProfileSaveLoading] = useState(false);
  const { showAlert } = useAlert();

  useEffect(() => {
    axiosInstance
      .get<ProfileData>("/profile")
      .then((response: AxiosResponse<ProfileData>) => {
        setProfileDataForm(response.data);
      });
  }, []);

  function saveProflie() {
    setProfileSaveLoading(true);
    setProfileFormErrors(undefined);
    axiosInstance
      .put("/profile", profileDataForm)
      .then((response: AxiosResponse<MessageResponse>) => {
        showAlert(
          AlertType.SUCCESS,
          t("general.messages.successful_update"),
          t(`general.messages.${response.data.message.success[0]}`)
        );
        setProfileSaveLoading(false);
      })
      .catch((error: AxiosError<any>) => {
        setProfileFormErrors(error.response?.data.message);
        setProfileSaveLoading(false);
      });
  }

  return (
    <div className="flex flex-col gap-2 w-full">
      <h1 className="text-lg font-bold">{t("profile.profile_data")}</h1>

      <div className="flex flex-col gap-2 bg-bg-primary2 p-2 rounded  shadow">
        <div className="flex flex-row gap-2 max-sm:flex-col">
          <InputField
            className="w-full"
            placeholder={t("user.last_name")}
            value={profileDataForm?.last_name}
            errorKey={profileFormErrors?.last_name}
            change={(value) =>
              setProfileDataForm((prev: any) => ({
                ...prev,
                last_name: value,
              }))
            }
          ></InputField>
          <InputField
            className="w-full"
            placeholder={t("user.first_name")}
            value={profileDataForm?.first_name}
            errorKey={profileFormErrors?.first_name}
            change={(value) =>
              setProfileDataForm((prev: any) => ({
                ...prev,
                first_name: value,
              }))
            }
          ></InputField>
        </div>
        <InputField
          className="w-full"
          placeholder={t("profile.email")}
          value={profileDataForm?.email}
          errorKey={profileFormErrors?.email}
          change={(value) =>
            setProfileDataForm((prev: any) => ({
              ...prev,
              email: value,
            }))
          }
        ></InputField>
        <InputField
          className="w-full"
          placeholder={t("profile.phone_number")}
          value={profileDataForm?.phone_number}
          errorKey={profileFormErrors?.phone_number}
          change={(value) =>
            setProfileDataForm((prev: any) => ({
              ...prev,
              phone_number: value,
            }))
          }
        ></InputField>
        <Button loading={profileSaveLoading} onClick={saveProflie}>
          {t("general.save")}
        </Button>
      </div>
    </div>
  );
}

export default ProfileForm;
